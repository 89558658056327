import { OverlayTrigger, Tooltip } from "react-bootstrap";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import Styles from "./ProductItem.module.css";
import ModalView from "./Modal";
import ViewCustomerProduct from "../AllProducts/ViewCustomerProduct";
import eye from "../../../Assets/product_eye.svg";
import like from "../../../Assets/product_like.svg";
import emptyImg from "../../../Assets/no_image.svg";
import {
  addtocartService,
  removeWishlistService,
} from "../../../Services/ApiServices";
import wishlist_like from "../../../Assets/wishlist_like.svg";
import { handleWishlistCount } from "../../../Redux/Action";

export default function ProductItem(props) {
  let location = useLocation();
  let isPath = location.pathname;
  let dispatch = useDispatch();
  const { wishlistCount } = useSelector((state) => state);
  const [isShowModal, setIsShowModal] = useState(false);
  const [isCustomerModal, setIsCustomerModal] = useState(false);
  const [wishlistStatus, setWishlistStatus] = useState(props.data.wish_list);

  const hanldeCartOrWish = (id, count, type) => {
    let formData = new FormData();
    formData.append("product_id", parseInt(id));
    formData.append("quantity", parseInt(count));
    formData.append("card_type", parseInt(type));
    addtocartService(formData)
      .then((res) => {
        toast(
          type === 2
            ? "Added to Wishlist Successfully"
            : "Add to Cart Successfully",
          {
            type: "success",
          }
        );
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast(err.response.data.detail[0].msg, { type: "error" });
        } else {
          toast("Something went wrong!!", { type: "error" });
        }
      });
  };

  const handleWishlist = (data) => {
    setWishlistStatus(parseInt(data));
  };

  const getButtonNameData = () => {
    if (props?.customerSelectedItem?.includes(props?.data?.product_id)) {
      return { id: 0, name: "Remove" };
    } else {
      if (
        (props?.data?.product_images &&
          props?.data?.product_images.length > 0) ||
        (props?.data?.product_unit && props?.data?.product_unit.length > 0)
      ) {
        return { id: 2, name: "View" };
      }
      return { id: 1, name: "Add" };
    }
  };

  const handleDeleteWishlist = (data) => {
    removeWishlistService(parseInt(data))
      .then((res) =>
        toast("Remove from Wishlist Succesfully", { type: "success" })
      )
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast(err.response.data.detail[0].msg, { type: "error" });
        } else {
          toast("Something went wrong!!", { type: "error" });
        }
      });
  };

  return (
    <div
      style={{
        border:
          isPath.includes("/kansas_product") &&
          props?.customerSelectedItem?.includes(props?.data?.product_id)
            ? "1px solid #ff7b26"
            : null,
      }}
      className={Styles.overallContainer}
    >
      {isShowModal ? (
        <ModalView
          categoryname={props.categoryname}
          productId={props.data.product_id}
          productType={props.productType}
          state={isShowModal}
          onHide={() => setIsShowModal(false)}
          hanldeCartOrWish={hanldeCartOrWish}
          handleWishlist={handleWishlist}
        />
      ) : null}

      {isCustomerModal ? (
        <ViewCustomerProduct
          show={isCustomerModal}
          handleSelectedData={props?.handleSelectedData}
          customerSelectedItem={props?.customerSelectedItem}
          onHide={() => setIsCustomerModal(false)}
          product_id={props.data.product_id}
        />
      ) : null}
      <div
        style={{
          display:
            location.pathname === "/kansas_product/allproducts" ? "none" : null,
        }}
        className={Styles.optionsParentContainer}
      >
        <OverlayTrigger placement="left" overlay={<Tooltip>Wishlist</Tooltip>}>
          <div className={Styles.optionsContainer}>
            <img
              className={Styles.optionsImg}
              src={parseInt(wishlistStatus) === 1 ? wishlist_like : like}
              onClick={(e) => {
                setWishlistStatus(parseInt(wishlistStatus) === 1 ? 0 : 1);
                if (parseInt(wishlistStatus) !== 0) {
                  handleDeleteWishlist(props.data.product_id);
                  dispatch(handleWishlistCount(wishlistCount - 1));
                } else {
                  hanldeCartOrWish(props.data.product_id, 1, 2);
                  dispatch(handleWishlistCount(wishlistCount + 1));
                }
              }}
              alt="like"
            />
          </div>
        </OverlayTrigger>
        <OverlayTrigger placement="left" overlay={<Tooltip>View</Tooltip>}>
          <div className={Styles.optionsContainer}>
            <img
              className={Styles.optionsImg}
              onClick={() => setIsShowModal(!isShowModal)}
              src={eye}
              alt="eye"
            />
          </div>
        </OverlayTrigger>
      </div>
      <div className={Styles.imgBox}>
        <img
          className={Styles.productImg}
          onClick={(e) => {
            e.stopPropagation();

            if (isPath.includes("/kansas_product")) {
              setIsCustomerModal(!isCustomerModal);
            } else {
              setIsShowModal(!isShowModal);
            }
          }}
          src={
            props.data.product_image === ""
              ? emptyImg
              : props.data.product_image
          }
          alt="product"
        />
      </div>
      <div>
        <div
          onClick={(e) => {
            e.stopPropagation();
            if (isPath.includes("/kansas_product")) {
              setIsCustomerModal(!isCustomerModal);
            } else {
              setIsShowModal(!isShowModal);
            }
          }}
          className="d-flex justify-content-center mt-2"
        >
          <p className={Styles.description}>
            {props.data.product_name.length > 25 // text cut
              ? props?.data?.product_name?.substring(0, 25 - 3) + "..."
              : props?.data?.product_name}
          </p>
        </div>
        <div className={Styles.priceDisplay}>
          <span
            className={
              isPath.includes("/kansas_product")
                ? Styles.customerNewPrice
                : Styles.newPrice + " orange"
            }
          >
            ₹
            {isPath.includes("/kansas_product")
              ? props?.data?.selling_price
              : props?.data?.franchise_price}
          </span>
          <span className={Styles.oldPrice}>{"₹" + props?.data?.price}</span>
          <button
            style={{
              display: !isPath.includes("/kansas_product") ? "none" : null,
            }}
            onClick={() => {
              if (isPath.includes("/kansas_product")) {
                let processId = getButtonNameData().id;
                if (processId === 0 || processId === 1) {
                  props?.handleSelectedData(props?.data);
                } else {
                  setIsCustomerModal(!isCustomerModal);
                }
              }
            }}
            className={Styles.customerAddBtn}
          >
            {getButtonNameData().name}
          </button>
        </div>
      </div>
    </div>
  );
}
