import toast from "react-hot-toast";
import { Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import OurServices from "./OurServices";
import Styles from "./Store.module.css";
import RecentActivity from "./RecentActivity";
import PaymentDetails from "./Payment";
import Wallet from "./Wallet";
import ViewCustomerWishlist from "../AddToCart/ViewCustomerWishlist";
import PaymentMode from "../PaymentMode/PaymentMode";
import wallet from "../../../Assets/wallet.svg";
import earning from "../../../Assets/rupee.png";
import rightArrow from "../../../Assets/right.png";
import inventoryIcon from "../../../Assets/inventory_icon.png";
import { TabTitle } from "../../../Utility/TabTitle";
import payment from "../../../Assets/payment_request.png";
import billing from "../../../Assets/billing_icon.svg";
import reject from "../../../Assets/reject_img.png";
import billList from "../../../Assets/billList.svg";
import myorder from "../../../Assets/myorder.png";
import complaintlist from "../../../Assets/complaint_list.png";
import qrUser from "../../../Assets/qrUser.png";
import qrcode from "../../../Assets/qrcode.png";
import cancelIcon from "../../../Assets/cross.png";
import paymentSuccess from "../../../Assets/paymentSuccess.png";
import paymentWaiting from "../../../Assets/paymentWaiting.png";
import paymentCancel from "../../../Assets/paymentCancel.png";
import Loader from "../../../SharedComponents/Loader/Loader";
import CustomerOrder from "../../../Assets/CustomerOrder.png";
import { getCookie, getNumberFormat } from "../../../Utility/Utility";
import {
  billListService,
  dashboardBalanceService,
  listCustomerWishlistService,
  trackingOrderService,
  wallethistoryService,
} from "../../../Services/ApiServices";

export default function Store() {
  TabTitle("KSTORE - Dashboard");
  let navigate = useNavigate();
  let { search } = useLocation();
  const [ourServiceData, setOurServiceData] = useState([
    { id: 1, name: "Stock List", img: inventoryIcon },
    // { id: 2, name: "products", img: productIcon },
    { id: 3, name: "Payment Request", img: payment },
    { id: 4, name: "Purchase Order", img: billing },
    { id: 5, name: "Invoice", img: billList },
    { id: 6, name: "My Order", img: myorder },
    { id: 7, name: "Complaints", img: complaintlist },
    { id: 8, name: "e-Brochure", img: qrcode },
    // { id: 9, name: "UPI-QR Code", img: qrcode },
    { id: 10, name: "Brochure User", img: qrUser },
    { id: 10, name: "Customer Order Request", img: CustomerOrder },
  ]);

  const [walletBal, setWalletBal] = useState(0);
  const [walletHistory, setWalletHistory] = useState([]);
  const [searchTrackId, setSearchTrackId] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [customerViewId, setCustomerViewId] = useState("");
  const [isWishlistView, setIsWishListView] = useState(false);
  const [isTopup, setIsTopup] = useState(false);
  const [customerWishlistData, setCustomerWishlistData] = useState([]);
  const [billData, setBillData] = useState([]);
  const [showPaymentStatus, setShowPaymentStatus] = useState(false);
  const [paymentStatusDetails, setPaymentStatusDetails] = useState({
    paymentMsg: "",
    paymentStatus: "",
  });

  useEffect(() => {
    handleDashboardBalance();
    handleWalletHistory();
    handleCustomerWishlist();
    handleList();
  }, []);

  useEffect(() => {
    let { login_access } = JSON.parse(getCookie("userlog"));
    let otpstatus = JSON.parse(sessionStorage.getItem("otpStatus"));
    if (login_access === 0 && otpstatus === null && otpstatus !== 1) {
      navigate("/verifyotp");
    }
  }, []);

  useEffect(() => {
    if (search !== "") {
      // showPaymentStatus(true);
      let data = search;
      let urlToString = data.replace(/%20/g, " ").split("&");
      let msg = urlToString[0].split("=")[1].replace(".", "");
      let status = urlToString[1].split("=")[1];
      setPaymentStatusDetails({ paymentMsg: msg, paymentStatus: status });
      setShowPaymentStatus(true);
    }
  }, []);

  const handleCustomerWishlist = () => {
    listCustomerWishlistService({}, 1, 10)
      .then((res) => setCustomerWishlistData(res.data))
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast(err.response.data.detail[0].msg, { type: "error" });
        } else {
          toast("Something went wrong!!", { type: "error" });
        }
      });
  };

  const handleDashboardBalance = () => {
    setIsLoader(true);
    dashboardBalanceService()
      .then((res) => setWalletBal(res.data))
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast(err.response.data.detail[0].msg, { type: "error" });
        } else {
          toast("Something went wrong!!", { type: "error" });
        }
      });
  };

  const handleWalletHistory = () => {
    let formData = new FormData();
    formData.append("dash_board_list", 1);
    wallethistoryService(formData, 1, 10)
      .then((res) => setWalletHistory(res.data))
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast(err.response.data.detail[0].msg, { type: "error" });
        } else {
          toast("Something went wrong!!", { type: "error" });
        }
      })
      .finally(() => setIsLoader(false));
  };

  const handleList = () => {
    billListService("", 1, 10)
      .then((res) => {
        setBillData(res.data.items);
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast.error(err.response.data.detail[0].msg, { type: "error" });
        } else {
          toast.error("Something went wrong !!", { type: "error" });
        }
      });
  };
  const handleTracking = () => {
    let formData = new FormData();
    formData.append("order_id", searchTrackId);
    trackingOrderService(formData)
      .then((res) => {
        // navigate("/store/track_order", { state: res.data });
        window.open(res.data, "_blank");
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          // toast(err.response.data.detail[0].msg, { type: "error" });
          setIsModal(true);
        } else {
          toast("Something went wrong!!", { type: "error" });
        }
      });
  };

  const handleCustomerView = (id) => {
    setCustomerViewId(id);
    setIsWishListView(true);
  };

  return (
    <div className={Styles.overallContainer + " container-fluid p-0"}>
      {isWishlistView ? (
        <ViewCustomerWishlist
          state={isWishlistView}
          onHide={() => setIsWishListView(false)}
          id={customerViewId}
        />
      ) : null}

      <Loader isLoader={isLoader} />
      <Modal show={isModal} onHide={() => setIsModal(false)} centered size="md">
        <Modal.Body className={Styles.modalH}>
          <div className={Styles.cancelImgContainer}>
            <img
              onClick={() => setIsModal(false)}
              src={cancelIcon}
              alt="cancel"
              className={Styles.cancelImg}
            />
          </div>

          <div>
            <div className={Styles.errorImgContainer}>
              <img className={Styles.errorImg} src={reject} alt="reject" />
            </div>
            <p className="kstoreFont fs-4">Oops!!! Enter Valid Order Id</p>
          </div>
        </Modal.Body>
      </Modal>

      {isTopup ? (
        <PaymentMode isShow={isTopup} onHide={() => setIsTopup(false)} />
      ) : null}
      {showPaymentStatus ? (
        <Modal
          centered
          size="md"
          show={showPaymentStatus}
          onHide={() => setShowPaymentStatus(false)}
        >
          <Modal.Body>
            <div>
              <div className="text-center">
                <img
                  src={
                    parseInt(paymentStatusDetails.paymentStatus) === 1
                      ? paymentSuccess
                      : parseInt(paymentStatusDetails.paymentStatus) === 0
                      ? paymentWaiting
                      : parseInt(paymentStatusDetails.paymentStatus) === 2
                      ? paymentCancel
                      : null
                  }
                  alt="success"
                />
              </div>
              <p className={Styles.paymentMsg}>
                {paymentStatusDetails.paymentMsg}
              </p>
            </div>
          </Modal.Body>
        </Modal>
      ) : null}
      <div className={Styles.leftContainer}>
        <div className={Styles.leftChildContainer}>
          <div className={`${Styles.walletDetails}  mt-3`}>
            <h2 className="fontBold text-uppercase">
              Wallet <span className="orange">Balance</span>
            </h2>
            <div className={Styles.wallet}>
              <img
                className={Styles.walletInnerImg}
                src={wallet}
                alt="wallet"
              />
              <div className={Styles.walletContent}>
                <h3 className="text-white fontBold">Balance</h3>
                <h4 className="text-white fontBold">
                  {getNumberFormat(walletBal?.wallet_amount)}
                </h4>
              </div>
              <p onClick={() => setIsTopup(true)} className={Styles.topupText}>
                Topup
              </p>

              <p
                className={Styles.balHistory}
                onClick={() => navigate("/store/balance_history")}
              >
                Balance History
                <img
                  className={Styles.balHistoryImg}
                  src={rightArrow}
                  alt="right"
                />
              </p>
            </div>
          </div>
          <div className={Styles.ourService}>
            <h2 className="fontBold text-uppercase">
              Our <span className="orange">Services</span>
            </h2>
            <div className={Styles.servicesContainer}>
              {ourServiceData?.map((e, index) => (
                <OurServices key={e.id} data={e} />
              ))}
            </div>
          </div>
          <div className={Styles.trackOrder}>
            <h2 className="fontBold text-uppercase">
              Track <span className="orange">Order</span>
              <div className={Styles.trackContainer}>
                <h4 className={Styles.trackOrderId + " header text-uppercase"}>
                  order id
                </h4>
                <div className={Styles.trackInputContainer}>
                  <input
                    className={Styles.trackInput + " contentFont"}
                    type="text"
                    placeholder="Enter Order id"
                    value={searchTrackId}
                    onChange={(e) => setSearchTrackId(e.target.value)}
                  />
                </div>
                <div className={Styles.trackBtnContainer + " header"}>
                  <button
                    disabled={searchTrackId === ""}
                    onClick={handleTracking}
                    className={Styles.trackBtn}
                  >
                    Track Order
                  </button>
                </div>
              </div>
            </h2>
          </div>
        </div>
      </div>
      <div className={Styles.rightContainer}>
        <div className={Styles.chartSec}>
          <h2 className="fontBold text-uppercase">
            Month <span className="orange">Report</span>
          </h2>
          <div className={Styles.moneyContainer}>
            <div className={Styles.moneyChildContainer}>
              <div className="container">
                <div className="d-flex justify-content-center mt-3">
                  <div className={Styles.earningContainer}>
                    <img
                      className={Styles.earnImg}
                      src={earning}
                      alt="earning"
                    />
                  </div>
                </div>
                <div className="mt-2">
                  <p className={Styles.moneyTitle + " mb-0"}>Total Sales</p>
                  <p className={Styles.money + " mb-0"}>
                    {getNumberFormat(walletBal?.total_sales)}
                  </p>
                </div>
              </div>
            </div>

            <div className={Styles.moneyChildContainer}>
              <div className="container">
                <div className="d-flex justify-content-center mt-3">
                  <div className={Styles.earningContainer}>
                    <img
                      className={Styles.earnImg}
                      src={earning}
                      alt="earning"
                    />
                  </div>
                </div>
                <div className="mt-2">
                  <p className={Styles.moneyTitle + " mb-0"}>Total Purchase</p>
                  <p className={Styles.money + " mb-0"}>
                    {getNumberFormat(walletBal?.total_purchase)}
                  </p>
                </div>
              </div>
            </div>
            <div className={Styles.moneyChildContainer}>
              <div className="d-flex justify-content-center mt-3">
                <div className={Styles.earningContainer}>
                  <img className={Styles.earnImg} src={earning} alt="earning" />
                </div>
              </div>
              <div className="mt-2">
                <p className={Styles.moneyTitle + " mb-0"}>Total Earnings</p>
                <p className={Styles.money + " mb-0"}>
                  {getNumberFormat(walletBal?.total_earning_amount)}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className={Styles.recentActivityContainer + " mt-5"}>
          <RecentActivity data={billData} />
        </div>
      </div>
      <div className={Styles.lastContainer}>
        <div className={Styles.paymentContainer}>
          <div className="d-flex justify-content-between">
            <h3 className="fontBold">
              Customer <span className="orange">Wishlist</span>
            </h3>
          </div>
          <div className={Styles.paymentDetailsParent}>
            {customerWishlistData?.items?.length === 0 ? (
              <p className={Styles.noDataFoundMsg}>No Data Found</p>
            ) : (
              <>
                {customerWishlistData?.items?.map((e, index) => (
                  <div
                    key={e.customer_wishlist_id}
                    className={Styles.paymentDetailsContainer}
                  >
                    <PaymentDetails
                      data={e}
                      handleCustomerView={handleCustomerView}
                    />
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
        <div className={Styles.walletContainer + " mt-4"}>
          <div className="d-flex justify-content-between">
            <h4 className="fontBold">
              Wallet <span className="orange">History</span>{" "}
            </h4>
          </div>
          <div className={Styles.WalletParent}>
            {walletHistory?.items?.length === 0 ? (
              <p className={Styles.noDataFoundMsg}>No Data Found</p>
            ) : (
              <>
                {walletHistory &&
                  walletHistory?.items?.length > 0 &&
                  walletHistory?.items
                    ?.slice(0, 10)
                    .map((e, index) => <Wallet key={index} data={e} />)}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
