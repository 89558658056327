import { useFormik } from "formik";
import React from "react";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { sendOTPService } from "../../Services/ApiServices";
import Styles from "./ForgotPassword.module.css";
import { setCookie } from "../../Utility/Utility";
import gif from "../../Assets/kstore.gif";

function ForgotPassword() {
  let navigate = useNavigate();

  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    initialValues: {
      email: "",
      mbl: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Required"),
      mbl: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      handleOtp(values);
    },
  });

  const handleOtp = (data) => {
    let formData = new FormData();
    formData.append("email", data.email);
    formData.append("mobile_no", data.mbl);
    formData.append("for_forgot_pwd", 1);
    sendOTPService(formData)
      .then((res) => {
        toast(res.data.msg, { type: "success" });
        navigate("/auth/otpverification", {
          state: {
            resetKey: res.data.reset_key,
          },
        });
        setCookie("resetKey", res.data.reset_key, 30);
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast.error(err.response.data.detail[0].msg, { type: "error" });
        } else {
          toast.error("Something went wrong!!", { type: "error" });
        }
      });
  };

  return (
    <>
      <img className={Styles.logo} src={gif} alt="kstore" />
      <div className={Styles.overallParent}>
        <div className={Styles.loginRightContainer}>
          <div>
            <h3 className="kstoreFont">Forgot Password</h3>
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="mt-4">
                <label className="label">
                  Username <span className="text-danger fw-bold">*</span>{" "}
                </label>
                <div className={Styles.inputContainer}>
                  <input
                    className={Styles.emailInput + " contentFont"}
                    type="text"
                    name="email"
                    onChange={handleChange}
                    value={values.email}
                    placeholder="Enter Username"
                  />
                </div>
              </div>
              {touched.email && errors.email ? (
                <div className={Styles.errMsg + " text-danger"}>
                  {errors.email}
                </div>
              ) : null}
              <div className="mt-4">
                <label className="label">
                  Mobile <span className="text-danger fw-bold">*</span>
                </label>
                <div className={Styles.inputContainer}>
                  <input
                    className={Styles.emailInput + " contentFont"}
                    type="text"
                    name="mbl"
                    maxLength={10}
                    onKeyPress={(e) => {
                      if (!(e.key === "0" || parseInt(e.key))) {
                        e.preventDefault();
                      }
                    }}
                    onChange={handleChange}
                    value={values.mbl}
                    placeholder="Enter Mobile Number"
                  />
                </div>
              </div>
              {touched.mbl && errors.mbl ? (
                <div className={Styles.errMsg + " text-danger"}>
                  {errors.mbl}
                </div>
              ) : null}
              <div className={Styles.btnContainer}>
                <button
                  type="submit"
                  onClick={() => navigate("/auth")}
                  className={Styles.previousBtn + " mt-4 kstoreFont"}
                >
                  Back to login
                </button>
                <button
                  type="submit"
                  onClick={handleSubmit}
                  className={Styles.loginBtn + " mt-4 kstoreFont"}
                >
                  GET OTP
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;
