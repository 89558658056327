import { Modal } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import toast from "react-hot-toast";
import Styles from "./DetailsPopup.module.css";
import cancelIcon from "../../../Assets/cross.png";
import { updatePaymentRequestService } from "../../../Services/ApiServices";
import Loader from "../../../SharedComponents/Loader/Loader";

export default function EditDetails(props) {
  const [isLoader, setIsLoader] = useState(false);
  const { handleSubmit, handleChange, errors, values, setFieldValue, touched } =
    useFormik({
      initialValues: {
        amount: props.data.data.amount,
        paymentMethod: props.data.data.payment_mode,
        payeeName: props.data.data.payee_name,
        img: props.data.data.proof_image,
        bankReferenceId: props.data.data.bank_reference_id,
        remarks: props.data.data.remarks,
      },
      validationSchema: Yup.object({
        amount: Yup.string().required("Required"),
        paymentMethod: Yup.string().required("Required"),
        payeeName: Yup.string().required("Required"),
      }),
      onSubmit: (values) => {
        handleUpdatePaymentRequest(values);
      },
    });

  const handleUpdatePaymentRequest = (data) => {
    setIsLoader(true);
    let formData = new FormData();
    formData.append(
      "payment_request_id",
      parseInt(props.data.data.payment_request_id)
    );
    formData.append("amount", parseInt(data.amount));
    formData.append("payment_mode", parseInt(data.paymentMethod));
    formData.append("payee_name", data.payeeName);
    if (data.bankReferenceId !== "") {
      formData.append("bank_reference_id", data.bankReferenceId);
    }

    if (data?.img !== null && typeof data?.img === "object") {
      formData.append("proof_image", data.img);
    }

    if (data.remarks !== "") {
      formData.append("remarks", data.remarks);
    }
    updatePaymentRequestService(formData)
      .then((res) => {
        toast("Update Details Successfully", { type: "success" });
        props.handleTableData(values);
        props.onHide(false);
        props.handleListPayment(props?.tableData?.page);
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast(err.response.data.detail[0].msg, { type: "error" });
        } else {
          toast("Something went wrong!!");
        }
      })
      .finally(() => setIsLoader(false));
  };

  return (
    <div className={Styles.parentContainer + " container-fluid"}>
      <Loader isLoader={isLoader} />
      <div className="container">
        <Modal
          size="xl"
          show={props.isOpen}
          onHide={props.onHide}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <div className={Styles.cancelImgParentContainer}>
              <div>
                <p className="fontBold">
                  Update <span className="orange">Details</span>
                </p>
              </div>
              <div className={Styles.cancelImgContainer}>
                <img
                  onClick={props.onHide}
                  src={cancelIcon}
                  alt="cancel"
                  className={Styles.cancelImg}
                />
              </div>
            </div>
            <form onSubmit={(e) => e.preventDefault()}>
              <div className={Styles.inputParent}>
                <div className={Styles.leftContainer}>
                  <label className="label">
                    Payee Name <span className="text-danger fs-6">*</span>
                  </label>
                  <div>
                    <input
                      className={Styles.input + " kstoreFont6"}
                      type="text"
                      name="payeeName"
                      value={values.payeeName}
                      onChange={handleChange}
                      placeholder="Enter Payee Name"
                    />
                  </div>
                  {touched.payeeName && errors.payeeName ? (
                    <div className={Styles.errMsg + " text-danger"}>
                      {errors.payeeName}
                    </div>
                  ) : null}
                </div>
                <div className={Styles.rightContainer}>
                  <label className="label">
                    Payment Mode <span className="text-danger fs-6">*</span>
                  </label>
                  <div>
                    <select
                      className={Styles.select + " kstoreFont6"}
                      name="paymentMethod"
                      value={values.paymentMethod}
                      onChange={handleChange}
                    >
                      <option value="" selected disabled>
                        Please select
                      </option>
                      <option value="1">IMPS</option>
                      <option value="2">NEFT</option>
                    </select>
                  </div>
                  {touched.paymentMethod && errors.paymentMethod ? (
                    <div className={Styles.errMsg + " text-danger"}>
                      {errors.paymentMethod}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className={Styles.inputParent}>
                <div className={Styles.leftContainer}>
                  <label className="label">Bank Reference ID</label>
                  <div>
                    <input
                      className={Styles.input + " kstoreFont6"}
                      type="text"
                      name="bankReferenceId"
                      onChange={handleChange}
                      value={values.bankReferenceId}
                      placeholder="Enter Bank Reference ID"
                    />
                  </div>
                  {touched.shippingPincode && errors.shippingPincode ? (
                    <div className={Styles.errMsg + " text-danger kstoreFont6"}>
                      {errors.shippingPincode}
                    </div>
                  ) : null}
                </div>
                <div className={Styles.rightContainer}>
                  <label className="label">
                    Amount <span className="text-danger fs-6">*</span>
                  </label>
                  <div>
                    <input
                      onChange={handleChange}
                      className={Styles.input + " kstoreFont6"}
                      type="text"
                      name="amount"
                      maxLength="10"
                      value={values.amount}
                      placeholder="Enter Amount"
                      onKeyPress={(e) => {
                        if (!(e.key === "0" || parseInt(e.key))) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
                  {touched.amount && errors.amount ? (
                    <div className={Styles.errMsg + " text-danger"}>
                      {errors.amount}
                    </div>
                  ) : null}
                </div>
              </div>
              <label className="label">Remarks</label>
              <div>
                <textarea
                  className={Styles.textarea + " kstoreFont6"}
                  type="text"
                  name="remarks"
                  value={values.remarks}
                  onChange={handleChange}
                  placeholder="Enter Remarks"
                />
              </div>
              <div className={Styles.inputParent}>
                <div className={Styles.leftContainer}>
                  <label className="label">Proof Image</label>
                  <div className={Styles.imgUploadContainer}>
                    <input
                      type="file"
                      id="uploadImg"
                      name="img"
                      onChange={(e) => setFieldValue("img", e.target.files[0])}
                      className="align-self-center"
                    />
                  </div>
                </div>
              </div>
              {values?.img !== "" && values?.img !== null ? (
                <div className={Styles.showImgContainer}>
                  <img
                    className="w-100"
                    src={
                      typeof values?.img === "string"
                        ? values?.img
                        : URL.createObjectURL(values?.img)
                    }
                    alt="img"
                  />
                </div>
              ) : null}
              <div className={Styles.btnContainer}>
                <button
                  onClick={props.onHide}
                  className={Styles.cancelBtn + " kstoreFont5"}
                >
                  Cancel
                </button>
                <button
                  onClick={handleSubmit}
                  className={Styles.btn + " kstoreFont5 ms-4"}
                >
                  Update
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}
