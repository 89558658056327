import { useState } from "react";
import toast from "react-hot-toast";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { resetPasswordService } from "../../Services/ApiServices";
import Styles from "./ResetPassword.module.css";

export default function ResetPassword() {
  let { state } = useLocation();
  let navigate = useNavigate();
  const [password, setPassword] = useState({
    password: "",
    confirmPassword: "",
    id: 0,
    required: 0,
  });
  const [isLoader, setIsLoader] = useState(false);

  const handleSubmit = () => {
    let formData = new FormData();
    if (password.password === password.confirmPassword) {
      setIsLoader(true);
      formData.append("reset_key", state.RESET_KEY);
      formData.append("otp", parseInt(state.OTP));
      formData.append("password", password.password);
      resetPasswordService(formData)
        .then((res) => {
          toast(res.data, { type: "success" });
          navigate("/auth");
        })
        .catch((err) => {
          if (err?.response?.data?.detail) {
            toast.error(err.response.data.detail[0].msg, { type: "error" });
          } else {
            toast.error("Something went wrong!!", { type: "error" });
          }
        })
        .finally(setIsLoader(false));
    } else {
      setPassword({ ...password, id: 1 });
    }

    if (password.password === "" && password.confirmPassword === "") {
      setPassword({ ...password, required: 1 });
    } else if (password.password !== "" && password.confirmPassword === "") {
      setPassword({ ...password, required: 2 });
    } else if (password.password === "" && password.confirmPassword !== "") {
      setPassword({ ...password, required: 3 });
    }
  };

  return (
    <div className={Styles.loginRightContainer}>
      {isLoader ? (
        <div className={Styles.loaderParent}>
          <div className={Styles.loader}></div>
        </div>
      ) : null}
      <h3 className="kstoreFont">Login</h3>
      <p className={Styles.loginRightPara + " fw-bold"}>
        Welcome back! login with your data that you entered during registration
      </p>
      <div className="mt-4 w-100 float-start">
        <p className="label">
          Password <span className="text-danger">*</span>
        </p>

        <div className={Styles.inputContainer}>
          <input
            className={Styles.emailInput + " contentFont"}
            type="text"
            name="username"
            placeholder="Enter Password"
            onChange={(e) =>
              setPassword({ ...password, password: e.target.value })
            }
          />
        </div>
        {password.required === 1 || password.required === 3 ? (
          <p className={Styles.errorMsg + " text-danger w-100 float-start"}>
            Required
          </p>
        ) : null}
      </div>
      <div className="mt-4 w-100 float-start">
        <p className="w-100 float-start label">
          Confirm Password <span className="text-danger">*</span>
        </p>
        <div className={Styles.usernameContainer + " mt-3"}>
          <div className={Styles.inputContainer}>
            <input
              className={Styles.emailInput + " contentFont"}
              type="text"
              name="username"
              placeholder="Enter Confirm Password"
              onChange={(e) =>
                setPassword({ ...password, confirmPassword: e.target.value })
              }
            />
          </div>
          {password.required === 2 || password.required === 1 ? (
            <p className={Styles.errorMsg + " text-danger w-100 float-start"}>
              Required
            </p>
          ) : null}
        </div>
        {password.id === 1 ? (
          <div
            className="w-100 float-start mt-3"
            //   style={{
            //     display:
            //       password.password !== password.confirmPassword ? "block" : "none",
            //   }}
          >
            <p className={Styles.errorMsg + " text-danger"}>
              Password or confirm Password is Not Match
            </p>
          </div>
        ) : null}
      </div>

      <div className={Styles.btnContainer}>
        <button
          type="submit"
          // disabled={otp.id === 0}
          // style={{
          //   backgroundColor: otp.id === 0 ? "#D56922" : "#FF7C25",
          // }}
          className={Styles.loginBtn + " mt-5"}
          onClick={handleSubmit}
        >
          SUBMIT
        </button>
        <Link className={Styles.backtoLoginBtn + " mt-5"} to="/auth">
          Back to Login
        </Link>
      </div>
    </div>
  );
}
