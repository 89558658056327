export const BaseURL = "https://kansaswallet.in/"; //live
// export const BaseURL = "http://192.168.1.125:8001/";
// export const BaseURL = "http://192.168.4.208:8000/"; //local
// export const BaseURL = "http://cbe.themaestro.in:1008/";
// export const BaseURL = "http://192.168.1.124:8002/";
// export const BaseURL = "http://192.168.1.124:8001/";

export const phoneExp = /^[6,7,8,9]{1}[0-9]{9}$/;
export function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export const getVideoID = (url) => {
  return url.slice(url.lastIndexOf("/") + 1);
};

export const getVideoThumbnail = (url) => {
  return `http://img.youtube.com/vi/${getVideoID(url)}/0.jpg`;
};
export const getNumberFormat = (num) => {
  let tempNumber = num?.toString().replace("₹", "");
  let sign = "";
  if (tempNumber?.toString().includes("-")) {
    sign = "-";
    tempNumber = tempNumber?.toString().replace("-", "");
  }
  let x = parseFloat(tempNumber).toFixed(2);
  let a =
    x.toString().split(".")[0].length > 3
      ? x
          .toString()
          .substring(0, x.toString().split(".")[0]?.length - 3)
          .replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
        "," +
        x.toString().substring(x?.toString().split(".")[0].length - 3)
      : x.toString();

  return "₹ " + sign + a;
};
