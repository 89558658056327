import Carousel from "react-multi-carousel";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import Styles from "./BrandPartners.module.css";
import { customerBrandService } from "../../Services/ApiServices";

export default function BrandPartners() {
  const [brandImgs, setBrandImgs] = useState([]);
  useEffect(() => {
    handleBrand();
  }, []);

  const handleBrand = () => {
    customerBrandService()
      .then((res) => {
        setBrandImgs(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast.error(err.response.data.detail[0].msg, { type: "error" });
        } else {
          toast.error("Something went wrong!!");
        }
      });
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <div id="partners" className="mt-5">
      <div className="d-flex justify-content-center">
        <h1 className="text-white kstoreFont">
          Brand <span className="orange kstoreFont">Partners</span>
        </h1>
      </div>
      <Carousel
        autoPlay
        autoPlaySpeed={1000}
        arrows={false}
        infinite={true}
        responsive={responsive}
        className="container"
      >
        {brandImgs?.map((e, index) => (
          <div key={index}>
            <img className={Styles.lgImg} src={e.brand_logo} alt="lg" />
          </div>
        ))}
      </Carousel>
    </div>
  );
}
