import { useState } from "react";
import * as React from "react";
import { addDays } from "date-fns";
import { DateRangePicker } from "react-date-range";
import { useNavigate } from "react-router-dom";
import Styles from "./RecentActivity.module.css";
import settings from "../../../Assets/recentActivity_settings.svg";
export default function RecentActivity(props) {
  let navigate = useNavigate();
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);
  const [isDate, setIsDate] = useState(false);

  return (
    <div>
      <div className="d-none justify-content-between">
        <h2 className="kstoreFont fs-5 text-uppercase">
          Recent <span className="orange">Activity</span>
        </h2>
        <img className={Styles.settingsImg} src={settings} alt="settings" />
      </div>

      {isDate ? (
        <DateRangePicker
          onChange={(item) => {
            setState([item.selection]);
            if (item.selection.startDate !== item.selection.endDate)
              setIsDate(false);
          }}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          ranges={state}
          direction="horizontal"
          preventSnapRefocus={true}
          calendarFocus="backwards"
          id="date"
        />
      ) : null}
      <div className={Styles.overallContainer}>
        <p className="fontBold">
          Latest <span className="orange">Invoice</span>{" "}
        </p>
        <div className={Styles.rightContainer}>
          <p
            onClick={() => navigate("/store/bill_list")}
            className={Styles.showbillText}
          >
            Show All
          </p>
        </div>
      </div>
      <div className={Styles.hrContainer + " w-100"}>
        <hr className={Styles.hr1} />
        <hr className={Styles.hr2} />
      </div>
      <div className={Styles.mapContainer}>
        <div className={Styles.tableParentContainer}>
          <div className={Styles.tableContainer}>
            <table>
              <thead className={Styles.header}>
                <tr>
                  <th className="text-center contentFont fw-bold pt-2 pb-2">
                    S.No
                  </th>
                  <th className="text-center contentFont fw-bold">Date</th>
                  <th className="text-center contentFont fw-bold">
                    Invoice No
                  </th>
                  <th className="text-center contentFont fw-bold">
                    Customer Name
                  </th>
                  <th className="text-center contentFont fw-bold">
                    Total Amount
                  </th>
                  <th className="text-center contentFont fw-bold">
                    Payment Type
                  </th>
                  <th className="text-center contentFont fw-bold">
                    Bill Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {props?.data?.map((e, index) => (
                  <tr key={index} className={Styles.tr}>
                    <td className={Styles.td + " pt-3 pb-3"}>{index + 1}</td>
                    <td className={Styles.td}>{e?.billed_date}</td>
                    <td className={Styles.td}>{e?.bill_no}</td>
                    <td className={Styles.td}>{e?.customer_name}</td>
                    <td className={Styles.td}>
                      <p>
                        ₹ <span>{e?.price.toFixed(2)}</span>{" "}
                      </p>
                    </td>
                    <td className={Styles.td}>{e?.payment_type}</td>
                    <td className={Styles.td}>{e?.bill_status_type}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
