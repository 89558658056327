import Styles from "./ContactUs.module.css";
import location from "../../Assets/location.png";
import whatsapp from "../../Assets/whatsapp.png";
import mail from "../../Assets/mail.png";
import call from "../../Assets/call.png";

export default function ContactUs() {
  const addressDetails = [
    {
      id: 1,
      img: location,
      description:
        "No. 9, Lakshmi Gardens, Thudiyalur road, Near Sankara College, Saravanampatti, Coimbatore - 641035",
    },
    {
      id: 2,
      img: call,
      description: "Dealer-Care : 80 56 56 96 96",
    },
    {
      id: 3,
      img: whatsapp,
      description: "Whatsapp : 80 56 56 96 96",
    },
    {
      id: 4,
      img: mail,
      description: "For Sales Enquiry : sales@kansaswallet.in",
    },
    {
      id: 5,
      img: mail,
      description: "Any Other Enquiry : kstore@kansaswallet.in",
    },
  ];

  return (
    <div id="contact" className="mt-5">
      <div className="d-flex justify-content-center">
        <h1 className="text-white kstoreFont">
          Contact <span className="orange">Us</span>
        </h1>
      </div>

      <div className={Styles.mapContainer + " container mt-5"}>
        <div className={Styles.address}>
          <ul>
            <li>
              {addressDetails.map((e) => (
                <div key={e.id} className="d-flex p-3">
                  <img className={Styles.img} src={e.img} alt="location" />
                  <p className={Styles.description + " text-white mb-0"}>
                    {e.description}
                  </p>
                </div>
              ))}
            </li>
          </ul>
        </div>
        <div className={Styles.map}>
          <iframe
            className="w-100 h-100"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15663.77108911721!2d76.9778198!3d11.0429176!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x5044f49dca3fad37!2sKANSAS+ENTERPRISES!5e0!3m2!1sen!2sin!4v1512216058369"
          />
        </div>
      </div>

      <div className="mt-5 pb-5">
        <p className="mb-0 text-white text-center">
          © Copyrights 2021 KSTORe. All Rights Reserved.{" "}
        </p>
      </div>
    </div>
  );
}
