import React, { useEffect, useState } from "react";
import Styles from "./KansasorderView.module.css";
import Loader from "../../../SharedComponents/Loader/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import emptyImg from "../../../Assets/no_image.svg";
import { kansasViewOrderList } from "../../../Services/ApiServices";
import toast from "react-hot-toast";
import ApprovalStatusChangeModal from "./ApprovalStatusModal";
import KansasApprovelConfirmationmodal from "./KansasApprovelConfirmationmodal";
export default function KansasOrderView() {
  let { state } = useLocation();
  const navigate = useNavigate();
  const [readmoreId, setReadmoreId] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [viewData, setViewData] = useState(null);
  const [approveStatusType, setApproveStatusType] = useState(null);
  const [approvalStatusChangeModal, setapprovalStatusChangeModal] =
    useState(false);
  const [showApprovalConfirmationModal, setShowApprovalConfirmationModal] =
    useState(false);
  const [damageError, setDamageError] = useState([]);

  const handleKansasViewDetails = () => {
    setIsLoader(true);
    let formData = new FormData();
    formData.append("purchase_id", state?.purchase_id);
    kansasViewOrderList(formData)
      .then((res) => {
        setViewData(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast.error(err.response.data.detail[0].msg, { type: "error" });
        } else {
          toast.error("Something went wrong !!", { type: "error" });
        }
      })
      .finally(() => {
        setIsLoader(false);
      });
  };
  //approveStatus

  useEffect(() => {
    handleKansasViewDetails();
  }, []);
  const RenderText = (key, value) => {
    return (
      <div className={Styles.InsideListChilds}>
        <p>{key}</p>
        <strong>:</strong>
        <span>{value ?? "-"}</span>
      </div>
    );
  };

  return (
    <div className={Styles.bg}>
      <Loader isLoader={isLoader} />
      <ApprovalStatusChangeModal
        isOpen={approvalStatusChangeModal}
        onHide={() => {
          setapprovalStatusChangeModal(false);
        }}
        listapicall={handleKansasViewDetails}
        approvalStatusId={state}
      />
      <KansasApprovelConfirmationmodal
        isOpen={showApprovalConfirmationModal}
        onHide={() => {
          setShowApprovalConfirmationModal(false);
        }}
        approveStatusType={approveStatusType}
        listapicall={handleKansasViewDetails}
        approvalStatusId={state}
      />
      <div className={Styles.childContainer + " bg-white"}>
        <div className={Styles.approvelStatus}>
          <p className={Styles.title + " fontBold"}>
            Customer Request<span className="orange">Details</span>
          </p>
          <div style={{ gap: "10px" }}>
            {!viewData?.franchise_order_approval ? (
              <>
                <button
                  className={Styles.Approvebtn}
                  onClick={() => {
                    // setApprovalStatusId(e);
                    setShowApprovalConfirmationModal(true);
                    setApproveStatusType(1);
                    // setapprovalStatusChangeModal(true);
                  }}
                >
                  Accept
                </button>
                <button
                  className={Styles.Rejectebtn}
                  onClick={() => {
                    // setApprovalStatusId(e);
                    setApproveStatusType(2);

                    setShowApprovalConfirmationModal(true);
                    // setapprovalStatusChangeModal(true);
                  }}
                >
                  Reject
                </button>
              </>
            ) : null}
            <button
              className={Styles.btn}
              onClick={() => {
                navigate("/store/customerOrderrequest");
              }}
            >
              Back
            </button>
          </div>
        </div>
        <div className={Styles.AddressOutline}>
          <div className={Styles.billingAddress}>
            <p className="fontBold" style={{ marginBottom: "10px" }}>
              Customer <span className="orange">Details</span>
            </p>
            <div
              className={Styles.ViewInsideListContainer}
              style={{
                borderBottom: "none",
              }}
            >
              {RenderText("Name", viewData?.name)}
              {RenderText("Mobile", viewData?.mobile)}
              {RenderText("TotalAmount", viewData?.total_amount)}
              {RenderText("Total Quantity", viewData?.total_qty)}
              {RenderText(
                "Order Status",
                viewData?.order_status == 1
                  ? "In Process"
                  : viewData?.order_status == 2
                  ? "Intransit"
                  : "Waiting"
              )}

              {RenderText(
                "Franchise Order Approval",
                viewData?.franchise_order_approval == 1
                  ? "Approved"
                  : viewData?.franchise_order_approval == 2
                  ? "Reject"
                  : "Waiting"
              )}

              {viewData?.franchise_order_approval == 2 ? (
                <>
                  {RenderText(
                    "Reason",
                    viewData?.franchise_order_approval == 1
                      ? "Approved"
                      : viewData?.franchise_order_approval == 2
                      ? "Reject"
                      : "Waiting"
                  )}
                </>
              ) : null}
            </div>
          </div>
          <div className={Styles.billingAddress}>
            <p className="fontBold" style={{ marginBottom: "10px" }}>
              Billing <span className="orange">Details</span>
            </p>
            <div
              className={Styles.ViewInsideListContainer}
              style={{
                borderBottom: "none",
              }}
            >
              {RenderText("Name", viewData?.billing_name)}
              {RenderText("Address", viewData?.billing_address)}
              {RenderText("Mobile", viewData?.mobile)}
              {RenderText("State", viewData?.state)}
              {RenderText("City", viewData?.city)}
              {RenderText("Location", viewData?.location)}
            </div>
          </div>
          <div className={Styles.billingAddress}>
            <p className="fontBold" style={{ marginBottom: "10px" }}>
              Shipping <span className="orange">Details</span>
            </p>
            <div
              className={Styles.ViewInsideListContainer}
              style={{
                borderBottom: "none",
              }}
            >
              {RenderText("Name", viewData?.shipping_name)}
              {RenderText("Address", viewData?.shipping_address)}
              {RenderText("Mobile", viewData?.shipping_mobile)}
              {RenderText("State", viewData?.shipping_state)}
              {RenderText("City", viewData?.shipping_city)}
              {RenderText("Location", viewData?.shipping_location)}
            </div>
          </div>
        </div>
        {/* <div className={Styles.addressContainer}>
          <div className={Styles.addressLeftSec}>
            <p className="fontBold">
              Billing <span className="orange">Address</span>
            </p>
            <p className={Styles.address + " fw-bold"}>
              {viewData?.billing_name}
            </p>
            <p className={Styles.address}>{viewData?.billing_address}</p>
            <p className={Styles.address}>{viewData?.billing_city}</p>
            <p className={Styles.address}>
              {viewData?.billing_state}
              {viewData?.billing_state && viewData?.billing_pincode
                ? " - "
                : ""}
              {viewData?.billing_pincode}
            </p>
            <p className={Styles.address}>{viewData?.billing_email}</p>
            <p className={Styles.address}>{viewData?.billing_mobile}</p>
          </div>
          <div className={Styles.addressRightSec}>
            <p className="fontBold">
              Shipping <span className="orange">Address</span>
            </p>
            <p className={Styles.address + " fw-bold"}>
              {viewData?.shipping_name}
            </p>
            <p className={Styles.address}>{viewData?.shipping_address}</p>
            <p className={Styles.address}>{viewData?.shipping_city}</p>
            <p className={Styles.address}>
              {viewData?.shipping_state}
              {viewData?.shipping_state && viewData?.shipping_pincode
                ? " - "
                : ""}
              {viewData?.shipping_pincode}
            </p>

            <p className={Styles.address}>{viewData?.shipping_email}</p>
            <p className={Styles.address}>{viewData?.shipping_mobile}</p>
          </div>
        </div> */}
        {/* <div className="d-flex justify-content-end">
          <p className={Styles.status}>
            Date : <span>{viewData?.ordered_date}</span>
          </p>
          <p className={Styles.status}>
            Order Status : <span>{viewData?.purchased_status}</span>
          </p>
        </div> */}
        <div className={Styles.tableParentContainer}>
          <div className={Styles.table + " d-flex justify-content-center"}>
            <table>
              <thead>
                <tr>
                  <th className={Styles.th + " text-center"}>S.No</th>
                  <th className={Styles.cartProductName + " text-center"}>
                    Product Name
                  </th>
                  <th className={Styles.cartProductName + " text-center"}>
                    Product Code
                  </th>
                  <th className={Styles.th + " text-center"}>Quantity</th>
                  <th className={Styles.th + " text-center"}>
                    Courier Name/Number
                  </th>
                  <th className={Styles.th + " text-center"}>Price</th>
                  <th className={Styles.th + " text-center"}>Total</th>
                </tr>
              </thead>
              <tbody>
                {viewData?.purchase_products?.map((e, index) => (
                  <tr key={e?.purchase_product_id} className={Styles.tr}>
                    <td className="text-center">{index + 1}</td>
                    <td
                      className={
                        Styles.productContainer + " contentFont text-center"
                      }
                    >
                      {e.product_name ? e.product_name : "-"}
                    </td>
                    <td className="text-center contentFont">
                      {e?.product_code ? e?.product_code : "-"}
                    </td>

                    <td className="text-center contentFont">
                      {e?.quantity ? e?.quantity : "-"}
                    </td>
                    <td className="text-center contentFont">
                      {e?.courier_name ? e?.courier_name : "-"}
                    </td>
                    <td className="contentFont text-center">
                      {"₹" + e?.price?.toFixed(2)}
                    </td>
                    <td className="text-center contentFont">
                      {e?.total ? "₹" + e?.total?.toFixed(2) : "-"}
                    </td>
                  </tr>
                ))}
                {viewData?.purchased_products?.length === 0 ? (
                  <tr>
                    <td
                      colSpan={8}
                      className="fs-6 text-center text-secondary p-3"
                    >
                      Data Not Found
                    </td>
                  </tr>
                ) : null}
              </tbody>
              {/* {viewData?.purchased_products?.length !== 0 ? (
                <tfoot>
                  <tr>
                    <td colSpan={4} />
                    <td className="text-center fw-bold orange">Total</td>
                    <td className={Styles.th + " text-center"}>
                      ₹
                      {viewData?.purchased_products
                        ?.reduce((prev, current) => {
                          return prev + current.total;
                        }, 0)
                        .toFixed(2)}
                    </td>
                  </tr>
                </tfoot>
              ) : null} */}
            </table>
          </div>
          {/* {viewData?.complaint_show_status !== 0 ? (
            <>
              <div className={Styles.msgContainer}>
                <p className="label">Complaint</p>
                <textarea
                  className={Styles.msg}
                  //   onChange={(e) => setComplaintMsg(e.target.value)}
                  type="text"
                  placeholder="Enter Your Complaint Here"
                />
              </div>
              <div className="text-end">
                <button
                  //   onClick={handleComplaint}
                  type="submit"
                  className={Styles.btn}
                >
                  Submit
                </button>
              </div>
            </>
          ) : null} */}
        </div>
      </div>
    </div>
  );
}
