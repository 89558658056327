import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import Styles from "./LanguageList.module.css";
import {
  languageSettingService,
  listLanguageService,
} from "../../../Services/ApiServices";
import Loader from "../../../SharedComponents/Loader/Loader";

export default function LanguageList({ isShowModal, setModal }) {
  const location = useLocation();
  const [languageList, setlanguageList] = useState([]);
  const [isLoader, setIsLoader] = useState(true);

  useEffect(() => {
    languageListAPICall();
  }, []);

  const languageListAPICall = () => {
    if (location.pathname.includes("/kansas_product")) {
    } else {
      listLanguageService()
        .then(({ data }) => {
          setlanguageList(data);
        })
        .catch((err) => {
          if (err?.response?.data?.detail) {
            toast.error(err.response.data.detail[0].msg);
          } else {
            toast.error("Something went wrong!!");
          }
        })
        .finally(() => {
          setIsLoader(false);
        });
    }
  };

  const languageSettingAPICall = (languageId) => {
    setIsLoader(true);
    let formData = new FormData();
    formData.append("language_id", languageId);
    languageSettingService(formData)
      .then(() => {
        setModal(false);
        window.location.reload();
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast.error(err.response.data.detail[0].msg);
        } else {
          toast.error("Something went wrong!!");
        }
      })
      .finally(() => {
        setIsLoader(false);
      });
  };

  const handleSubmitChangedLanguage = () => {
    let selectedLanguageId = [...languageList].find(
      (item) => item.active_language === 1
    ).language_id;
    languageSettingAPICall(selectedLanguageId);
  };

  const handleSelectLanguage = (id) => {
    let tempLanguages = [...languageList];
    tempLanguages = [...tempLanguages].map((item) => {
      return { ...item, active_language: item.language_id === id ? 1 : 0 };
    });
    setlanguageList(tempLanguages);
  };

  return (
    <div>
      <Loader isLoader={isLoader} />

      <Modal
        show={isShowModal}
        onHide={() => {
          setModal(false);
        }}
        size="md"
        centered
      >
        <Modal.Body>
          <div>
            <p className={Styles.title + " black fontBold text-center"}>
              Select Language
            </p>
            <div className={Styles.contentContainer}>
              <div className="mt-3">
                {[...languageList].map((item) => {
                  return (
                    <div
                      className={Styles.radioButtonContainer}
                      key={item.language_id.toString()}
                      onClick={() => {
                        handleSelectLanguage(item.language_id);
                      }}
                    >
                      <input
                        value={item.language}
                        className={Styles.radioButton + " align-self-center"}
                        type="radio"
                        //   onChange={() => handleSelectLanguage(item.language_id)}
                        checked={item.active_language === 1}
                      />
                      <label
                        onClick={() => handleSelectLanguage(item.language_id)}
                        className={Styles.buttonLabel + " label"}
                      >
                        {item.language}
                      </label>
                    </div>
                  );
                })}
              </div>
              <button
                onClick={() => handleSubmitChangedLanguage()}
                className={Styles.submitButton}
              >
                Submit
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
