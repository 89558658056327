import { useEffect, useState } from "react";
import { OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import ReactSelect from "react-select";
import moment from "moment";
import FileSaver from "file-saver";
import ModalView from "./Modal";
import Styles from "./InventoryList.module.css";
import EditPrice from "../InventoryPrice/EditPrice";
import {
  categoryListService,
  productBrandService,
  productListService,
  subCategoryListService,
  updatePriceService,
} from "../../../Services/ApiServices";
import LeftArrow from "../../../Assets/modal_left_arrow.png";
import RightArrow from "../../../Assets/modal_right_arrow.png";
import edit from "../../../Assets/price_edit.png";
import viewIcon from "../../../Assets/view.svg";
import bookIcon from "../../../Assets/book.svg";
import searchIcon from "../../../Assets/searchFilter.png";
import downloadIcon from "../../../Assets/download_icon.png";
import Loader from "../../../SharedComponents/Loader/Loader";

export default function InventoryList() {
  let navigate = useNavigate();
  let { state } = useLocation();
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(0);
  const [editPrice, setEditPrice] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [filterData, setFilterData] = useState({
    category: "",
    subCategory: "",
    productCode: "",
    productName: "",
    brand: "",
  });
  // For View
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({ data: "", status: 1 });
  const [editData, setEditData] = useState({
    id: "",
    selling_price: "",
    bought_price: "",
    customer_price: "",
  });
  useEffect(() => {
    handleListProducts();
    handleCategory();
    handleBrand();
  }, []);

  const handleListProducts = (pageNumber = 1, csv) => {
    setIsLoader(true);
    let formData = new FormData();
    if (filterData.category !== "") {
      formData.append("category_id", filterData.category);
    }
    if (filterData.subCategory !== "") {
      formData.append("sub_category_id", filterData.subCategory);
    }
    if (filterData.productCode !== "") {
      formData.append("product_code", filterData.productCode);
    }
    if (filterData.productName !== "") {
      formData.append("product_name", filterData.productName);
    }
    if (filterData.brand !== "") {
      formData.append("brand", filterData.brand);
    }
    if (csv !== undefined) {
      formData.append("download", parseInt(csv));
    }

    formData.append("list_type", state !== null ? parseInt(state) : 1);
    productListService(formData, pageNumber, 10)
      .then((res) => {
        if (csv !== undefined) {
          let url = res.data;
          let urlArr = url.split(".");
          let ext = urlArr[urlArr.length - 1];
          let dateTime =
            moment().format("DD-MM-YYYY") + "," + moment().format("hh:mm:ss a");
          let fileName = dateTime + "." + ext;
          FileSaver.saveAs(url, fileName);
        } else {
          setTableData(res.data);
          setPage(pageNumber - 1);
        }
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast.error(err.response.data.detail[0].msg, { type: "error" });
        } else {
          toast.error("Something went wrong!!");
        }
      })
      .finally(() => setIsLoader(false));
  };

  const handleUpdatePrice = (id, price) => {
    let formData = new FormData();
    formData.append("product_id", parseInt(id));
    formData.append("price", parseInt(price));
    updatePriceService(formData)
      .then((res) => {
        toast("Price Updated", { type: "success" });
        handleListProducts(tableData?.page);
        setIsLoader(false);
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast.error(err.response.data.detail[0].msg);
        } else {
          toast.error("Something went wrong!!");
        }
      })
      .finally(() => setIsLoader(false));
  };

  const customStyles = {
    control: () => ({
      // none of react-select's styles are passed to <Control />
      backgroundColor: "white",
      border: "1px solid rgba(0,0,0,0.1)",
      width: "100%",
      borderRadius: "5px",
      display: "flex",
      margin: "5px 0",
      height: "37px",
      fontSize: "12px ",
      zIndex: "1",
      // ":hover": {
      //   borderColor: "white",
      // },
    }),
    indicatorsContainer: () => ({
      display: "flex",
      alignSelf: "center",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "black",
    }),
    input: (provided, state) => ({
      ...provided,
      color: "black",
      padding: "6px 5px",
    }),
  };

  const handleCategory = () => {
    categoryListService()
      .then((res) => {
        setCategoryList(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast.error(err.response.data.detail[0].msg);
        } else {
          toast.error("Something went wrong!!");
        }
      });

    subCategoryListService()
      .then((res) => {
        setSubCategoryList(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast.error(err.response.data.detail[0].msg);
        } else {
          toast.error("Something went wrong!!");
        }
      });
  };

  const handleBrand = () => {
    productBrandService()
      .then((res) => setBrandList(res.data))
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast.error(err.response.data.detail[0].msg);
        } else {
          toast.error("Something went wrong!!");
        }
      });
  };

  const handleReset = (pageNumber = 1) => {
    setIsLoader(true);
    setFilterData({
      category: "",
      subCategory: "",
      productCode: "",
      productName: "",
      brand: "",
    });
    let formData = new FormData();
    formData.append("list_type", 1);
    productListService(formData, pageNumber, 10)
      .then((res) => {
        setTableData(res.data);
        setPage(pageNumber - 1);
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast.error(err.response.data.detail[0].msg, { type: "error" });
        } else {
          toast.error("Something went wrong!!");
        }
      })
      .finally(() => setIsLoader(false));
  };

  return (
    <div className={Styles.parentContainer + " container-fluid"}>
      {showModal ? (
        <ModalView
          state={showModal}
          data={modalData}
          productId={modalData.data.product_id}
          productType={1}
          onHide={() => setShowModal(false)}
        />
      ) : null}

      {editPrice ? (
        <EditPrice
          isOpen={editPrice}
          data={editData}
          handleUpdatePrice={handleUpdatePrice}
          onHide={() => setEditPrice(false)}
          isLoader={() => setIsLoader(true)}
        />
      ) : null}
      <Loader isLoader={isLoader} />

      <div className={Styles.childContainerParent}>
        <div className={Styles.childContainer}>
          <div className={Styles.searchParentContainer}>
            <p className="fontBold align-self-center">
              Stock <span className="orange">List</span>
            </p>
            <div>
              <img
                className={Styles.searchIcon}
                src={searchIcon}
                alt="search"
                onClick={() => setShowFilter(!showFilter)}
              />
              <button
                className={Styles.CsvBtn + " kstoreFont"}
                onClick={() => handleListProducts(1, 1)}
              >
                Export to Csv
                <img
                  className={Styles.download}
                  src={downloadIcon}
                  alt="download"
                />
              </button>
              <button
                className={Styles.MonthlyReport + " kstoreFont"}
                onClick={() => navigate("/store/monthly_stock")}
              >
                Mothly Stock
              </button>
            </div>
          </div>

          {showFilter ? (
            <div className={Styles.filterParentContainer + " row"}>
              <div className={"col-md-3 col-sm-12 " + Styles.categories}>
                <label className="label">Category</label>
                <ReactSelect
                  className="basic-single"
                  classNamePrefix="select"
                  name="color"
                  placeholder="Select Category..."
                  isClearable={true}
                  value={categoryList.find(
                    (e) => e.category_id === filterData.category
                  )}
                  styles={customStyles}
                  options={categoryList}
                  getOptionLabel={(e) => e.category_name}
                  getOptionValue={(e) => e.category_id}
                  onChange={(e) => {
                    if (e !== null) {
                      setFilterData({
                        ...filterData,
                        category: e.category_id,
                        subCategory: "",
                      });
                    } else {
                      setFilterData({ ...filterData, category: "" });
                    }
                  }}
                  key={filterData.category !== "" ? Math.random() : "123"}
                />
              </div>
              <div className={Styles.categories + " col-md-3 col-sm-12"}>
                <label className="label">Sub category</label>
                <ReactSelect
                  className="basic-single contentFont"
                  classNamePrefix="select"
                  name="color"
                  placeholder="Select sub category..."
                  isClearable={true}
                  value={subCategoryList.find(
                    (e) => e.sub_category_id === filterData.subCategory
                  )}
                  styles={customStyles}
                  options={subCategoryList}
                  getOptionLabel={(e) => e.sub_category_name}
                  getOptionValue={(e) => e.sub_category_id}
                  onChange={(e) => {
                    if (e !== null) {
                      setFilterData({
                        ...filterData,
                        subCategory: e.sub_category_id,
                      });
                    } else {
                      setFilterData({ ...filterData, subCategory: "" });
                    }
                  }}
                  key={filterData.subCategory !== "" ? Math.random() : "123"}
                />
              </div>
              <div className={Styles.categories + " col-md-3 col-sm-12"}>
                <label className="label">Brand</label>
                <ReactSelect
                  className="basic-single contentFont"
                  classNamePrefix="select"
                  name="color"
                  placeholder="Select Brand..."
                  isClearable={true}
                  value={brandList.find((e) => e.brand_id === filterData.brand)}
                  styles={customStyles}
                  options={brandList}
                  getOptionLabel={(e) => e.brand_name}
                  getOptionValue={(e) => e.brand_id}
                  onChange={(e) => {
                    if (e !== null) {
                      setFilterData({
                        ...filterData,
                        brand: e.brand_id,
                      });
                    } else {
                      setFilterData({ ...filterData, brand: "" });
                    }
                  }}
                  key={filterData.brand !== "" ? Math.random() : "123"}
                />
              </div>
              <div className={Styles.categories + " col-md-3 col-sm-12"}>
                <label className="label">Product name</label>
                <input
                  type="text"
                  placeholder="Product name"
                  className={Styles.filterInput + " contentFont"}
                  value={filterData.productName}
                  onChange={(e) =>
                    setFilterData({
                      ...filterData,
                      productName: e.target.value,
                    })
                  }
                />
              </div>
              <div className={Styles.categories + " col-md-3 col-sm-12"}>
                <label className="label">Product code</label>
                <input
                  type="text"
                  placeholder="Product code"
                  className={Styles.filterInput + " contentFont"}
                  value={filterData.productCode}
                  onChange={(e) =>
                    setFilterData({
                      ...filterData,
                      productCode: e.target.value,
                    })
                  }
                />
              </div>

              <div
                className={
                  Styles.searchBtnParent +
                  " d-flex justify-content-end col-8 pt-2"
                }
              >
                <button
                  onClick={() => {
                    handleReset();
                  }}
                  className={Styles.resetBtn + " me-2"}
                >
                  Reset
                </button>
                <button
                  onClick={() => handleListProducts(1)}
                  className={Styles.searchBtn}
                >
                  Search
                </button>
              </div>
            </div>
          ) : null}
          <div className={Styles.tableParentContainer}>
            <div className={Styles.TableChildContainer}>
              <table>
                <thead>
                  <tr>
                    <th
                      className={Styles.tableHead + " contentFont text-center"}
                    >
                      S.No
                    </th>
                    <th
                      className={Styles.tableHead + " text-start contentFont"}
                    >
                      Product Code
                    </th>
                    <th
                      style={{ width: "18%" }}
                      className={Styles.tableHead + " text-start contentFont"}
                    >
                      Product Name
                    </th>
                    <th
                      className={Styles.tableHead + " text-start contentFont"}
                    >
                      Category Name
                    </th>
                    <th
                      className={Styles.tableHead + " text-start contentFont"}
                    >
                      Sub Category Name
                    </th>
                    <th
                      className={Styles.tableHead + " text-start contentFont"}
                    >
                      Brand
                    </th>
                    <th
                      className={Styles.tableHead + " text-center contentFont"}
                    >
                      Quantity
                    </th>
                    <th
                      className={Styles.tableHead + " text-center contentFont"}
                    >
                      Franchise Price
                    </th>
                    <th
                      className={Styles.tableHead + " text-center contentFont"}
                    >
                      Customer Price
                    </th>
                    <th
                      className={Styles.tableHead + " text-center contentFont"}
                    >
                      Selling Price
                    </th>
                    <th
                      className={Styles.tableHead + " text-center contentFont"}
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {tableData?.items !== undefined &&
                    tableData?.items.length > 0 &&
                    tableData?.items.map((e, index) => (
                      <tr key={e.product_id}>
                        <td className="text-center contentFont">
                          {(tableData.page - 1) * tableData.size + (index + 1)}
                        </td>
                        <td className="text-start contentFont">
                          {e?.product_code}
                        </td>
                        <OverlayTrigger
                          placement="bottom"
                          trigger={"hover"}
                          overlay={
                            <Popover className={Styles.popover}>
                              {e?.product_name}
                            </Popover>
                          }
                        >
                          <td className={" contentFont text-start"}>
                            {e.product_name.substring(0, 37)}
                            {e.product_name.length > 37 ? "..." : null}
                          </td>
                        </OverlayTrigger>

                        <td className="text-start contentFont">
                          {e?.product_category_name}
                        </td>
                        <td className="text-start contentFont">
                          {e?.product_subcategory_name}
                        </td>
                        <td className="text-start contentFont">
                          {e?.brand_name ? e?.brand_name : "-"}
                        </td>
                        <td className="text-center contentFont">
                          {e?.quantity}
                        </td>
                        <td
                          className={Styles.price + " text-center contentFont"}
                        >
                          <p className={Styles.priceSymbol}>₹</p>
                          <p className={Styles.priceAmt}>
                            {e?.franchise_price.toFixed(2)}
                          </p>
                        </td>
                        <td className=" text-center contentFont">
                          <div className={Styles.price}>
                            <p className={Styles.priceSymbol}>₹</p>
                            <p className={Styles.priceAmt}>
                              {e?.default_customer_price.toFixed(2)}
                            </p>
                          </div>
                        </td>
                        <td
                          className={
                            Styles.sellingPriceContainer +
                            " text-center contentFont"
                          }
                        >
                          <p className={Styles.sellingPrice}>
                            <p className={Styles.priceSymbol}>₹</p>
                            <p className={Styles.priceAmt}>
                              {e?.customer_price_on_franchise?.toFixed(2)}
                            </p>
                          </p>
                          <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip>Edit price</Tooltip>}
                          >
                            <img
                              className={Styles.editPrice}
                              src={edit}
                              alt="edit"
                              onClick={() => {
                                setEditPrice(true);
                                setEditData({
                                  ...editData,
                                  id: e?.product_id,
                                  selling_price: e?.customer_price_on_franchise,
                                  bought_price: e?.franchise_price,
                                  customer_price: e?.price,
                                });
                              }}
                            />
                          </OverlayTrigger>
                        </td>
                        <td className="text-center">
                          <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip>View</Tooltip>}
                          >
                            <img
                              src={viewIcon}
                              alt="view"
                              className={Styles.viewImg}
                              onClick={() => {
                                setShowModal(true);
                                setModalData({ ...modalData, data: e });
                              }}
                            />
                          </OverlayTrigger>
                          <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip>History</Tooltip>}
                          >
                            <img
                              src={bookIcon}
                              alt="history"
                              className={Styles.historyImg}
                              onClick={() => {
                                navigate("/store/inventory_history", {
                                  state: e.product_id,
                                });
                              }}
                            />
                          </OverlayTrigger>
                        </td>
                      </tr>
                    ))}

                  {tableData?.items?.length === 0 ? (
                    <tr>
                      <td
                        colSpan={10}
                        className="fs-6 text-center text-secondary p-3"
                      >
                        Data Not Found
                      </td>
                    </tr>
                  ) : null}
                </tbody>
              </table>
            </div>
          </div>

          <div
            style={{ display: tableData.total <= 10 ? "none" : "flex" }}
            className={Styles.paginationContainer}
          >
            <ReactPaginate
              previousLabel={<img src={LeftArrow} alt="left" />}
              nextLabel={<img src={RightArrow} alt="right" />}
              breakLabel="..."
              pageCount={Math.ceil(tableData.total) / 10}
              marginPagesDisplayed={1}
              pageRangeDisplayed={1}
              onPageChange={({ selected }) => handleListProducts(selected + 1)}
              containerClassName={"pagination m-0"}
              pageClassName={"page-item"}
              pageLinkClassName={
                "page-link text-secondary rounded-circle bg-light pt-1 pb-1 ps-2 pe-2 ms-1 me-1 border-0 shadow-none"
              }
              forcePage={page}
              previousClassName={"page-item"}
              previousLinkClassName={
                "page-link border-0 text-dark p-1 rounded-circle bg_orange shadow-none me-1"
              }
              nextClassName={"page-item "}
              nextLinkClassName={
                "page-link border-0 text-dark p-1 bg_orange rounded-circle shadow-none ms-1"
              }
              activeClassName={"active"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
