import Styles from "./Loader.module.css";
import loaderImg from "../../Assets/kstore_loader.gif";
export default function Loader(props) {
  return (
    <>
      {props.isLoader ? (
        <div className={Styles.loaderParent}>
          <img className={Styles.loadergif} src={loaderImg} alt="loader" />
        </div>
      ) : null}
    </>
  );
}
