import { useFormik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Styles from "./ChangePassword.module.css";
import visibleImg from "../../../Assets/visible.png";
import unvisible from "../../../Assets/unvisible.png";
import { ChangePasswordService } from "../../../Services/ApiServices";

export default function ChangePassword() {
  let navigate = useNavigate();
  const [isOldPassword, setIsOldPassword] = useState(false);
  const [isNewPassword, setIsNewPassword] = useState(false);
  const [isConfirmPassword, setIsConfirmPassword] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    initialValues: {
      old_password: "",
      new_password: "",
      confirm_password: "",
    },
    validationSchema: Yup.object({
      old_password: Yup.string().required("Required"),
      new_password: Yup.string().required("Required"),
      confirm_password: Yup.string()
        .required("Required")
        .oneOf([Yup.ref("new_password")], "Password do not match"),
    }),
    onSubmit: (values) => {
      handleChangePassword(values);
    },
  });

  const handleChangePassword = (data) => {
    setIsLoader(true);
    let formData = new FormData();
    formData.append("old_password", data.old_password);
    formData.append("new_password", data.new_password);
    formData.append("confirm_password", data.confirm_password);
    formData.append("change_password", 1);
    ChangePasswordService(formData)
      .then((res) => {
        toast("password changed successfully", { type: "success" });
        setIsLoader(false);
        navigate("/store");
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast.error(err.response.data.detail[0].msg);
        } else {
          toast.error("Something went wrong!!");
        }
      })
      .finally(() => setIsLoader(false));
  };

  return (
    <div className={Styles.overallParent + " container-fluid"}>
      {isLoader ? (
        <div className={Styles.loaderParent}>
          <div className={Styles.loader}></div>
        </div>
      ) : null}
      <div className={Styles.childContainer}>
        <p className="fontBold text-center">
          Change <span className="orange">Password</span>{" "}
        </p>
        <div>
          <form onSubmit={(e) => e.preventDefault()} autoComplete="off">
            <div className={Styles.overallInput}>
              <div className="mt-4">
                <label className="label">
                  Old Password <span className="text-danger fw-bold">*</span>{" "}
                </label>
                <div className={Styles.inputContainer}>
                  <input
                    name="old_password"
                    onChange={handleChange}
                    value={values.old_password}
                    autoComplete="new-password"
                    className={Styles.passwordInput + " kstoreFont"}
                    placeholder="Enter Old Password"
                    type={isOldPassword ? "text" : "password"}
                  />

                  <img
                    onClick={() => setIsOldPassword(!isOldPassword)}
                    className={Styles.visibleImg}
                    src={isOldPassword ? visibleImg : unvisible}
                  />
                </div>
              </div>
              {touched.old_password && errors.old_password ? (
                <div className={Styles.errorMsg + " text-danger"}>
                  {errors.old_password}
                </div>
              ) : null}
              <div className="mt-4">
                <label className="label">
                  New Password <span className="text-danger fw-bold">*</span>
                </label>
                <div className={Styles.inputContainer}>
                  <input
                    name="new_password"
                    onChange={handleChange}
                    value={values.new_password}
                    className={Styles.passwordInput + " kstoreFont"}
                    placeholder="Enter New Password"
                    type={isNewPassword ? "text" : "password"}
                  />

                  <img
                    onClick={() => setIsNewPassword(!isNewPassword)}
                    className={Styles.visibleImg}
                    src={isNewPassword ? visibleImg : unvisible}
                  />
                </div>
              </div>
              {touched.new_password && errors.new_password ? (
                <div className={Styles.errorMsg + " text-danger"}>
                  {errors.new_password}
                </div>
              ) : null}
              <div className="mt-4">
                <label className="label">
                  Confirm Password{" "}
                  <span className="text-danger fw-bold">*</span>
                </label>
                <div className={Styles.inputContainer}>
                  <input
                    name="confirm_password"
                    onChange={handleChange}
                    value={values.confirm_password}
                    className={Styles.passwordInput + " contentFOnt"}
                    placeholder="Enter Confirm Password"
                    type={isConfirmPassword ? "text" : "password"}
                  />

                  <img
                    onClick={() => setIsConfirmPassword(!isConfirmPassword)}
                    className={Styles.visibleImg}
                    src={isConfirmPassword ? visibleImg : unvisible}
                  />
                </div>
              </div>
              {touched.confirm_password && errors.confirm_password ? (
                <div className={Styles.errorMsg + " text-danger"}>
                  {errors.confirm_password}
                </div>
              ) : null}
            </div>
            <div className="text-center">
              <button className={Styles.btn} onClick={handleSubmit}>
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
