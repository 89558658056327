import { useFormik } from "formik";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { forgotPasswordService } from "../../Services/ApiServices";
import Loader from "../../SharedComponents/Loader/Loader";
import Styles from "./OtpVerification.module.css";
import gif from "../../Assets/kstore.gif";
import visibleImg from "../../Assets/visible.png";
import unvisible from "../../Assets/unvisible.png";
export default function OtpVerification() {
  let { state } = useLocation();
  let navigate = useNavigate();
  const [count, setCount] = useState(0);
  const [isLoader, setIsLoader] = useState(false);
  const [eye, setEye] = useState({
    newpassword: false,
    confirmPassword: false,
  });
  useEffect(() => {
    let timer;
    if (count > 0) {
      timer = setInterval(() => {
        setCount(count - 1);
      }, 1000);
    } else {
      clearInterval(timer);
    }
    return () => {
      clearInterval(timer);
    };
  }, [count]);

  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    initialValues: {
      otp: "",
      new_password: "",
      confirm_password: "",
    },
    validationSchema: Yup.object({
      otp: Yup.string().required("Required"),
      new_password: Yup.string().required("Required"),
      confirm_password: Yup.string().oneOf(
        [Yup.ref("new_password"), null],
        "Passwords must match"
      ),
    }),
    onSubmit: (values) => {
      handleValidation(values);
    },
  });

  const handleValidation = (data) => {
    setIsLoader(true);
    let formData = new FormData();
    formData.append("reset_key", state.resetKey);
    formData.append("otp", data.otp);
    formData.append("new_password", data.new_password);
    formData.append("confirm_password", data.confirm_password);
    forgotPasswordService(formData)
      .then((res) => {
        navigate("/auth");
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast.error(err.response.data.detail[0].msg, { type: "error" });
        } else {
          toast.error("Something went wrong!!", { type: "error" });
        }
      })
      .finally(() => setIsLoader(false));
  };

  return (
    <>
      <Loader isLoader={isLoader} />
      <img className={Styles.logo} src={gif} alt="kstore" />
      <div className={Styles.overallParent}>
        <div className={Styles.loginRightContainer}>
          <div>
            <h3 className="kstoreFont">OTP Verification</h3>
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="mt-4">
                <label className="label">
                  OTP <span className="text-danger fw-bold">*</span>{" "}
                </label>
                <div className={Styles.inputContainer}>
                  <input
                    className={Styles.emailInput + " contentFont"}
                    type="text"
                    name="otp"
                    onChange={handleChange}
                    value={values.otp}
                    placeholder="OTP"
                  />
                </div>
              </div>
              {touched.otp && errors.otp ? (
                <div className={Styles.errMsg + " text-danger"}>
                  {errors.otp}
                </div>
              ) : null}
              <div className="mt-4">
                <label className="label">
                  New Password <span className="text-danger fw-bold">*</span>{" "}
                </label>
                <div className={Styles.inputContainer}>
                  <input
                    className={Styles.emailInput + " contentFont"}
                    type={eye.newpassword ? "text" : "password"}
                    name="new_password"
                    onChange={handleChange}
                    value={values.new_password}
                    placeholder="New Password"
                  />
                  <img
                    className={Styles.eyeIcon}
                    onClick={() =>
                      setEye({ ...eye, newpassword: !eye.newpassword })
                    }
                    src={eye.newpassword ? visibleImg : unvisible}
                    alt="visible"
                  />
                </div>
              </div>
              {touched.new_password && errors.new_password ? (
                <div className={Styles.errMsg + " text-danger"}>
                  {errors.new_password}
                </div>
              ) : null}
              <div className="mt-4">
                <label className="label">
                  Confirm Password{" "}
                  <span className="text-danger fw-bold">*</span>{" "}
                </label>
                <div className={Styles.inputContainer}>
                  <input
                    className={Styles.emailInput + " contentFont"}
                    type={eye.confirmPassword ? "text" : "password"}
                    name="confirm_password"
                    onChange={handleChange}
                    value={values.confirm_password}
                    placeholder="Confirm Password"
                  />
                  <img
                    className={Styles.eyeIcon}
                    onClick={() =>
                      setEye({ ...eye, confirmPassword: !eye.confirmPassword })
                    }
                    src={eye.confirmPassword ? visibleImg : unvisible}
                    alt="visible"
                  />
                </div>
              </div>
              {touched.confirm_password && errors.confirm_password ? (
                <div className={Styles.errMsg + " text-danger"}>
                  {errors.confirm_password}
                </div>
              ) : null}
              <button
                type="submit"
                onClick={handleSubmit}
                className={Styles.loginBtn + " mt-4 kstoreFont text-uppercase"}
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
