export const HandleCustomerCartCount = (data) => {
  return {
    type: "SET_CUSTOMER_CART_COUNT",
    data: data,
  };
};

export const ViewProfile = (data) => {
  return {
    type: "VIEW_PROFILE",
    data: data,
  };
};

export const handleProductCartCount = (data) => {
  return {
    type: "CART_COUNT",
    data: data,
  };
};

export const handleWishlistCount = (data) => {
  return {
    type: "WISHLIST_COUNT",
    data: data,
  };
};
