import { Modal } from "react-bootstrap";
import Styles from "./InventoryTypePopup.module.css";
import cancelIcon from "../../../Assets/cross.png";

export default function InventoryTypePopup(props) {
  return (
    <div className={Styles.parentContainer + " container-fluid"}>
      <div className="container">
        <Modal
          size="s"
          show={props.isOpen}
          onHide={props.onHide}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <div className={Styles.cancelImgParentContainer}>
              <div>
                <p className="fontBold">Alert</p>
              </div>
              <div className={Styles.cancelImgContainer}>
                <img
                  onClick={props.onHide}
                  src={cancelIcon}
                  alt="cancel"
                  className={Styles.cancelImg}
                />
              </div>
            </div>
            <p className={Styles.msg}>Are you sure want to switch Inventory?</p>
            <span className={Styles.note}>
              Note: If you switch inventory, the selected products will be
              removed.
            </span>
            <div className={Styles.btnContainer}>
              <button
                className={Styles.cancelBtn}
                onClick={() => {
                  props.onHide();
                }}
              >
                Cancel
              </button>
              <button
                className={Styles.btn}
                onClick={() => {
                  props.handleSubmit();
                  props.onHide();
                }}
              >
                Ok
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}
