import { Modal } from "react-bootstrap";
import { useState } from "react";
import Styles from "./EditPrice.module.css";
import cancelIcon from "../../../Assets/cross.png";
export default function EditPrice(props) {
  const [editPrice, setEditPrice] = useState(props?.data?.selling_price);
  const [showError, setShowError] = useState({ status: false, text: "" });

  const handlePrice = () => {
    if (editPrice.length === 0) {
      setShowError({ status: true, text: "Price cannot be blank" });
    } else if (editPrice < props.data.bought_price) {
      setShowError({
        status: true,
        text: `Price must be greaterthan ₹${props.data.bought_price}`,
      });
    } else if (editPrice > props.data.customer_price) {
      setShowError({
        status: true,
        text: `Price must be lessthan ₹${props.data.customer_price}`,
      });
    } else {
      props.handleUpdatePrice(props.data.id, editPrice);
      props.onHide();
      props.isLoader();
    }
  };
  return (
    <div className={Styles.parentContainer + " container-fluid"}>
      <div className="container">
        <Modal
          size="s"
          show={props.isOpen}
          onHide={props.onHide}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <div className={Styles.cancelImgParentContainer}>
              <div>
                <p className="fontBold">
                  Edit <span className="orange">Price</span>
                </p>
              </div>
              <div className={Styles.cancelImgContainer}>
                <img
                  onClick={props.onHide}
                  src={cancelIcon}
                  alt="cancel"
                  className={Styles.cancelImg}
                />
              </div>
            </div>
            <div className={Styles.priceContainer}>
              <p className="contentFont text-center align-self-center w-50">
                {props.isCart ? "Price" : "Selling Price"}
              </p>
              <input
                type="text"
                value={editPrice}
                className={Styles.input + " kstoreFont6"}
                placeholder="Enter Price"
                onChange={(e) => setEditPrice(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "0" || parseInt(e.key)) {
                  } else {
                    e.preventDefault();
                  }
                }}
              />
            </div>
            {showError.status ? (
              <p className={Styles.errMsg}>{showError.text}</p>
            ) : null}
            <div className={Styles.btnContainer}>
              <button className={Styles.btn} onClick={handlePrice}>
                Submit
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}
