import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import FileSaver from "file-saver";
import toast from "react-hot-toast";
import moment from "moment";
import Styles from "./BalanceHistory.module.css";
import LeftArrow from "../../../Assets/modal_left_arrow.png";
import RightArrow from "../../../Assets/modal_right_arrow.png";
import downloadIcon from "../../../Assets/download_icon.png";
import searchIcon from "../../../Assets/searchFilter.png";
import Loader from "../../../SharedComponents/Loader/Loader";
import { wallethistoryService } from "../../../Services/ApiServices";
import { getNumberFormat } from "../../../Utility/Utility";

export default function BalanceHistory() {
  const [tableData, setTableData] = useState([]);
  const [searchData, setSearchData] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [filterData, setFilterData] = useState({ date: new Date(), type: "" });
  let date = new Date();
  let currentDate = moment(date).format("YYYY-MM-DD");

  useEffect(() => {
    handleWalletHistory();
  }, [filterData]);

  const handleWalletHistory = (csv, pageNum = 1) => {
    setIsLoader(true);
    let formData = new FormData();
    if (filterData.type !== NaN) {
      formData.append("transfer_type", filterData.type);
    }
    formData.append(
      "select_date",
      moment(filterData.date).format("YYYY-MM-DD")
    );
    if (csv !== undefined && csv !== "") {
      formData.append("download", parseInt(csv));
    }
    formData.append("search_key", searchData);
    wallethistoryService(formData, parseInt(pageNum), 200)
      .then((res) => {
        if (csv !== undefined) {
          let url = res.data;
          let urlArr = url.split(".");
          let ext = urlArr[urlArr.length - 1];
          let dateTime =
            moment().format("DD-MM-YYYY") + "," + moment().format("hh:mm:ss a");
          let fileName = dateTime + "." + ext;
          FileSaver.saveAs(url, fileName);
        } else {
          setTableData(res.data);
        }
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast.error(err.response.data.detail[0].msg);
        } else {
          toast.error("Something went wrong!!");
        }
      })
      .finally(() => setIsLoader(false));
  };

  return (
    <div
      style={{
        height:
          tableData?.items?.length === 0 ? "calc(100vh - 88.6167px)" : null,
      }}
      className={Styles.parentContainer + " container-fluid"}
    >
      <Loader isLoader={isLoader} />
      <div className={Styles.childContainerParent}>
        <div className={Styles.childContainer}>
          <div className={Styles.searchParentContainer}>
            <p className={Styles.title + " fontBold align-self-center"}>
              Balance <span className="orange">History</span>
            </p>

            <div className={Styles.searchContainer}>
              <img
                className={Styles.searchIcon}
                src={searchIcon}
                alt="search"
                onClick={() => setShowFilter(!showFilter)}
              />
              <button
                className={Styles.CsvBtn + " kstoreFont"}
                onClick={() => handleWalletHistory(1)}
              >
                Export to Csv
                <img
                  className={Styles.download}
                  src={downloadIcon}
                  alt="download"
                />
              </button>
              <button
                className={Styles.MblCsvBtn + " kstoreFont"}
                onClick={() => handleWalletHistory(1)}
              >
                <img
                  className={Styles.download}
                  src={downloadIcon}
                  alt="download"
                />
              </button>
            </div>
          </div>

          {showFilter ? (
            <div className={Styles.filterContainer + " row"}>
              <div className={Styles.filterHeading + " col-md-3"}>
                <span className={Styles.heading + " pb-1"}>Date</span>
                <input
                  type="date"
                  required
                  max={currentDate}
                  value={moment(filterData.date).format("YYYY-MM-DD")}
                  onChange={(e) => {
                    if (e.target.value !== "")
                      setFilterData({
                        ...filterData,
                        date: e.target.value,
                      });
                  }}
                  className={Styles.date + " contentFont"}
                />
              </div>
              <div className={Styles.filterHeading + " col-md-3"}>
                <span className={Styles.heading + " pb-1"}>Type</span>
                <select
                  name="type"
                  value={filterData.type}
                  onChange={(e) =>
                    setFilterData({
                      ...filterData,
                      type: e.target.value,
                    })
                  }
                  className={Styles.select + " contentFont"}
                  id="type"
                >
                  <option value="">Select Type</option>
                  <option value="1">Debit</option>
                  <option value="2">Credit</option>
                </select>
              </div>

              <div className={Styles.searchInputContainer + " col-md-3"}>
                <span className={Styles.heading + " pb-1"}>Message</span>
                <input
                  className={Styles.searchInput + " ContentFont"}
                  type="text"
                  value={searchData}
                  placeholder="Search Message..."
                  onChange={(e) => setSearchData(e.target.value)}
                />
              </div>
              <div className={Styles.btnContainer + " col-md-3"}>
                <button
                  className={Styles.resetBtn}
                  onClick={() => {
                    setSearchData("");
                    setFilterData({ date: new Date(), type: "" });
                  }}
                >
                  RESET
                </button>
                <button
                  className={Styles.searchBtn}
                  onClick={() => handleWalletHistory()}
                >
                  SEARCH
                </button>
              </div>
            </div>
          ) : null}

          <div className={Styles.AmountResponsiveContainer}>
            <div className={Styles.amountParentContainer}>
              <div className={Styles.amountChildContainer}>
                <p className={Styles.amountTitle}>Opening Balance</p>
                <p className={Styles.amount}>
                  {getNumberFormat(tableData?.balance_details?.opening_balance)}
                </p>
              </div>
              <div className={Styles.amountChildContainer}>
                <p className={Styles.amountTitle}>Closing Balance</p>
                <p className={Styles.amount}>
                  {getNumberFormat(
                    tableData?.balance_details?.clossing_balance
                  )}
                </p>
              </div>
              <div className={Styles.amountChildContainer}>
                <p className={Styles.amountTitle}>Current Balance</p>
                <p className={Styles.amount}>
                  {getNumberFormat(tableData?.balance_details?.current_balance)}
                </p>
              </div>
              <div className={Styles.amountChildContainer}>
                <p className={Styles.amountTitle}>Total Sales</p>
                <p className={Styles.amount}>
                  {getNumberFormat(tableData?.balance_details?.total_sales)}
                </p>
              </div>
              <div className={Styles.amountChildContainer}>
                <p className={Styles.amountTitle}>Total Purchase</p>
                <p className={Styles.amount}>
                  {getNumberFormat(tableData?.balance_details?.total_purchase)}
                </p>
              </div>
              <div className={Styles.amountChildContainer}>
                <p className={Styles.amountTitle}>Total Earning Amount</p>
                <p className={Styles.amount}>
                  {getNumberFormat(
                    tableData?.balance_details?.total_earning_amount
                  )}
                </p>
              </div>
            </div>
          </div>

          <div className={Styles.tableParentContainer}>
            <div className={Styles.TableChildContainer}>
              <table>
                <thead>
                  <tr>
                    <th
                      className={
                        Styles.tableHead + " text-center ContentFont pt-2 pb-2"
                      }
                    >
                      S.No
                    </th>
                    <th
                      className={Styles.tableHead + " text-center ContentFont"}
                    >
                      Date
                    </th>
                    <th
                      className={Styles.tableHead + " text-center ContentFont"}
                    >
                      Message
                    </th>
                    <th
                      className={Styles.tableHead + " text-center ContentFont"}
                    >
                      Credit
                    </th>

                    <th
                      className={Styles.tableHead + " text-center ContentFont"}
                    >
                      Debit
                    </th>
                    <th
                      className={Styles.tableHead + " text-center ContentFont"}
                    >
                      Old Balance
                    </th>
                    <th
                      className={Styles.tableHead + " text-center ContentFont"}
                    >
                      New Balance
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {tableData?.items !== undefined &&
                    tableData?.items.length > 0 &&
                    tableData?.items.map((e, index) => (
                      <tr key={e.product_id}>
                        <td className="contentFont pt-3 pb-3 text-center">
                          {(tableData.page - 1) * tableData.size + (index + 1)}
                        </td>
                        <td className="contentFont text-center">{e.date}</td>
                        <td className="contentFont text-center">{e.message}</td>
                        <td
                          className={Styles.price + " contentFont text-center"}
                        >
                          {e?.type === 2 ? (
                            <>
                              <p className={Styles.priceSymbol}>₹</p>
                              <p className={Styles.priceAmt}>
                                {e?.amount.toFixed(2)}
                              </p>
                            </>
                          ) : (
                            "-"
                          )}
                        </td>
                        <td className="contentFont text-center">
                          {e?.type === 1 ? (
                            <div className={Styles.price}>
                              <p className={Styles.priceSymbol}>₹</p>
                              <p className={Styles.priceAmt}>
                                {e?.amount.toFixed(2)}
                              </p>
                            </div>
                          ) : (
                            "-"
                          )}
                        </td>
                        <td
                          className={Styles.price + " contentFont text-center"}
                        >
                          <p className={Styles.priceSymbol}>₹</p>
                          <p className={Styles.priceAmt}>
                            {e?.old_balance.toFixed(2)}
                          </p>
                        </td>
                        <td className=" contentFont text-center">
                          <div className={Styles.price}>
                            <p className={Styles.priceSymbol}>₹</p>
                            <p className={Styles.priceAmt}>
                              {e?.new_balance.toFixed(2)}
                            </p>
                          </div>
                        </td>
                      </tr>
                    ))}
                  {tableData?.items?.length !== 0 ? (
                    <tr
                      style={{
                        display: tableData?.length === 0 ? "none" : null,
                      }}
                      className={Styles.totalContainer}
                    >
                      <td></td>
                      <td></td>
                      <td className={Styles.total + " text-center label"}>
                        Total
                      </td>
                      <td className="text-center label">
                        <div className={Styles.price}>
                          <p className={Styles.priceSymbol}>₹</p>
                          <p className={Styles.priceAmt}>
                            {tableData?.transfer_amount?.credit_amount?.toFixed(
                              2
                            )}
                          </p>
                        </div>
                      </td>
                      <td className={Styles.price + " text-center label"}>
                        <p className={Styles.priceSymbol}>₹</p>
                        <p className={Styles.priceAmt}>
                          {tableData?.transfer_amount?.debit_amount?.toFixed(2)}
                        </p>
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                  ) : null}

                  {tableData?.items?.length === 0 || tableData?.length === 0 ? (
                    <tr>
                      <td
                        colSpan={9}
                        className="fs-6 text-secondary p-3 kstoreFont6 text-center"
                      >
                        Data Not Found
                      </td>
                    </tr>
                  ) : null}
                </tbody>
              </table>
            </div>
          </div>

          <div
            style={{
              display:
                tableData?.total <= 200 || tableData?.length === 0
                  ? "none"
                  : "block",
            }}
            className={Styles.paginationContainer}
          >
            <ReactPaginate
              previousLabel={<img src={LeftArrow} alt="left" />}
              nextLabel={<img src={RightArrow} alt="right" />}
              breakLabel="..."
              pageCount={Math.ceil(tableData.total) / 10}
              marginPagesDisplayed={1}
              pageRangeDisplayed={1}
              onPageChange={({ selected }) =>
                handleWalletHistory("", selected + 1)
              }
              containerClassName={"pagination m-0"}
              pageClassName={"page-item"}
              pageLinkClassName={
                "page-link text-secondary rounded-circle bg-light pt-1 pb-1 ps-2 pe-2 ms-1 me-1 border-0 shadow-none"
              }
              forcePage={0}
              previousClassName={"page-item"}
              previousLinkClassName={
                "page-link border-0 text-dark p-1 rounded-circle bg_orange shadow-none me-1"
              }
              nextClassName={"page-item "}
              nextLinkClassName={
                "page-link border-0 text-dark p-1 bg_orange rounded-circle shadow-none ms-1"
              }
              activeClassName={"active"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
