import { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import toast from "react-hot-toast";
import Styles from "./StoreLocator.module.css";
import { storeLocatorService } from "../../Services/ApiServices";
import searchIcon from "../../Assets/searchFilter.png";
import location from "../../Assets/location.png";
import mail from "../../Assets/mail.png";
import call from "../../Assets/call.png";

export default function StoreLocator() {
  const [searchData, setSearchData] = useState("");
  const [listFranchise, setListFranchise] = useState([]);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    handleStoreList();
  }, []);

  const handleStoreList = () => {
    let formData = new FormData();
    setShowError(false);
    formData.append("search_key", searchData);
    storeLocatorService(formData)
      .then((res) => {
        setListFranchise(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast.error(err.response.data.detail[0].msg, { type: "error" });
        } else {
          toast.error("Something went wrong!!");
        }
      });
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div id="stores" className={Styles.parentContainer}>
      <div className="d-flex justify-content-center">
        <h1 className="text-white kstoreFont">
          Store <span className="orange"> Locator</span>
        </h1>
      </div>

      <div className={Styles.inputContainer + " mb-1"}>
        <input
          className={Styles.input}
          type="text"
          value={searchData}
          onChange={(e) => setSearchData(e.target.value)}
          placeholder=" City and Pincode"
        />
        <img
          onClick={handleStoreList}
          className={Styles.searchIcon}
          src={searchIcon}
          alt="download"
        />
      </div>
      {showError ? (
        <p className={Styles.errMsg + " mb-4"}>Enter Valid City/Pincode</p>
      ) : null}
      <Carousel
        autoPlay
        autoPlaySpeed={3000}
        arrows={false}
        infinite={true}
        responsive={responsive}
        className="container"
      >
        {listFranchise?.map((e, index) => (
          <div className={Styles.shopListContainer} key={index}>
            <div className={Styles.shopList}>
              <h1 className="fs-5 text-white align-self-center">
                {e.shop_name}
              </h1>
              {e.shop_city !== "" ||
              e.shop_address !== "" ||
              e.shop_pincode !== "" ? (
                <div className="d-flex mt-3 mb-0">
                  <img src={location} className={Styles.img} />
                  <p>
                    {e.shop_city} {e.shop_address}
                    {(e.shop_city !== "" || e.shop_address !== "") &&
                    e.shop_pincode !== ""
                      ? " - "
                      : ""}
                    {e.shop_pincode}
                  </p>
                </div>
              ) : null}
              <div className="d-flex mt-3 mb-0">
                <img src={call} className={Styles.img} />
                <p>{e.mobile_no}</p>
              </div>
              <div className="d-flex mt-3 mb-0 aligin-items-center">
                <img
                  src={mail}
                  className={Styles.img}
                  style={{
                    height: "18px",
                    width: "18px",
                    marginLeft: "4px",
                    marginTop: "5px",
                  }}
                />
                <p style={{ overflowWrap: "anywhere" }}>{e.email}</p>
              </div>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
}
