import { useState } from "react";
import { Modal } from "react-bootstrap";
import Styles from "./ViewComplaintList.module.css";
import cancel from "../../../Assets/cross.png";

export default function ViewComplaintList(props) {
  const [readmoreId, setReadmoreId] = useState([]);
  return (
    <Modal centered size="lg" show={props?.isShow} onHide={props?.onHide}>
      <Modal.Body>
        <div onClick={props.onHide} className={Styles.cancelParent}>
          <div className={Styles.cancelContainer}>
            <img className={Styles.cancelImg} src={cancel} alt="cancel" />
          </div>
        </div>
        <p className=" fontBold">
          View <span className="orange">Complaint</span>{" "}
        </p>
        <div className={Styles.detailsParentContainer}>
          <div className={Styles.detailsContainer}>
            <p className="fw-bold">Ticket No </p>
            <p>{props?.data?.ticket_no}</p>
          </div>
          <div className={Styles.detailsContainer}>
            <p className="fw-bold">No of Products </p>
            <p>{props?.data?.no_of_products}</p>
          </div>
          <div className={Styles.detailsContainer}>
            <p className="fw-bold">Message </p>
            <p>{props?.data?.message}</p>
          </div>
          <div className={Styles.detailsContainer}>
            <p className="fw-bold">Complaint Status </p>
            <p>{props?.data?.complaints_status}</p>
          </div>
          <div className={Styles.detailsContainer}>
            <p className="fw-bold">Date </p>
            <p>{props?.data?.date}</p>
          </div>
        </div>
        <div className={Styles.tableParentContainer}>
          <div className={Styles.table + " d-flex justify-content-center"}>
            <table>
              <thead>
                <tr>
                  <th className={Styles.cartList + " text-center"}>S.No</th>
                  <th className={Styles.cartList + " text-center"}>
                    Poruduct Name
                  </th>

                  <th className={Styles.cartList + " text-center"}>
                    Damage Qty
                  </th>
                </tr>
              </thead>
              <tbody>
                {props?.data?.products_list?.map((e, index) => (
                  <tr key={e?.purchase_product_id} className={Styles.tr}>
                    <td className={Styles.td + " text-center"}>{index + 1}</td>
                    <td
                      style={{ cursor: "pointer" }}
                      className={" contentFont text-center"}
                    >
                      {readmoreId.includes(e.product_id)
                        ? e.product_name
                        : e.product_name.substring(0, 37)}
                      {e.product_name.length > 37 &&
                      !readmoreId.includes(e.product_id)
                        ? "..."
                        : null}
                      <span
                        style={{
                          display:
                            e.product_name.length < 37 ? "none" : "block",
                        }}
                        className={Styles.readmore + " orange"}
                        onClick={() => {
                          if (readmoreId.includes(e.product_id)) {
                            let filterData = readmoreId.filter(
                              (filterId) => filterId !== e.product_id
                            );
                            setReadmoreId(filterData);
                          } else {
                            setReadmoreId([...readmoreId, e.product_id]);
                          }
                        }}
                      >
                        {readmoreId.includes(e.product_id)
                          ? "Show Less"
                          : "Read More"}
                      </span>
                    </td>

                    <td className="text-center contentFont">
                      {e?.damaged_quantity}
                    </td>
                  </tr>
                ))}
                {props?.data?.products_list?.length === 0 ? (
                  <tr>
                    <td
                      colSpan={8}
                      className="fs-6 text-center text-secondary p-3"
                    >
                      Data Not Found
                    </td>
                  </tr>
                ) : null}
              </tbody>
            </table>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
