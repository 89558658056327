import React, { useState } from "react";
import Loader from "../../../SharedComponents/Loader/Loader";
import { Modal } from "react-bootstrap";
import Styles from "./OrderStatusmodal.module.css";
import cancelIcon from "../../../Assets/cross.png";
import { approvalStatusServices } from "../../../Services/ApiServices";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
export default function ApprovalStatusChangeModal({
  isOpen,
  onHide,
  approvalStatusId,
  listapicall,
}) {
  const [isLoader, setIsLoader] = useState(false);

  const { values, errors, touched, handleSubmit, handleChange, resetForm } =
    useFormik({
      initialValues: {
        status: "0",
        reason: "",
      },
      validationSchema: Yup.object({
        status: Yup.string().required("Required"),
      }),
      onSubmit: () => {
        handleApprovalStatus();
      },
    });
  const handleApprovalStatus = () => {
    setIsLoader(true);
    let formData = new FormData();
    formData.append("purchase_id", approvalStatusId?.purchase_id);
    formData.append("approval_status", values.status);
    if (values.reason) {
      formData.append("reason", values.reason);
    }
    approvalStatusServices(formData)
      .then((res) => {
        listapicall();
        toast.success(res.data.msg, { type: "success" });

        onHide();
        resetForm();
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast.error(err.response.data.detail[0].msg, { type: "error" });
        } else {
          toast.error("Something went wrong !!", { type: "error" });
        }
      })
      .finally(() => {
        setIsLoader(false);
      });
  };
  const handleModalCloe = () => {
    resetForm();
    onHide();
  };

  return (
    <div>
      <Loader isLoader={isLoader} />
      <Modal
        size="md"
        show={isOpen}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className={Styles.cancelImgParentContainer}>
            <div>
              <p className="fontBold">
                Change Approve <span className="orange">Status</span>
              </p>
            </div>
            <div className={Styles.cancelImgContainer}>
              <img
                onClick={handleModalCloe}
                src={cancelIcon}
                alt="cancel"
                className={Styles.cancelImg}
              />
            </div>
          </div>
          <div className="col-md-12 col-sm-12">
            <label className="label">Approve Status</label>
            <select
              className={Styles.select + " contentFont"}
              value={values.status}
              name="status"
              onChange={handleChange}
            >
              <option value="0">Waiting</option>
              <option value="1">Approve</option>
              <option value="2">Reject</option>
            </select>
          </div>
          {touched.status && errors.status ? (
            <div className={Styles.errorMsg + " text-danger"}>
              {errors.status}
            </div>
          ) : null}
          {values.status === "2" ? (
            <>
              <label className="label">Reason</label>
              <div>
                <textarea
                  className={Styles.textarea + " contentFont"}
                  type="text"
                  name="reason"
                  value={values.reason}
                  onChange={handleChange}
                  placeholder="Enter Reason"
                />
              </div>
            </>
          ) : null}
          <div className={Styles.btnContainer}>
            {/* <button onClick={resetForm} className={Styles.Resetbtn}>
              Reset
            </button> */}
            <button onClick={handleSubmit} className={Styles.btn}>
              Submit
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
