import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Styles from "./Popup.module.css";
import EditPrice from "../InventoryPrice/EditPrice";
import {
  minimumAmountService,
  updatePriceService,
} from "../../../Services/ApiServices";
import edit from "../../../Assets/price_edit.png";
import del from "../../../Assets/wishlist_del.svg";

export default function Popup(props) {
  let navigate = useNavigate();
  let { billOptions, setBillOption } = props;
  const [readmoreId, setReadmoreId] = useState("");
  const [minimumAmt, setMinimumAmt] = useState([]);
  const [errCatId, setErrorCatId] = useState([]);
  const [editPrice, setEditPrice] = useState({
    status: false,
    data: {
      id: "",
      selling_price: "",
      bought_price: "",
      customer_price: "",
    },
  });
  const [errorIds, setErrorIds] = useState([]);

  useEffect(() => {
    minimumAmountService()
      .then((res) => {
        setMinimumAmt(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast.error(err.response.data.detail[0].msg, { type: "error" });
        } else {
          toast.error("Something went wrong!!", { type: "error" });
        }
      });
  }, []);

  useEffect(() => {
    if (sessionStorage.length > 0) {
      let option = JSON.parse(sessionStorage.getItem("option"));
      if (parseInt(option) === 2) {
        setBillOption(2);
      } else {
        setBillOption(1);
      }
    }
  }, []);

  useEffect(() => {
    if (props?.billList?.length > 0) {
      props?.billList?.map((ele) => {
        if (errorIds.includes(ele.product_category_id)) {
        } else {
          setErrorIds([...errorIds, ele.product_category_id]);
        }
      });
    } else {
      setErrorCatId([]);
    }
  }, [props?.billList]);

  const handleFranchiseAmount = () => {
    const total = props?.billList?.reduce((prev, current) => {
      if (billOptions === 2) {
        return prev + current?.qty * current?.franchise_price;
      } else {
        return prev + current?.qty * current?.franchise_customer_price;
      }
    }, 0);
    return total;
  };

  const handleModal = () => {
    if (billOptions === 2) {
      handleData(props.billList);
      sessionStorage.setItem("billProducts", JSON.stringify(props?.billList));
      sessionStorage.setItem("option", billOptions);
    } else {
      let temp = [];

      if (props?.billList) {
        temp = [...props.billList];
      }

      if (temp.length > 0) {
        handleData(temp);
        sessionStorage.setItem("billProducts", JSON.stringify(props?.billList));
        sessionStorage.setItem("option", billOptions);
      } else {
        sessionStorage.setItem("billProducts", JSON.stringify(props?.billList));
        sessionStorage.setItem("option", billOptions);
        navigate("/store/checkout", {
          state: {
            items: props?.billList,
            status: billOptions,
            CustomerDetails: props?.customerDetails,
            fromCustomerWishlist: props?.fromCustomerWishlist,
            order_from: props?.type,
          },
        });
      }
    }
  };

  const handleClear = () => {
    props.clearBilllist();
    sessionStorage.removeItem("forCustomerDetails");
  };

  const handleData = (data) => {
    let tempData = [...data];
    let result = {};
    let inActiveProductIndex = tempData.findIndex(
      (value) => value.active_status === 0
    );
    if (inActiveProductIndex === -1) {
      tempData.forEach((element, index) => {
        if (!(element.product_category_id in result)) {
          result[element.product_category_id] = {};
        }
        if (
          !(
            element.product_subcategory_id in
            result[element.product_category_id]
          )
        ) {
          result[element.product_category_id][element.product_subcategory_id] =
            [];
        }
        result[element.product_category_id][
          element.product_subcategory_id
        ].push(element);
      });
      const tempRes = Object.entries(result).map(
        ([category, subCategories]) => {
          return Object.entries(subCategories).map(([subCategory, items]) => {
            return items;
          });
        }
      );
      handleMinimumAmount(tempRes, data);
    } else {
      toast.error(
        `${tempData[inActiveProductIndex].product_name} is out of stock!`
      );
    }
  };

  const handleMinimumAmount = (data, cart) => {
    let tempError = [];
    let tempIsWishList = false;
    let tempOutOfStockProductIndex = cart.findIndex(
      (value) =>
        value.isFromWishList &&
        parseInt(value.qty) > parseInt(value.kansas_quantity)
    );
    if (props.type === false) {
      data.map((e) => {
        let tempCat = minimumAmt.find(
          (ele) => ele.category_id === e[0][0].product_category_id
        );
        tempIsWishList = e.every((val) =>
          val.every(
            (value) => value.isFromWishList && value.qty <= value.quantity
          )
        );
        if (!tempIsWishList) {
          e.map((ele) => {
            if (ele[0].product_subcategory_id !== "") {
              let tempFlag = 0;
              let tempSubAmount = ele.reduce((prev, current) => {
                return prev + current.qty * current.franchise_price;
              }, 0);
              let tempSub = tempCat?.sub_categories?.find(
                (cusCat) =>
                  cusCat.sub_category_id === ele[0].product_subcategory_id
              );
              let tempIsWishListSubCat = ele.every(
                (val) => val.isFromWishList && val.qty <= val.quantity
              );
              if (!tempIsWishListSubCat) {
                if (tempSubAmount < tempSub?.sub_category_amount) {
                  tempFlag = 1;
                  tempError.push({
                    category_id: tempCat.category_id,
                    sub_category_id: tempSub.sub_category_id,
                    category_name: tempCat.category_name,
                    sub_category_name: tempSub.sub_category_name,
                    minimum_amount: tempSub.sub_category_amount - tempSubAmount,
                    type: 2,
                  });
                }
              }
              if (tempFlag === 0) {
                let tempAmount = e.reduce((prev, current) => {
                  return (
                    prev +
                    current.reduce((pre, cur) => {
                      return pre + cur.qty * cur.franchise_price;
                    }, 0)
                  );
                }, 0);

                if (tempAmount < tempCat?.category_min_order_amount) {
                  tempError.push({
                    category_id: tempCat.category_id,
                    sub_category_id: "",
                    category_name: tempCat.category_name,
                    sub_category_name: "",
                    minimum_amount:
                      tempCat.category_min_order_amount - tempAmount,
                    type: 1,
                  });
                }
              }
            } else {
              let tempAmount = e.reduce((prev, current) => {
                return (
                  prev +
                  current.reduce((pre, cur) => {
                    return pre + cur.total_amount;
                  }, 0)
                );
              }, 0);

              if (tempAmount < tempCat.category_min_order_amount) {
                tempError.push({
                  category_id: tempCat.category_id,
                  sub_category_id: "",
                  category_name: tempCat.category_name,
                  sub_category_name: "",
                  minimum_amount: tempCat.category_min_order_amount,
                  type: 1,
                });
              }
            }
          });
        }
      });
    }
    if (tempIsWishList || tempOutOfStockProductIndex === -1) {
      tempError = tempError.filter(
        (value, index, self) =>
          index ===
          self.findIndex(
            (t) =>
              t.category_id === value.category_id &&
              t.sub_category_id === value.sub_category_id
          )
      );

      setErrorCatId(tempError);
      if (tempError.length === 0) {
        sessionStorage.setItem("billProducts", JSON.stringify(props?.billList));
        navigate("/store/checkout", {
          state: {
            items: props?.billList,
            status: billOptions,
            CustomerDetails: props?.customerDetails,
            fromCustomerWishlist: props?.fromCustomerWishlist,
            order_from: props?.type,
          },
        });
      }
    } else {
      toast.error(
        `${cart[tempOutOfStockProductIndex].product_name} is out of stock!`
      );
    }
  };

  // for delete error message
  const handleDeleteMinimumAmt = (catID) => {
    let temp = [...errCatId];
    temp = temp.filter((ele) => ele.category_id !== catID);
    setErrorCatId(temp);
  };

  const handleUpdatePrice = (id, price) => {
    let formData = new FormData();
    formData.append("product_id", parseInt(id));
    formData.append("price", parseInt(price));
    formData.append("check_price", 1);
    updatePriceService(formData)
      .then((res) => {
        props.handleEditAmount(id, price);
        toast("Price Updated", { type: "success" });
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast.error(err.response.data.detail[0].msg);
        } else {
          toast.error("Something went wrong!!");
        }
      });
  };

  return (
    <div className={Styles.overallPopupContainer}>
      {editPrice.status ? (
        <EditPrice
          isOpen={editPrice.status}
          data={editPrice.data}
          handleUpdatePrice={handleUpdatePrice}
          onHide={() =>
            setEditPrice({
              status: false,
              data: {
                id: "",
                selling_price: "",
                bought_price: "",
                customer_price: "",
              },
            })
          }
          isCart={true}
        />
      ) : null}

      <div
        className={Styles.tableContainer}
        style={{ display: props?.billList?.length === 0 ? "none" : null }}
      >
        <div className={Styles.billOptionContainer}>
          <p className="label">Bill For</p>
          <div>
            <input
              className={Styles.billOptionCheckbox}
              type="checkbox"
              value={billOptions}
              onChange={() => {
                setBillOption(1);
              }}
              checked={parseInt(billOptions) === 1}
            />
            <label
              onClick={() => {
                setBillOption(1);
              }}
              className={Styles.billOptionlabel}
            >
              For Customer
            </label>
          </div>
          {!props.type ? (
            <div>
              <input
                className={Styles.billOptionCheckbox}
                type="checkbox"
                value={billOptions}
                onChange={() => setBillOption(2)}
                checked={parseInt(billOptions) === 2}
              />
              <label
                onClick={() => setBillOption(2)}
                className={Styles.billOptionlabel}
              >
                For Franchise
              </label>
            </div>
          ) : null}
        </div>

        <table className="mt-2">
          <thead>
            <tr className={Styles.heading}>
              <th className="py-2 w-50">Products</th>
              <th className="text-center">Qty</th>
              <th className="text-center">Price</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {props?.billList?.map((e) => (
              <tr className={Styles.tr} key={e?.product_id}>
                <td className={" contentFont"}>
                  {readmoreId.includes(e?.product_id)
                    ? e?.product_name
                    : e?.product_name?.substring(0, 37)}
                  {e?.product_name?.length > 37 &&
                  !readmoreId.includes(e?.product_id)
                    ? "..."
                    : null}
                  <div className="d-flex">
                    {e.product_units &&
                      e.product_units.length > 0 &&
                      e.selectedUnit && (
                        <>
                          <p className={Styles.unitContainer}>
                            {e.product_units &&
                              e.product_units.length > 0 &&
                              e.selectedUnit &&
                              e.selectedUnit.unit_id}
                          </p>
                          &nbsp;&nbsp;
                        </>
                      )}
                    {e.colorItem ? (
                      <p className={Styles.unitContainer}>{e.colorItem.name}</p>
                    ) : null}
                  </div>

                  <span
                    style={{
                      display: e?.product_name?.length < 37 ? "none" : "block",
                    }}
                    className={Styles.readmore + " orange"}
                    onClick={() => {
                      if (readmoreId?.includes(e?.product_id)) {
                        let filterData = readmoreId?.filter(
                          (filterId) => filterId !== e?.product_id
                        );
                        setReadmoreId(filterData);
                      } else {
                        setReadmoreId([...readmoreId, e?.product_id]);
                      }
                    }}
                  >
                    {readmoreId.includes(e?.product_id)
                      ? "Show Less"
                      : "Read More"}
                  </span>
                </td>
                <td className="text-center">
                  <input
                    className={Styles.productCount}
                    type="number"
                    defaultValue={
                      props?.fromCustomerWishlist ? e?.quantity : null
                    }
                    value={e?.qty}
                    min={props.type ? 1 : e?.min_quantity}
                    onChange={(qty) => {
                      props.handleQuantity(e?.product_id, qty?.target?.value);
                    }}
                  />
                </td>
                <td className={Styles.td + " contentFont text-center fw-bold"}>
                  ₹{" "}
                  {billOptions === 2
                    ? e.franchise_price
                    : e?.franchise_customer_price}
                  {billOptions === 1 ? (
                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip>Edit price</Tooltip>}
                    >
                      <img
                        className={Styles.editPrice}
                        src={edit}
                        alt="edit"
                        onClick={() => {
                          setEditPrice({
                            status: true,
                            data: {
                              id: e?.product_id,
                              selling_price: e?.franchise_customer_price,
                              bought_price: e?.franchise_price,
                              customer_price: e?.price,
                            },
                          });
                        }}
                      />
                    </OverlayTrigger>
                  ) : null}
                </td>
                <td>
                  <img
                    className={Styles.del}
                    src={del}
                    onClick={() => {
                      props.handleDelete(e.product_id);
                      handleDeleteMinimumAmt(e.product_category_id);
                    }}
                    alt="del"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {props?.billList?.length > 0 ? (
        <div>
          {errCatId?.map((ele) => (
            <div>
              {ele?.type === 1 ? (
                <p key={ele?.errorCat} className={Styles.minAmtError}>
                  You need {" ₹" + ele?.minimum_amount} to order in{" "}
                  {ele?.category_name}
                </p>
              ) : null}
              {ele?.type === 2 ? (
                <p className={Styles.minAmtError}>
                  You need{"₹" + ele?.minimum_amount} to order in{" "}
                  {ele?.sub_category_name}
                </p>
              ) : null}
            </div>
          ))}
        </div>
      ) : null}
      <div>
        <div className={Styles.popupBill}>
          <span className={Styles.popupTitle}>Subtotal</span>
          <span className={Styles.popupTitle}>
            ₹{handleFranchiseAmount()?.toFixed(2)}
          </span>
        </div>
        <div className={Styles.border} />
        <div className={Styles.popupBill}>
          <span className={Styles.popupTitle + " orange"}>Total</span>
          <span className={Styles.popupTitle + " orange"}>
            ₹{handleFranchiseAmount()?.toFixed(2)}
          </span>
        </div>
      </div>
      <div className={Styles.btnContainer + " mt-2"}>
        <button className={Styles.cancelBtn} onClick={handleClear}>
          Clear
        </button>
        <button
          className={Styles.submitBtn}
          disabled={props?.billList?.length === 0 || props?.disabled}
          onClick={handleModal}
          style={{
            backgroundColor:
              props?.billList?.length === 0 || props?.disabled
                ? "#C45D19"
                : "#F27F0C",
          }}
        >
          Submit
        </button>
      </div>
    </div>
  );
}
