import { useState } from "react";
import { Dropdown } from "react-bootstrap";
import Styles from "./InventoryDropdown.module.css";

export default function InventoryDropdown(props) {
  const [id, setId] = useState(1);
  const [selectedData, setSeletcedData] = useState({
    subID: "",
    categoryID: -1,
    status: "",
  });

  return (
    <div className={Styles.overallContainer}>
      <div>
        <div className={Styles.checkboxContainer}>
          <button
            onClick={() => {
              props?.handleGetProduct();
              setId(1);
            }}
            className={Styles.dropdownBtn2 + " kstoreFont"}
            style={{
              color: parseInt(id) === 1 ? "#FF7C25" : "black",
              height: "47.5px",
              alignSelf: "center",
            }}
          >
            All products
          </button>
          {props?.data?.map((e) => (
            <div key={e.category_id}>
              {e.sub_category.length > 0 ? (
                <Dropdown
                  onSelect={(sub_id) => {
                    props.handleGetProduct(sub_id, e.category_id, 1);
                    setSeletcedData({
                      subID: sub_id,
                      categoryID: e.category_id,
                      status: 2,
                    });
                    setId(0);
                  }}
                  id={e.category_name}
                  onToggle={(status) => {
                    if (status) {
                      document
                        .getElementById(e.category_name)
                        .scrollIntoView({ block: "nearest" });
                    }
                  }}
                  className={Styles.dropdownOptions + " pt-2 pb-2"}
                  key={e.category_id}
                >
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    className={
                      parseInt(selectedData?.categoryID) === e.category_id &&
                      selectedData.status === 2 &&
                      id !== 1
                        ? Styles.dropdownBtn +
                          " " +
                          Styles.active +
                          " kstoreFont"
                        : Styles.dropdownBtn +
                          " " +
                          Styles.inActive +
                          " kstoreFont"
                    }
                  >
                    {e.category_name}
                  </Dropdown.Toggle>

                  {e.sub_category.length > 0 ? (
                    <Dropdown.Menu className={Styles.dropdownOptionsParent}>
                      {e?.sub_category?.map((e) => (
                        <Dropdown.Item
                          key={e.sub_category_id}
                          eventKey={e.sub_category_id}
                          onClick={() => setId(0)}
                          className={Styles.dropdownItems + " kstoreFont"}
                          style={{
                            color:
                              parseInt(selectedData.subID) === e.sub_category_id
                                ? "#FF7C25"
                                : "black",
                          }}
                        >
                          {e.subcategory_name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  ) : null}
                </Dropdown>
              ) : (
                <div className="pt-2 pb-2">
                  <button
                    id={e.category_name}
                    onClick={(status) => {
                      props.handleGetProduct("", e.category_id, 1);
                      setSeletcedData({
                        subID: "",
                        categoryID: e.category_id,
                        status: 1,
                      });
                      if (status) {
                        document
                          .getElementById(e.category_name)
                          .scrollIntoView({ block: "nearest" });
                      }
                    }}
                    className={Styles.dropdownBtn2 + " kstoreFont"}
                    style={{
                      color:
                        parseInt(selectedData?.categoryID) === e.category_id &&
                        selectedData.status === 1 &&
                        id !== 1
                          ? "#FF7C25"
                          : "black",
                    }}
                  >
                    {e.category_name}
                  </button>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
