import React, { useEffect, useState } from "react";
import Loader from "../../../SharedComponents/Loader/Loader";
import toast from "react-hot-toast";
import { inventoryControlHistoryService } from "../../../Services/ApiServices";
import ReactPaginate from "react-paginate";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import Styles from "./InventoryHistory.module.css";
import { useLocation } from "react-router-dom";
import LeftArrow from "../../../Assets/modal_left_arrow.png";
import RightArrow from "../../../Assets/modal_right_arrow.png";
import searchIcon from "../../../Assets/searchFilter.png";

export default function InventoryHistory() {
  let { state } = useLocation();
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [filterData, setFilterData] = useState({
    fromDate: "",
    toDate: "",
  });
  useEffect(() => {
    if (state) {
      handleInventoryControlHistory();
    }
  }, []);

  const handleInventoryControlHistory = (pageNumber = 1, reset = false) => {
    setIsLoader(true);
    let formData = new FormData();
    if (!reset) {
      if (filterData.fromDate !== "") {
        formData.append(
          "from_date",
          dayjs(filterData.fromDate).format("YYYY-MM-DD")
        );
      }
      if (filterData.toDate !== "") {
        formData.append(
          "to_date",
          dayjs(filterData.toDate).format("YYYY-MM-DD")
        );
      }
    }
    formData.append("product_id", parseInt(state));
    inventoryControlHistoryService(formData, pageNumber, 10)
      .then((res) => {
        setTableData(res.data);
        setPage(pageNumber - 1);
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast.error(err.response.data.detail[0].msg, { type: "error" });
        } else {
          toast.error("Something went wrong!!");
        }
      })
      .finally(() => setIsLoader(false));
  };

  const handleReset = () => {
    setFilterData({
      fromDate: "",
      toDate: "",
    });
    handleInventoryControlHistory(1, true);
  };

  return (
    <div className={Styles.parentContainer + " container-fluid"}>
      <Loader isLoader={isLoader} />

      <div className={Styles.childContainerParent}>
        <div className={Styles.childContainer}>
          <div className={Styles.searchParentContainer}>
            <p className="fontBold align-self-center">
              Stock <span className="orange">History</span>
            </p>
            <div>
              <img
                className={Styles.searchIcon}
                src={searchIcon}
                alt="search"
                onClick={() => setShowFilter(!showFilter)}
              />
            </div>
          </div>

          {showFilter ? (
            <div className={Styles.filterParentContainer + " row"}>
              <div className="col-md-3 col-sm-12">
                <label className="label">From Date</label>
                <DatePicker
                  value={filterData.fromDate}
                  className={Styles.filterInput + " contentFont"}
                  format={"DD/MM/YYYY"}
                  onChange={(val) => {
                    setFilterData({
                      ...filterData,
                      fromDate: val || "",
                    });
                    if (val > filterData.toDate) {
                      setFilterData((pre) => ({ ...pre, toDate: "" }));
                    }
                  }}
                />
              </div>
              <div className="col-md-3 col-sm-12">
                <label className="label">To Date</label>
                <DatePicker
                  value={filterData.toDate}
                  className={Styles.filterInput + " contentFont"}
                  format={"DD/MM/YYYY"}
                  disabledDate={(current) => {
                    return current < filterData.fromDate;
                  }}
                  onChange={(val) => {
                    setFilterData({
                      ...filterData,
                      toDate: val || "",
                    });
                  }}
                />
              </div>

              <div
                className={
                  Styles.searchBtnParent +
                  " d-flex justify-content-start col-md-3 col-sm-12 align-items-center pt-4"
                }
              >
                <button
                  onClick={() => {
                    handleReset();
                  }}
                  className={Styles.resetBtn + " me-2"}
                >
                  Reset
                </button>
                <button
                  onClick={() => handleInventoryControlHistory(1)}
                  className={Styles.searchBtn}
                >
                  Search
                </button>
              </div>
            </div>
          ) : null}
          <div className={Styles.tableParentContainer}>
            <div className={Styles.TableChildContainer}>
              <table>
                <thead>
                  <tr>
                    <th
                      className={Styles.tableHead + " contentFont text-center"}
                    >
                      S.No
                    </th>
                    <th
                      className={Styles.tableHead + " text-start contentFont"}
                    >
                      Date
                    </th>
                    <th
                      className={Styles.tableHead + " text-center contentFont"}
                    >
                      Old Quantity
                    </th>
                    <th
                      className={Styles.tableHead + " text-center contentFont"}
                    >
                      Added Quantity
                    </th>
                    <th
                      className={Styles.tableHead + " text-center contentFont"}
                    >
                      New Quantity
                    </th>
                    <th
                      className={Styles.tableHead + " text-center contentFont"}
                    >
                      Price
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {tableData?.items !== undefined &&
                    tableData?.items.length > 0 &&
                    tableData?.items.map((e, index) => (
                      <tr key={e.product_id}>
                        <td className="text-center contentFont">
                          {(tableData.page - 1) * tableData.size + (index + 1)}
                        </td>

                        <td className="text-start contentFont">{e?.date}</td>
                        <td className="text-center contentFont">
                          {e?.old_quantity}
                        </td>
                        <td className="text-center contentFont">
                          {e?.added_quantity}
                        </td>
                        <td className="text-center contentFont">
                          {e?.new_quantity}
                        </td>
                        <td
                          className={
                            Styles.sellingPriceContainer +
                            " text-center contentFont"
                          }
                        >
                          <p className={Styles.sellingPrice}>
                            <p className={Styles.priceSymbol}>₹</p>
                            <p className={Styles.priceAmt}>
                              {e?.price?.toFixed(2)}
                            </p>
                          </p>
                        </td>
                      </tr>
                    ))}

                  {tableData?.items?.length === 0 ? (
                    <tr>
                      <td
                        colSpan={10}
                        className="fs-6 text-center text-secondary p-3"
                      >
                        Data Not Found
                      </td>
                    </tr>
                  ) : null}
                </tbody>
              </table>
            </div>
          </div>

          <div
            style={{ display: tableData.total <= 10 ? "none" : "flex" }}
            className={Styles.paginationContainer}
          >
            <ReactPaginate
              previousLabel={<img src={LeftArrow} alt="left" />}
              nextLabel={<img src={RightArrow} alt="right" />}
              breakLabel="..."
              pageCount={Math.ceil(tableData.total) / 10}
              marginPagesDisplayed={1}
              pageRangeDisplayed={1}
              onPageChange={({ selected }) =>
                handleInventoryControlHistory(selected + 1)
              }
              containerClassName={"pagination m-0"}
              pageClassName={"page-item"}
              pageLinkClassName={
                "page-link text-secondary rounded-circle bg-light pt-1 pb-1 ps-2 pe-2 ms-1 me-1 border-0 shadow-none"
              }
              forcePage={page}
              previousClassName={"page-item"}
              previousLinkClassName={
                "page-link border-0 text-dark p-1 rounded-circle bg_orange shadow-none me-1"
              }
              nextClassName={"page-item "}
              nextLinkClassName={
                "page-link border-0 text-dark p-1 bg_orange rounded-circle shadow-none ms-1"
              }
              activeClassName={"active"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
