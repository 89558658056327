import React, { useEffect, useState } from "react";
import Styles from "./KansasOrder.module.css";
import Loader from "../../../SharedComponents/Loader/Loader";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import view from "../../../Assets/view.svg";
import FileSaver from "file-saver";
import downloadIcon from "../../../Assets/download_icon.png";
import { useNavigate } from "react-router-dom";
import searchIcon from "../../../Assets/searchFilter.png";
import { DatePicker } from "antd";
import { kansasOrderList } from "../../../Services/ApiServices";
import toast from "react-hot-toast";
import dayjs from "dayjs";
import OrderStatusChangeModal from "./OrderStatusChangeModal";
import ReactPaginate from "react-paginate";
import LeftArrow from "../../../Assets/modal_left_arrow.png";
import RightArrow from "../../../Assets/modal_right_arrow.png";
import { useFormik } from "formik";
import StatusChangeIcon from "../../../Assets/refresh.png";
import ApprovalStatusChangeModal from "./ApprovalStatusModal";
import OrderStatusIcon from "../../../Assets/orderStatus.png";
import TaskUpdate from "../../../Assets/taskupdate.png";
import moment from "moment";
export default function KansasOrder() {
  let navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [isLoader, setIsLoader] = useState(false);
  const [approvalStatusId, setApprovalStatusId] = useState(null);
  const [orderStatusID, setOrderStatusID] = useState(null);
  const [showFilter, setShowFilter] = useState(false);
  const [OrdersList, setOrdersList] = useState(null);
  const [orderStatusChangeModal, setOrderStatusChangeModal] = useState(false);
  const [approvalStatusChangeModal, setapprovalStatusChangeModal] =
    useState(false);
  const [filterData, setFilterData] = useState({
    fromDate: "",
    toDate: "",
    reference_no: "",
    approval_status: null,
    order_status: null,
    courier_name: "",
    tracking_id: "",
  });

  const handleKansasOrder = (pagenum = 1) => {
    setIsLoader(true);
    let formData = new FormData();
    if (filterData.fromDate) {
      formData.append(
        "from_date",
        dayjs(filterData.fromDate).format("YYYY-MM-DD")
      );
    }
    if (filterData.toDate) {
      formData.append("to_date", dayjs(filterData.toDate).format("YYYY-MM-DD"));
    }
    if (filterData.reference_no) {
      formData.append("reference_no", filterData.reference_no);
    }
    if (filterData.approval_status) {
      formData.append("approval_status", filterData.approval_status);
    }
    if (filterData.order_status) {
      formData.append("order_status", filterData.order_status);
    }
    if (filterData.courier_name) {
      formData.append("courier_name", filterData.courier_name);
    }
    if (filterData.tracking_id) {
      formData.append("tracking_id", filterData.tracking_id);
    }

    const isFormDataEmpty = () => {
      for (let pair of formData.entries()) {
        return false; // If there's at least one entry, formData is not empty
      }
      return true; // If no entries found, formData is empty
    };

    kansasOrderList(pagenum, 10, !isFormDataEmpty() ? formData : "")
      .then((res) => {
        setOrdersList(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast.error(err.response.data.detail[0].msg, { type: "error" });
        } else {
          toast.error("Something went wrong !!", { type: "error" });
        }
      })
      .finally(() => {
        setIsLoader(false);
      });
  };

  const handleReset = (pageNum = 1) => {
    setIsLoader(true);
    setFilterData({
      fromDate: "",
      toDate: "",

      reference_no: "",
      approval_status: "",
      order_status: "",
      courier_name: "",
      tracking_id: "",
    });
    kansasOrderList(pageNum, 10, "")
      .then((res) => {
        setOrdersList(res.data);
        setPage(pageNum - 1);
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast.error(err.response.data.detail[0].msg, { type: "error" });
        } else {
          toast.error("Something went wrong !!", { type: "error" });
        }
      })
      .finally(() => setIsLoader(false));
  };

  const handleDownload = (url) => {
    let urlArr = url.split(".");
    let ext = urlArr[urlArr.length - 1];
    let dateTime =
      moment().format("DD-MM-YYYY") + "," + moment().format("hh:mm:ss a");
    let fileName = dateTime + "." + ext;
    FileSaver.saveAs(url, fileName);
  };

  useEffect(() => {
    handleKansasOrder();
  }, []);
  return (
    <div className={Styles.bg}>
      <Loader isLoader={isLoader} />
      {/* {orderStatusChangeModal ? ( */}
      <ApprovalStatusChangeModal
        isOpen={approvalStatusChangeModal}
        onHide={() => {
          setapprovalStatusChangeModal(false);
        }}
        listapicall={handleKansasOrder}
        approvalStatusId={approvalStatusId}
      />
      <OrderStatusChangeModal
        isOpen={orderStatusChangeModal}
        orderStatusID={orderStatusID}
        listapicall={handleKansasOrder}
        onHide={() => {
          setOrderStatusChangeModal(false);
        }}
      />
      {/* ) : null} */}
      <div className="bg-white">
        <div className={Styles.searchContainer}>
          <p className={Styles.title + " fontBold"}>
            Customer Order <span className="orange">Request List</span>{" "}
          </p>
          <div>
            <img
              onClick={() => setShowFilter(!showFilter)}
              className={Styles.searchImg}
              src={searchIcon}
              alt="search"
            />
          </div>
        </div>

        {showFilter ? (
          <div className={Styles.filterParentContainer + " row"}>
            <div className="col-md-3 col-sm-12">
              <label className="label">Reference Number</label>
              <input
                type="text"
                className={Styles.filterInput + " contentFont"}
                placeholder="Reference Number"
                value={filterData.reference_no}
                onChange={(e) => {
                  setFilterData({
                    ...filterData,
                    reference_no: e.target.value,
                  });
                }}
              />
            </div>
            <div className="col-md-3 col-sm-12">
              <label className="label">Courier Name</label>
              <input
                type="text"
                className={Styles.filterInput + " contentFont"}
                placeholder="Courier Name"
                value={filterData.courier_name}
                onChange={(e) => {
                  setFilterData({
                    ...filterData,
                    courier_name: e.target.value,
                  });
                }}
              />
            </div>
            <div className="col-md-3 col-sm-12">
              <label className="label">Tracking ID</label>
              <input
                type="text"
                className={Styles.filterInput + " contentFont"}
                placeholder="Tracking ID"
                value={filterData.tracking_id}
                onChange={(e) => {
                  setFilterData({
                    ...filterData,
                    tracking_id: e.target.value,
                  });
                }}
              />
            </div>
            <div className="col-md-3 col-sm-12">
              <label className="label">Approval Status</label>
              <select
                className={Styles.filterInput + " contentFont"}
                value={filterData.approval_status}
                name="status"
                onChange={(e) => {
                  setFilterData({
                    ...filterData,
                    approval_status: e.target.value,
                  });
                }}
              >
                <option value={""}>Select</option>
                <option value="0">Waiting</option>
                <option value="1">Approved</option>
                <option value="2">Reject</option>
              </select>
            </div>
            <div className="col-md-3 col-sm-12">
              <label className="label">Order Status</label>
              <select
                className={Styles.filterInput + " contentFont"}
                value={filterData.order_status}
                name="status"
                onChange={(e) => {
                  setFilterData({
                    ...filterData,
                    order_status: e.target.value,
                  });
                }}
              >
                <option value={""}>Select</option>
                <option value={0}>Waiting</option>
                <option value={1}>OrderIn Process</option>
                <option value={2}>Intransit</option>
              </select>
            </div>
            <div className="col-md-3 col-sm-12">
              <label className="label">From Date</label>
              <DatePicker
                value={filterData.fromDate}
                className={Styles.filterInput + " contentFont"}
                format={"DD/MM/YYYY"}
                onChange={(val) => {
                  setFilterData({
                    ...filterData,
                    fromDate: val,
                  });
                  if (val > filterData.toDate) {
                    setFilterData((pre) => ({ ...pre, toDate: "" }));
                  }
                }}
              />
            </div>
            <div className="col-md-3 col-sm-12">
              <label className="label">To Date</label>
              <DatePicker
                value={filterData.toDate}
                className={Styles.filterInput + " contentFont"}
                format={"DD/MM/YYYY"}
                disabledDate={(current) => {
                  return current < filterData.fromDate;
                }}
                onChange={(val) => {
                  setFilterData({
                    ...filterData,
                    toDate: val,
                  });
                }}
              />
            </div>
            <div className=" d-flex justify-content-end align-self-end col-md-3 pt-2">
              <button
                onClick={() => handleReset()}
                className={Styles.resetBtn + " me-2"}
              >
                reset
              </button>
              <button
                onClick={() => handleKansasOrder()}
                className={Styles.searchBtn}
              >
                Search
              </button>
            </div>
          </div>
        ) : null}

        <div className={Styles.tableParentContainer}>
          <div className={Styles.table + " d-flex justify-content-center"}>
            <table>
              <thead>
                <tr>
                  <th className={Styles.th + " text-center"}>S.No</th>
                  <th className={Styles.cartList + " text-center"}>
                    Customer Name
                  </th>
                  <th className={Styles.th + " text-center"}>
                    Reference Number
                  </th>
                  <th className={Styles.cartList + " text-center"}>Mobile</th>

                  <th className={Styles.th + " text-center"}>Total Amount</th>
                  <th className={Styles.cartList + " text-center"}>
                    Total Quantity
                  </th>
                  <th className={Styles.cartList + " text-center"}>
                    Approve Status
                  </th>
                  <th className={Styles.cartList + " text-center"}>
                    Order Status
                  </th>
                  <th className={Styles.cartList + " text-center"}>Date</th>
                  <th className={Styles.th + " text-center"}>Action</th>
                </tr>
              </thead>
              <tbody>
                {OrdersList?.items?.map((e, index) => (
                  <tr key={e?.purchase_product_id} className={Styles.tr}>
                    <td className={Styles.td + " text-center"}>
                      {(OrdersList?.page - 1) * OrdersList?.size + (index + 1)}
                    </td>
                    <td className="text-center contentFont">
                      {e?.name ? e?.name : "-"}
                    </td>
                    <td className="text-center contentFont">
                      {e?.reference_no}
                    </td>
                    <td className="text-center contentFont">
                      {e?.mobile ? e?.mobile : "-"}
                    </td>
                    <td className="text-center contentFont">
                      {e?.total_amount ? e?.total_amount : "-"}
                    </td>
                    <td className="text-center contentFont">
                      {e?.total_qty ? e?.total_qty : "-"}
                    </td>
                    <td className="text-center contentFont">
                      {e?.franchise_order_approval === 1
                        ? "Approved"
                        : e?.franchise_order_approval === 2
                        ? "Reject"
                        : e?.franchise_order_approval === 0
                        ? "Waiting"
                        : "-"}
                    </td>
                    <td className="text-center contentFont">
                      {e?.order_status === 1
                        ? "In Process"
                        : e?.order_status === 2
                        ? "Intransit"
                        : e?.order_status === 3
                        ? "Completed"
                        : e?.order_status === 4
                        ? "Delivered"
                        : e?.order_status === 5
                        ? "Cancelled"
                        : e?.order_status === 0
                        ? "Waiting"
                        : "-"}
                    </td>
                    <td className="text-center contentFont">
                      {e?.date_time
                        ? dayjs(e?.date_time).format("YYYY-MM-DD HH:mm:ss")
                        : "-"}
                    </td>

                    <td className="text-center contentFont">
                      <div style={{ display: "flex" }}>
                        <OverlayTrigger
                          placement="bottom"
                          overlay={<Tooltip>View</Tooltip>}
                        >
                          <img
                            className={Styles.complaintsImg}
                            src={view}
                            alt="view"
                            onClick={() =>
                              navigate("/store/customerOrderrequestview", {
                                state: e,
                              })
                            }
                          />
                        </OverlayTrigger>
                        {(e.franchise_order_approval !== 1 &&
                          e.franchise_order_approval !== 2) ||
                        (e.franchise_order_approval !== 1 &&
                          e.franchise_order_approval !== 2) ? (
                          <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip>Change Approve Status</Tooltip>}
                          >
                            <img
                              className={Styles.changeStatusOrder}
                              src={StatusChangeIcon}
                              alt="ChangeStatus"
                              onClick={() => {
                                setApprovalStatusId(e);
                                setapprovalStatusChangeModal(true);
                              }}
                            />
                          </OverlayTrigger>
                        ) : null}
                        {(e.franchise_order_approval === 1 &&
                          e?.order_status === 0) ||
                        (e.franchise_order_approval === 1 &&
                          e?.order_status === 1) ||
                        (e.franchise_order_approval === 1 &&
                          e?.order_status === 2) ? (
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip>
                                {e?.order_status === 2
                                  ? "Update Shipment Details"
                                  : "Change Order Status"}
                              </Tooltip>
                            }
                          >
                            <img
                              className={Styles.changeStatusOrder}
                              src={
                                e?.order_status === 2
                                  ? TaskUpdate
                                  : OrderStatusIcon
                              }
                              alt="ChangeStatus"
                              onClick={() => {
                                setOrderStatusID(e);
                                setOrderStatusChangeModal(true);
                              }}
                            />
                          </OverlayTrigger>
                        ) : null}
                        {e.franchise_invoice ? (
                          <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip>Download</Tooltip>}
                          >
                            <img
                              className={Styles.complaintsImg}
                              src={downloadIcon}
                              alt="download"
                              onClick={() =>
                                handleDownload(e?.franchise_invoice)
                              }
                            />
                          </OverlayTrigger>
                        ) : null}
                      </div>
                    </td>
                  </tr>
                ))}
                {OrdersList?.items?.length === 0 ? (
                  <tr>
                    <td
                      colSpan={8}
                      className="fs-6 text-center text-secondary p-3"
                    >
                      Data Not Found
                    </td>
                  </tr>
                ) : null}
              </tbody>
            </table>
          </div>
        </div>

        <div
          style={{ display: OrdersList?.total <= 10 ? "none" : "flex" }}
          className={Styles.cartBtnRightContainer}
        >
          <ReactPaginate
            previousLabel={<img src={LeftArrow} alt="left" />}
            nextLabel={<img src={RightArrow} alt="right" />}
            breakLabel="..."
            pageCount={Math.ceil(OrdersList?.total) / 10}
            marginPagesDisplayed={1}
            pageRangeDisplayed={1}
            onPageChange={({ selected }) => handleKansasOrder(selected + 1)}
            forcePage={page}
            containerClassName={"pagination m-0"}
            pageClassName={"page-item"}
            pageLinkClassName={
              "page-link text-secondary rounded-circle bg-light pt-1 pb-1 ps-2 pe-2 ms-1 me-1 border-0 shadow-none"
            }
            previousClassName={"page-item"}
            previousLinkClassName={
              "page-link border-0 text-dark rounded-circle p-1 bg_orange shadow-none me-1"
            }
            nextClassName={"page-item "}
            nextLinkClassName={
              "page-link border-0 text-dark bg_orange p-1 rounded-circle shadow-none ms-1"
            }
            activeClassName={"active"}
          />
        </div>
      </div>
    </div>
  );
}
