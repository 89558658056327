import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import Styles from "./CommonInfiniteScroll.module.css";
const CommonInfiniteScroll = ({
  items,
  fetchData,
  page,
  size,
  hasMore,
  children,
  iddata,
}) => {
  return (
    // <div
    //   style={{
    //     display: "flex",
    //     justifyContent: "end",
    //     zIndex: 1,
    //     position: "absolute",
    //     width: "100%",
    //   }}
    // >
    <InfiniteScroll
      dataLength={items?.length} //This is important field to render the next data
      next={() => fetchData(page, size)}
      hasMore={hasMore}
      loader={<p>Loading...</p>}
      scrollableTarget={iddata}
      endMessage={
        <p style={{ textAlign: "center" }}>
          {items?.length == 0 ? (
            <div className={Styles.nodataFound}>
              <p className={Styles.nodatatext}>No Data</p>
            </div>
          ) : (
            <p style={{ paddingTop: 10 }}>Yay! You have seen it all</p>
          )}
        </p>
      }
      // below props only if you need pull down functionality
      //   refreshFunction={this.refresh}
      // pullDownToRefresh
      // pullDownToRefreshThreshold={50}
      // pullDownToRefreshContent={
      //   <h3 style={{ textAlign: "center" }}>&#8595; Pull down to refresh</h3>
      // }
      // releaseToRefreshContent={
      //   <h3 style={{ textAlign: "center" }}>&#8593; Release to refresh</h3>
      // }
    >
      {children}
    </InfiniteScroll>
  );
};

export default CommonInfiniteScroll;
