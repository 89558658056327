import { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Styles from "./CustomerCartList.module.css";
import { TabTitle } from "../../../Utility/TabTitle";
import emptyImg from "../../../Assets/no_image.svg";
import del from "../../../Assets/delete.svg";
import Header from "../../../SharedComponents/Header/Header";

export default function CustomerCartList() {
  TabTitle("KSTORE - Add-to-cart");
  let navigate = useNavigate();
  let { state, pathname } = useLocation();
  const [cartData, setCartData] = useState([]);
  const [readmoreId, setReadmoreId] = useState("");

  useEffect(() => {
    const session = sessionStorage.getItem("customer_cart_list");
    if (session === null) {
      setCartData(state);
    } else {
      setCartData(JSON.parse(session));
    }
  }, []);

  const handleCartTotal = () => {
    if (cartData.length === 0) {
      return 0;
    }
    const totalAmount = cartData?.reduce((previousValue, currentValue) => {
      return previousValue + currentValue.total_amount;
    }, 0);
    return totalAmount;
  };

  const handleCartCount = (price, id, qty) => {
    let count_amount = price * qty;
    let refData = [...cartData];
    let refIndex = refData?.findIndex((e) => e.product_id === id);
    if (refIndex !== -1) {
      refData[refIndex].qty = qty;
      refData[refIndex].total_amount = count_amount;
      setCartData(refData);
      sessionStorage.setItem("customer_cart_list", JSON.stringify(refData));
    }
  };

  const handleClearCart = (id) => {
    let filterCartList = cartData?.filter((e) => e.product_id !== id);
    setCartData(filterCartList);
  };

  return (
    <>
      <Header />
      <div className={Styles.bg}>
        <div className="bg-white">
          <h1 className={Styles.title + ""}>
            Cart <span className="orange">List</span>{" "}
          </h1>
          <div className={Styles.overallcart}>
            <div className={Styles.leftTable}>
              <div className={Styles.mobileCart + " d-block d-sm-none"}>
                {cartData &&
                  cartData?.map((e, index) => (
                    <div className={Styles.item}>
                      <a
                        onClick={() => handleClearCart(e?.product_id)}
                        href="#"
                      >
                        <img
                          className={Styles.clsicon}
                          width="13px"
                          src="https://www.ksnmdrip.com/themes/bootstraptheme/assets/img/icons/close_icon.svg"
                        />
                      </a>
                      <img
                        alt="vv"
                        style={{ height: "65px", width: "65px" }}
                        src={
                          e?.product_images[0]?.image?.length
                            ? e?.product_images[0]?.image[0]
                            : emptyImg
                        }
                      />
                      <p className={Styles.title}>
                        <a href="#">
                          {readmoreId === e?.customer_wishlist_id
                            ? e?.product_name
                            : e?.product_name.substring(0, 37)}
                        </a>
                      </p>
                      {(e.colorItem || e.selectedUnit) && (
                        <div className={Styles.unitColor}>
                          {e.selectedUnit && (
                            <p className={Styles.title}>
                              Unit : {e.selectedUnit.unit_id}
                            </p>
                          )}
                          {e.colorItem && (
                            <p className={Styles.title}>
                              Color : {e.colorItem.name}
                            </p>
                          )}
                        </div>
                      )}

                      <div className={Styles.quantity}>
                        <input
                          className={Styles.productCount}
                          type="number"
                          value={e?.qty}
                          min="1"
                          onChange={(userCount) => {
                            handleCartCount(
                              e?.selling_price,
                              e?.product_id,
                              userCount.target.value
                            );
                          }}
                        />
                      </div>

                      <p className={Styles.price}>
                        <span class="currency_symbol">₹</span>{" "}
                        {e?.selling_price}
                      </p>
                    </div>
                  ))}
                {cartData?.length === 0 ? (
                  <p className="fs-6 text-center text-secondary p-3">
                    Data Not Found
                  </p>
                ) : null}
              </div>

              <div
                className={
                  Styles.table +
                  " table-responsive d-none d-sm-block customerCart justify-content-center"
                }
              >
                <table>
                  <thead>
                    <tr>
                      <th className={Styles.th + " text-center"}>S.No</th>
                      <th className={Styles.cartProductName + ""}>
                        Product Name
                      </th>
                      <th className={Styles.th + " text-center"}>Color</th>
                      <th className={Styles.th + " text-center"}>Unit</th>
                      <th className={Styles.th + " text-center"}>Quantity</th>
                      <th className={Styles.th + " text-center"}>Price</th>
                      <th className={Styles.cartList + " text-center"}>
                        Total
                      </th>
                      <th className={Styles.cartList + " text-center"}>
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {cartData?.map((e, index) => (
                      <tr key={e.product_id} className={Styles.tr}>
                        <td className="text-center">{index + 1}</td>
                        <td
                          className={Styles.productContainer + " contentFont"}
                        >
                          <div className={Styles.imgContainer}>
                            <img
                              className={Styles.img}
                              src={
                                e?.product_images[0]?.image?.length
                                  ? e?.product_images[0]?.image[0]
                                  : emptyImg
                              }
                              alt="cart"
                            />
                          </div>
                          <p className={Styles.productDetails + " mb-0 "}>
                            {readmoreId === e.customer_wishlist_id
                              ? e.product_name
                              : e.product_name.substring(0, 37)}
                            <span
                              style={{
                                display:
                                  e.product_name.length < 37 ||
                                  readmoreId === e.customer_wishlist_id
                                    ? "none"
                                    : "block",
                              }}
                              className={Styles.readmore + " orange cp"}
                              onClick={() =>
                                setReadmoreId(e.customer_wishlist_id)
                              }
                            >
                              Read More
                            </span>
                          </p>
                        </td>
                        <td className="text-center contentFont">
                          <p className={Styles.productDetails + " mb-0 "}>
                            {e.colorItem ? e.colorItem.name : ""}
                          </p>
                        </td>
                        <td className="text-center contentFont">
                          <p className={Styles.productDetails + " mb-0 "}>
                            {e.selectedUnit ? e.selectedUnit.unit_id : ""}
                          </p>
                        </td>

                        <td className="text-center contentFont">
                          <input
                            className={Styles.productCount}
                            type="number"
                            value={e?.qty}
                            min="1"
                            onChange={(userCount) => {
                              handleCartCount(
                                e?.selling_price,
                                e?.product_id,
                                userCount.target.value
                              );
                            }}
                          />
                        </td>
                        <td className="contentFont text-center">
                          {e?.selling_price}
                        </td>
                        <td
                          className={
                            Styles.td + " orange contentFont text-center"
                          }
                        >
                          {"₹" + e.total_amount}
                        </td>
                        <td className="text-center">
                          <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip>Delete</Tooltip>}
                          >
                            <img
                              className={Styles.del}
                              src={del}
                              onClick={() => handleClearCart(e.product_id)}
                              alt="del"
                            />
                          </OverlayTrigger>
                        </td>
                      </tr>
                    ))}
                    {cartData?.length === 0 ? (
                      <tr>
                        <td
                          colSpan={7}
                          className="fs-6 text-center text-secondary p-3"
                        >
                          Data Not Found
                        </td>
                      </tr>
                    ) : null}
                  </tbody>
                </table>
              </div>

              <div
                style={{
                  display: pathname.includes("/kansas_product") ? "none" : null,
                }}
                className={Styles.cartBtnContainer + " mt-3"}
              >
                {cartData?.length > 0 ? (
                  <div className={Styles.cartBtnLeftContainer}>
                    <button
                      onClick={handleClearCart}
                      className={Styles.clearCart + " text-uppercase"}
                    >
                      Clear Cart
                    </button>
                  </div>
                ) : null}
              </div>
            </div>

            <div className={Styles.rightCart}>
              <div className={Styles.mblCartotal}>
                <p className="kstoreFont">Cart Total</p>
                <div className={Styles.totalDetailsContainer}>
                  <div
                    className={
                      Styles.priceDiv + " d-flex justify-content-between"
                    }
                  >
                    <span className={Styles.font}>Sub Total </span>
                    <span className={Styles.font}>
                      ₹{handleCartTotal(cartData).toFixed(2)}
                    </span>
                  </div>
                  <div className="d-flex justify-content-between ">
                    <span className={Styles.font}>Total </span>
                    <span className={Styles.font}>
                      ₹{handleCartTotal(cartData).toFixed(2)}
                    </span>
                  </div>
                </div>
                <button
                  className={Styles.checkoutBtn}
                  style={
                    cartData?.length === 0
                      ? { cursor: "default", backgroundColor: "#AC5701" }
                      : { cursor: "pointer", backgroundColor: "#F27F0C" }
                  }
                  disabled={cartData?.length === 0}
                  onClick={() => {
                    navigate("/kansas_product/checkout", {
                      state: { items: cartData, customerStatus: 1 },
                    });
                    sessionStorage.setItem(
                      "customer_cart_list",
                      JSON.stringify(cartData)
                    );
                  }}
                >
                  Proceed to Checkout
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
