import axios from "axios";
import { getCookie, setCookie } from "../Utility/Utility";
import { BaseURL } from "../Utility/Utility";

const instance = axios.create({
  baseURL: BaseURL,
});

instance.interceptors.request.use(
  (request) => {
    const userlog =
      getCookie("userlog") !== "" ? JSON.parse(getCookie("userlog")) : null;

    if (userlog) {
      request.headers["Authorization"] = `Bearer ${userlog.access_token}`;
    }
    return request;
  },
  (error) => Promise.reject(error)
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 403) {
      // localStorage.setItem("userlog", null);
      setCookie("userlog", null, 0);
      window.location.replace("/");
    } else {
      return Promise.reject(error);
    }
  }
);
export default instance;
