import Styles from "./Wallet.module.css";
import withdraw from "../../../Assets/debit.png";
import topup from "../../../Assets/credit.png";

export default function Wallet(props) {
  return (
    <div className={Styles.overallContainer}>
      <div className={Styles.imgContainer}>
        <img
          className={Styles.img}
          src={props?.data?.transfer_type === "Withdraw" ? withdraw : topup}
          alt="download"
        />
        <div className={Styles.dateContainer}>
          <p className="mb-0 fw-bold">
            {props?.data?.type === 1 ? "Debit" : "Credit"}
          </p>
        </div>
      </div>
      <p
        style={{ color: props?.data?.type === 1 ? "red" : "green" }}
        className={Styles.amt + " align-self-center fw-bold"}
      >
        ₹ {props?.data?.amount?.toFixed(2)}
      </p>

      <p className={Styles.date}>{props.data.date}</p>
    </div>
  );
}
