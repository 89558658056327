import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import Styles from "./MyorderView.module.css";
import {
  addComplaintService,
  viewMyorderService,
} from "../../../Services/ApiServices";
import emptyImg from "../../../Assets/no_image.svg";
import Loader from "../../../SharedComponents/Loader/Loader";

export default function MyorderView() {
  let { state } = useLocation();
  const [readmoreId, setReadmoreId] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [viewData, setViewData] = useState(null);
  const [damageQtyDetails, setDamageQtyDetails] = useState([]);
  const [damageError, setDamageError] = useState([]);
  const [complaintMsg, setComplaintMsg] = useState("");

  useEffect(() => {
    handleMyorderView();
  }, []);

  const handleMyorderView = (pageNum = 1) => {
    setIsLoader(true);
    let formData = new FormData();
    formData.append("purchase_details_id", parseInt(state));
    viewMyorderService(pageNum, 10, formData)
      .then((res) => {
        setViewData(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast.error(err.response.data.detail[0].msg, { type: "error" });
        } else {
          toast.error("Data Not Found !!", { type: "error" });
        }
      })
      .finally(() => setIsLoader(false));
  };

  const handlenDamageQty = (qty, id, purchase_id) => {
    setDamageQtyDetails((p) => {
      let refData = [...p];
      let temp = refData.map(
        (element) => element?.product_id && element?.purchase_id
      );
      if (temp?.includes(id && purchase_id)) {
        let refIndex = refData.findIndex(
          (ele) => ele.product_id === id && ele.purchase_id === purchase_id
        );
        if (refIndex > -1 && refData[refIndex].quantity === "") {
          refData.splice(refIndex, 1);
        } else {
          refData[refIndex].quantity = qty;
        }
      } else {
        refData = [
          ...refData,
          { product_id: id, quantity: qty, purchase_id: purchase_id },
        ];
      }
      return refData;
    });
  };

  const handleDamageError = (qty, id, total_qty) => {
    if (qty > total_qty) {
      setDamageError([...damageError, id]);
    } else {
      let filterid = damageError.filter((ele) => ele !== id);
      setDamageError(filterid);
    }
  };

  const handleComplaint = () => {
    if (damageError.length === 0) {
      setIsLoader(true);
      let product_id = damageQtyDetails.map((ele) => ele.product_id);
      let quantity = damageQtyDetails.map((element) => element.quantity);
      let formData = new FormData();
      formData.append("message", complaintMsg);
      formData.append("purchase_detail_id", parseInt(state));
      formData.append("product_id", product_id);
      formData.append("damaged_quantity", quantity);
      addComplaintService(formData)
        .then((res) => {
          toast("Your Complaint has been sended successfully", {
            type: "success",
          });
        })
        .catch((err) => {
          if (err?.response?.data?.detail) {
            toast.error(err.response.data.detail[0].msg, { type: "error" });
          } else {
            toast.error("Data Not Found !!", { type: "error" });
          }
        })
        .finally(() => setIsLoader(false));
    }
  };
  return (
    <div className={Styles.bg}>
      <Loader isLoader={isLoader} />
      <div className={Styles.childContainer + " bg-white"}>
        <p className={Styles.title + " fontBold"}>
          My Order <span className="orange">Products</span>
        </p>
        <div className={Styles.addressContainer}>
          <div className={Styles.addressLeftSec}>
            <p className="fontBold">
              Billing <span className="orange">Address</span>
            </p>
            <p className={Styles.address + " fw-bold"}>
              {viewData?.billing_name}
            </p>
            <p className={Styles.address}>{viewData?.billing_address}</p>
            <p className={Styles.address}>{viewData?.billing_city}</p>
            <p className={Styles.address}>
              {viewData?.billing_state}
              {viewData?.billing_state && viewData?.billing_pincode
                ? " - "
                : ""}
              {viewData?.billing_pincode}
            </p>
            <p className={Styles.address}>{viewData?.billing_email}</p>
            <p className={Styles.address}>{viewData?.billing_mobile}</p>
          </div>
          <div className={Styles.addressRightSec}>
            <p className="fontBold">
              Shipping <span className="orange">Address</span>
            </p>
            <p className={Styles.address + " fw-bold"}>
              {viewData?.shipping_name}
            </p>
            <p className={Styles.address}>{viewData?.shipping_address}</p>
            <p className={Styles.address}>{viewData?.shipping_city}</p>
            <p className={Styles.address}>
              {viewData?.shipping_state}
              {viewData?.shipping_state && viewData?.shipping_pincode
                ? " - "
                : ""}
              {viewData?.shipping_pincode}
            </p>

            <p className={Styles.address}>{viewData?.shipping_email}</p>
            <p className={Styles.address}>{viewData?.shipping_mobile}</p>
          </div>
        </div>
        <div className="d-flex justify-content-end">
          <p className={Styles.status}>
            Date : <span>{viewData?.ordered_date}</span>
          </p>
          <p className={Styles.status}>
            Order Status : <span>{viewData?.purchased_status}</span>
          </p>
        </div>
        <div className={Styles.tableParentContainer}>
          <div className={Styles.table + " d-flex justify-content-center"}>
            <table>
              <thead>
                <tr>
                  <th className={Styles.th + " text-center"}>S.No</th>
                  <th className={Styles.cartProductName + " text-center"}>
                    Product Name
                  </th>
                  <th className={Styles.th + " text-center"}>Quantity</th>
                  <th className={Styles.th + " text-center"}>
                    Courier Name/Number
                  </th>
                  <th className={Styles.th + " text-center"}>Price</th>
                  <th className={Styles.th + " text-center"}>Total</th>
                  {viewData?.complaint_show_status !== 0 ? (
                    <th className={Styles.damage + " text-center"}>
                      Damage Qty
                    </th>
                  ) : null}
                </tr>
              </thead>
              <tbody>
                {viewData?.purchased_products?.map((e, index) => (
                  <tr key={e?.purchase_product_id} className={Styles.tr}>
                    <td className="text-center">{index + 1}</td>
                    <td
                      className={
                        Styles.productContainer + " contentFont text-center"
                      }
                    >
                      <div className={Styles.imgContainer}>
                        <img
                          className={Styles.img}
                          src={e?.product_image ? e?.product_image : emptyImg}
                          alt="cart"
                        />
                      </div>
                      <div className="w-50 align-self-center">
                        <p
                          className={
                            Styles.productDetails +
                            " mb-0 contentFont text-center"
                          }
                        >
                          {readmoreId.includes(e?.product_id)
                            ? e?.product_name
                            : e?.product_name?.substring(0, 37)}
                          {e?.product_name?.length > 37 &&
                          !readmoreId.includes(e?.product_id)
                            ? "..."
                            : null}
                          <span
                            style={{
                              display:
                                e?.product_name?.length < 37 ||
                                readmoreId === e?.product_id
                                  ? "none"
                                  : "block",
                            }}
                            className={Styles.readmore + " orange cp"}
                            onClick={() => {
                              if (readmoreId.includes(e?.product_id)) {
                                let filterData = readmoreId.filter(
                                  (filterId) => filterId !== e?.product_id
                                );
                                setReadmoreId(filterData);
                              } else {
                                setReadmoreId([...readmoreId, e?.product_id]);
                              }
                            }}
                          >
                            {readmoreId.includes(e?.product_id)
                              ? "Show Less"
                              : "Read More"}
                          </span>
                        </p>
                      </div>
                    </td>
                    <td className="text-center contentFont">
                      {e?.quantity ? e?.quantity : "-"}
                    </td>
                    <td className="text-center contentFont">
                      {e?.courier_name ? e?.courier_name : "-"}
                    </td>
                    <td className="contentFont text-center">
                      {"₹" + e?.price?.toFixed(2)}
                    </td>
                    <td className="text-center contentFont">
                      {e?.total ? "₹" + e?.total?.toFixed(2) : "-"}
                    </td>
                    {viewData?.complaint_show_status !== 0 ? (
                      <td className="text-center contentFont">
                        <input
                          className={Styles.damageQtyInput}
                          onKeyPress={(e) => {
                            if (e.key === "0" || parseInt(e.key)) {
                            } else {
                              e.preventDefault();
                            }
                          }}
                          min="1"
                          max={e.quantity}
                          onChange={(ele) => {
                            handlenDamageQty(
                              ele.target.value,
                              e?.product_id,
                              e?.purchase_product_id
                            );
                            handleDamageError(
                              ele.target.value,
                              e.product_id,
                              e.quantity
                            );
                          }}
                          type="number"
                        />
                        {damageError?.includes(e?.product_id) ? (
                          <p className="fw-bold text-danger options">
                            Damage Quantity must be lessthan quantity
                          </p>
                        ) : null}
                      </td>
                    ) : null}
                  </tr>
                ))}
                {viewData?.purchased_products?.length === 0 ? (
                  <tr>
                    <td
                      colSpan={8}
                      className="fs-6 text-center text-secondary p-3"
                    >
                      Data Not Found
                    </td>
                  </tr>
                ) : null}
              </tbody>
              {viewData?.purchased_products?.length !== 0 ? (
                <tfoot>
                  <tr>
                    <td colSpan={4} />
                    <td className="text-center fw-bold orange">Total</td>
                    <td className={Styles.th + " text-center"}>
                      ₹
                      {viewData?.purchased_products
                        ?.reduce((prev, current) => {
                          return prev + current.total;
                        }, 0)
                        .toFixed(2)}
                    </td>
                  </tr>
                </tfoot>
              ) : null}
            </table>
          </div>
          {viewData?.complaint_show_status !== 0 ? (
            <>
              <div className={Styles.msgContainer}>
                <p className="label">Complaint</p>
                <textarea
                  className={Styles.msg}
                  onChange={(e) => setComplaintMsg(e.target.value)}
                  type="text"
                  placeholder="Enter Your Complaint Here"
                />
              </div>
              <div className="text-end">
                <button
                  onClick={handleComplaint}
                  type="submit"
                  className={Styles.btn}
                >
                  Submit
                </button>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
}
