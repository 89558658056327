import { useState } from "react";
import Styles from "./DropDown.module.css";
export default function CategoryDropdown(props) {
  const [isShow, setIsShow] = useState(false);

  return (
    <div style={{ width: props.listType === 1 ? "100%" : null }}>
      <p
        key={props?.data?.category_id}
        className={Styles.heading + " dropdownHeading"}
        style={{
          color:
            parseInt(props.categoryID.cat_id) === props?.data?.category_id
              ? "#FF7C25"
              : "black",
        }}
        onClick={() => {
          if (props?.data?.sub_category?.length > 0) {
            setIsShow(!isShow);
            props.handleGetProduct(null, props?.data?.category_id, "");
            props?.handleAllproductId(0);
          } else {
            props.handleGetProduct(
              null,
              props?.data?.category_id,
              props?.data?.category_name
            );
            props?.handleAllproductId(0);
          }
        }}
      >
        {props?.data?.category_name}
      </p>

      {isShow ? (
        <>
          {props?.data?.sub_category?.map((e) => (
            <p
              className={Styles.subHeading + " options"}
              key={e?.sub_category_id}
              onClick={() => {
                props.handleGetProduct(
                  e.sub_category_id,
                  props?.data?.category_id,
                  props?.data?.category_name
                );
                props.handleAllproductId(0);
              }}
              style={{
                color:
                  parseInt(props.categoryID.sub_id) === e.sub_category_id
                    ? "#FF7C25"
                    : "black",
              }}
            >
              {e?.subcategory_name}
            </p>
          ))}
        </>
      ) : null}
    </div>
  );
}
