import { useLocation } from "react-router-dom";
import Styles from "./TrackOrder.module.css";

export default function TrackOrder() {
  let { state } = useLocation();
  return (
    <div className=" container-fluid p-0">
      <iframe className={Styles.container} src={state} />
    </div>
  );
}
