import Styles from "./Payment.module.css";

export default function PaymentDetails(props) {
  return (
    <div
      onClick={() =>
        props.handleCustomerView(props?.data?.customer_wishlist_id)
      }
      className={Styles.overallContainer}
    >
      <div className={Styles.nameContainer}>
        <p className={Styles.name + " dropdownHeading fw-bold orange"}>
          {props?.data?.customer_name}
        </p>
        <p className={Styles.count}>
          <span>No of products : </span> {props?.data?.count_of_products}
        </p>
      </div>

      <div className={Styles.dateContainer}>
        <p className={Styles.date + " contentFont"}>
          {props?.data?.requested_at}
        </p>
        <p className={Styles.amount}>₹{props?.data?.total_amount.toFixed(2)}</p>
      </div>
    </div>
  );
}
