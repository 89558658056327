import { Navigate } from "react-router-dom";
import { getCookie } from "./Utility";
export const AuthPrivateRoute = ({ children }) => {
  const userlog =
    getCookie("userlog") !== "" ? JSON.parse(getCookie("userlog")) : null;

  if (userlog) {
    return <Navigate to="/" />;
  }

  return children;
};

export const StorePrivateRoute = ({ children }) => {
  const userlog =
    getCookie("userlog") !== "" ? JSON.parse(getCookie("userlog")) : null;
  if (!userlog) {
    return <Navigate to="/auth" />;
  }

  return children;
};

export const CustomerPrivateRoute = ({ children }) => {
  const customerlog = sessionStorage.getItem("customerlog");
  const id = getCookie("franchiseid");
  if (id !== "") {
    return <Navigate to={`/kansas_product/shop/${id}`} />;
  }
  if (!customerlog) {
    return <Navigate to="/" />;
  }
  return children;
};

export const VerifyPrivateRoute = ({ children }) => {
  const verifyStatus = sessionStorage.getItem("otpStatus");
  if (verifyStatus !== null) {
    return <Navigate to="/store" />;
  }
  return children;
};
