import { useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import FileSaver from "file-saver";
import Styles from "./CustomerInvoice.module.css";
import { billingService } from "../../../Services/ApiServices";
import Loader from "../../../SharedComponents/Loader/Loader";
import next from "../../../Assets/next.png";

export default function CustomerInvoice() {
  let { state } = useLocation();
  let navigate = useNavigate();
  const [isLoader, setIsLoader] = useState(false);
  const [paymentMode, setPaymentMode] = useState(3);
  const [paymentError, setPaymentError] = useState(false);
  const [paymentDetils, setPaymentDetails] = useState(null);
  const [readmoreId, setReadmoreId] = useState([]);
  let flag = 0;

  const handleBill = (data) => {
    if (paymentMode !== "") {
      let id = state?.wishlist_products?.map((e) => parseInt(e?.product_id));
      let quantity = state?.wishlist_products?.map((e) =>
        parseInt(e?.quantity)
      );
      let amount = state?.wishlist_products?.map((e) =>
        parseInt(e?.total_amount)
      );
      let orderDetails = state?.wishlist_products?.map((e) => {
        return {
          product_id: e?.product_id,
          quantity: e?.quantity,
          product_unit: 0,
          product_color: 0,
          amount: e?.total_amount,
        };
      });

      let formData = new FormData();
      formData.append("product_id", "[" + id + "]");
      formData.append("quantity", "[" + quantity + "]");
      formData.append("amount", "[" + amount + "]");
      formData.append(
        "customer_wishlist_products",
        state?.customer_wishlist_id
      );
      formData.append("order_details", JSON.stringify(orderDetails));
      formData.append("customer_name", data.customer_name);
      formData.append("mobile_no", data.mobile_no);
      formData.append("address", data.address);
      formData.append("state", data.state);
      formData.append("city", data.city);
      formData.append("payment_type", parseInt(paymentMode));
      formData.append("pincode", data.pincode);
      formData.append("gst_no", data.gst_no);
      formData.append("invoice_no", data.invoice_no);
      formData.append("order_from", state?.order_from ? 1 : 0);
      // newBillingService(formData)
      billingService(formData)
        .then((res) => {
          if (paymentMode === 1) {
            setPaymentDetails(res.data);
            document.getElementById("upipayment").submit();
          }
          if (paymentMode === 2) {
            setPaymentDetails(res.data.data);
            document.getElementById("worldlinepayment").submit();
          }
          if (paymentMode === 3) {
            let url = res.data;
            let urlArr = url.split(".");
            let ext = urlArr[urlArr.length - 1];
            let dateTime =
              moment().format("DD-MM-YYYY") +
              "," +
              moment().format("hh:mm:ss a");
            let fileName = dateTime + "." + ext;
            FileSaver.saveAs(url, fileName);
          }
          if (flag === 1) {
            navigate("/store/bill_list");
          }

          sessionStorage.removeItem("storeDetails");
          sessionStorage.removeItem("billProducts");
        })
        .catch((err) => {
          if (err?.response?.data?.detail) {
            toast.error(err.response.data.detail[0].msg);
          } else {
            toast.error("Something went wrong!!");
          }
        })
        .finally(() => setIsLoader(false));
    } else {
      setPaymentError(true);
    }
  };

  const handleCartTotal = () => {
    if (state?.wishlist_products?.length === 0) {
      return 0;
    }
    const totalAmount = state?.wishlist_products?.reduce(
      (previousValue, currentValue) => {
        return (
          previousValue + parseInt(currentValue.price * currentValue.quantity)
        );
      },
      0
    );
    return totalAmount;
  };

  return (
    <div className="container-fluid p-0">
      <Loader isLoader={isLoader} />
      <div className="container">
        <p className="fontBold text-center">
          Invoice <span className="orange">Details</span>
        </p>

        <div className={Styles.parentDetails}>
          <div className={Styles.leftDetails}>
            <p className="fontBold orange">Billing Address : </p>
            <p className={Styles.details + " fw-bold"}>
              {state?.customer_name}
            </p>
            <p className={Styles.details}>{state?.city_name}</p>
            <p className={Styles.details}>
              {state?.state_name}
              {state?.state_name && state?.pincode ? " - " : ""}
              {state?.pincode}
            </p>
            <p className={Styles.details}>{state?.email}</p>
            <p className={Styles.details}>
              <span>Phone : </span>
              {state?.mobile_no}
            </p>
          </div>
          <div className={Styles.rightDetails}>
            <p className="fontBold orange">Shipping Address : </p>
            <p className={Styles.shippingDetails + " fw-bold"}>
              {state?.customer_name}
            </p>
            <p className={Styles.shippingDetails}>{state?.city_name}</p>
            <p className={Styles.shippingDetails}>
              {state?.state_name}
              {state?.state_name && state?.pincode ? " - " : ""}
              {state?.pincode}
            </p>
            <p className={Styles.shippingDetails}>{state?.email}</p>
            <p className={Styles.shippingDetails}>
              <span>Phone : </span>
              {state?.mobile_no}
            </p>
          </div>
        </div>
        <div className={Styles.tableParent}>
          <div className={Styles.swichContainer}>
            <p className="fontBold">
              Product <span className="orange">Details</span>{" "}
            </p>
          </div>
          <div className={Styles.ParentTable}>
            <div className={Styles.childTable}>
              <table>
                <thead>
                  <tr>
                    <th className={Styles.th + " text-center"}>S.No</th>
                    <th className={Styles.cartProductName + " text-center"}>
                      Product Name
                    </th>
                    <th
                      style={{
                        display:
                          state?.status === 1 ||
                          state?.productDetails?.customerStatus === 1
                            ? "none"
                            : "block",
                      }}
                      className={Styles.th + " text-center"}
                    >
                      Stock
                    </th>
                    <th className={Styles.th + " text-center"}>Quantity</th>
                    <th className={Styles.th + " text-center"}>Price</th>
                    <th
                      style={{
                        display: state.stockStatus === 2 ? "block" : "none",
                      }}
                      className={Styles.th + " text-center"}
                    >
                      Franchise Price
                    </th>
                    <th className={Styles.cartList + " text-center"}>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {state?.wishlist_products?.map((e, index) => (
                    <>
                      <tr>
                        <td className={Styles.th + " text-center contentFont"}>
                          {index + 1}
                        </td>
                        <td
                          className={
                            Styles.productName + " text-center contentFont"
                          }
                        >
                          {readmoreId.includes(e.product_id)
                            ? e.product_name
                            : e.product_name.substring(0, 37)}
                          {e.product_name.length > 37 &&
                          !readmoreId.includes(e.product_id)
                            ? "..."
                            : null}
                          <span
                            style={{
                              display:
                                e.product_name.length < 37 ? "none" : "block",
                            }}
                            className={Styles.readmore + " orange"}
                            onClick={() => {
                              if (readmoreId.includes(e.product_id)) {
                                let filterData = readmoreId.filter(
                                  (filterId) => filterId !== e.product_id
                                );
                                setReadmoreId(filterData);
                              } else {
                                setReadmoreId([...readmoreId, e.product_id]);
                              }
                            }}
                          >
                            {readmoreId.includes(e.product_id)
                              ? "Show Less"
                              : "Read More"}
                          </span>
                        </td>
                        <td
                          style={{
                            color: e?.total_qty > e?.quantity ? "green" : "red",
                          }}
                          className={Styles.th + " text-center contentFont"}
                        >
                          {e?.total_qty >= e?.quantity
                            ? "In Stock"
                            : "Out of stock " +
                              "(" +
                              (e?.quantity - e?.total_qty) +
                              ")"}
                        </td>
                        <td className={Styles.th + " text-center contentFont"}>
                          {e?.quantity}
                        </td>
                        <td
                          className={Styles.price + " text-center contentFont"}
                        >
                          <p className={Styles.priceSymbol}>₹</p>
                          <p className={Styles.priceAmt}>{e?.price}</p>
                        </td>
                        <td
                          style={{
                            display: state.stockStatus !== 2 ? "none" : null,
                          }}
                          className="text-center contentFont"
                        >
                          {e?.total_qty >= e?.quantity
                            ? "-"
                            : "₹" + e?.franchise_total_amount.toFixed(2)}
                        </td>
                        <td className=" text-center contentFont">
                          <div className={Styles.price}>
                            <p className={Styles.priceSymbol}>₹</p>
                            <p className={Styles.priceAmt}>{e?.total_amount}</p>
                          </div>
                        </td>
                      </tr>
                    </>
                  ))}
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td
                      style={{
                        display:
                          state.status === 1 ||
                          state?.productDetails?.customerStatus === 1
                            ? "none"
                            : "block",
                      }}
                    ></td>
                    <td className="text-center fw-bold orange">Total</td>
                    <td className={Styles.th + " text-center fw-bold"}>
                      ₹{handleCartTotal(state?.wishlist_products).toFixed(2)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className={Styles.paymentContainer}>
          <p className={Styles.paymentTitle + " fontBold"}>
            Payment <span className="orange">Mode</span>{" "}
          </p>
          <div className={Styles.paymentSec}>
            <input
              value="1"
              className={Styles.paymentRadioBtn + " align-self-center"}
              type="radio"
              onChange={() => setPaymentMode(1)}
              checked={paymentMode === 1}
            />
            <label
              onClick={() => setPaymentMode(1)}
              className={Styles.paymentLabel + " label"}
            >
              UPI
            </label>
          </div>

          <div className={Styles.paymentSec}>
            <input
              value="2"
              className={Styles.paymentRadioBtn + " align-self-center"}
              type="radio"
              onChange={() => setPaymentMode(2)}
              checked={paymentMode === 2}
            />
            <label
              onClick={() => setPaymentMode(2)}
              className={Styles.paymentLabel + " label"}
            >
              CREDIT / DEBIT
            </label>
          </div>
          <div className={Styles.paymentSec}>
            <input
              value="3"
              className={Styles.paymentRadioBtn + " align-self-center"}
              type="radio"
              onChange={() => setPaymentMode(3)}
              checked={paymentMode === 3}
            />
            <label
              onClick={() => setPaymentMode(3)}
              className={Styles.paymentLabel + " label"}
            >
              CASH
            </label>
          </div>
          {paymentError ? <p className="text-danger">Required</p> : null}
        </div>

        <div className={Styles.btnParent}>
          <button onClick={() => navigate(-1)} className={Styles.PreviousBtn}>
            <img className={Styles.nextIcon} src={next} alt="next" />
            Previous
          </button>
          <button onClick={() => handleBill(state)} className={Styles.btn}>
            Submit
          </button>
        </div>

        <form id="upipayment" action={paymentDetils?.paytm_url} method="POST">
          <input
            type="hidden"
            name="ORDER_ID"
            value={paymentDetils?.order_id}
          />
          <input
            type="hidden"
            name="CUST_ID"
            value={paymentDetils?.customer_id}
          />
          <input
            type="hidden"
            name="TXN_AMOUNT"
            value={paymentDetils?.amount}
          />
          <input type="hidden" name="MID" value={paymentDetils?.mid} />
          <input
            type="hidden"
            name="INDUSTRY_TYPE_ID"
            value={paymentDetils?.industry_type}
          />
          <input
            type="hidden"
            name="CHANNEL_ID"
            value={paymentDetils?.challei_id}
          />
          <input type="hidden" name="WEBSITE" value={paymentDetils?.website} />
          <input
            type="hidden"
            name="CALLBACK_URL"
            value={paymentDetils?.callback_url}
          />
          <input
            type="hidden"
            name="CHECKSUMHASH"
            value={paymentDetils?.checksum}
          />
        </form>

        <form id="worldlinepayment" action={paymentDetils?.url} method="post">
          <input
            type="hidden"
            size="200"
            name="merchantRequest"
            id="merchantRequest"
            value={paymentDetils?.merchantRequest}
          />
          <input type="hidden" name="MID" id="MID" value={paymentDetils?.Mid} />
        </form>
      </div>
    </div>
  );
}
