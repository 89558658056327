import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import ReactPaginate from "react-paginate";
import FileSaver from "file-saver";
import moment from "moment";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import Styles from "./ComplaintList.module.css";
import ViewComplaintList from "./ViewComplaintList";
import LeftArrow from "../../../Assets/modal_left_arrow.png";
import RightArrow from "../../../Assets/modal_right_arrow.png";
import { viewComplaintlistService } from "../../../Services/ApiServices";
import view from "../../../Assets/view.svg";
import downloadIcon from "../../../Assets/download_icon.png";
import searchIcon from "../../../Assets/searchFilter.png";
import Loader from "../../../SharedComponents/Loader/Loader";

export default function ComplaintList() {
  const [complaintlist, setComplaintlist] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [viewData, setViewData] = useState(null);
  const [isShowModal, setIsShowModal] = useState(false);
  const [page, setPage] = useState("");
  const [filterData, setFilterData] = useState({
    ticket_no: "",
    fromDate: "",
    toDate: "",
    status: "",
  });
  const [showFilter, setShowFilter] = useState(false);

  useEffect(() => {
    handleComplaintList();
  }, []);

  const handleComplaintList = (pageNum = 1, csv) => {
    setIsLoader(true);
    let formData = new FormData();
    if (filterData.ticket_no !== "") {
      formData.append("ticket_no", filterData.ticket_no);
    }

    if (filterData.fromDate) {
      formData.append(
        "from_date",
        dayjs(filterData.fromDate).format("YYYY-MM-DD 00:00:00")
      );
    }
    if (filterData.toDate) {
      formData.append(
        "to_date",
        dayjs(filterData.toDate).format("YYYY-MM-DD 23:59:59")
      );
    }
    formData.append("complaint_status", filterData.status);
    if (csv !== undefined) {
      formData.append("download", parseInt(csv));
    }
    viewComplaintlistService(pageNum, 10, formData)
      .then((res) => {
        if (csv !== undefined) {
          let url = res.data;
          let urlArr = url.split(".");
          let ext = urlArr[urlArr.length - 1];
          let dateTime =
            moment().format("DD-MM-YYYY") + "," + moment().format("hh:mm:ss a");
          let fileName = dateTime + "." + ext;
          FileSaver.saveAs(url, fileName);
        } else {
          setComplaintlist(res.data);
          setPage(pageNum - 1);
        }
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast.error(err.response.data.detail[0].msg, { type: "error" });
        } else {
          toast.error("Something went wrong !!", { type: "error" });
        }
      })
      .finally(() => setIsLoader(false));
  };

  const handleReset = (pageNumber = 1, csv) => {
    setIsLoader(true);
    setFilterData({
      ticket_no: "",
      fromDate: "",
      toDate: "",
      status: "",
    });
    viewComplaintlistService(parseInt(pageNumber), 10, "")
      .then((res) => {
        if (csv !== undefined) {
          let url = res.data;
          let urlArr = url.split(".");
          let ext = urlArr[urlArr.length - 1];
          let dateTime =
            moment().format("DD-MM-YYYY") + "," + moment().format("hh:mm:ss a");
          let fileName = dateTime + "." + ext;
          FileSaver.saveAs(url, fileName);
        } else {
          setComplaintlist(res.data);
          setPage(pageNumber - 1);
        }
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast(err.response.data.detail[0].msg, { type: "error" });
        } else {
          toast.error("Data Not Found!!");
        }
      })
      .finally(() => setIsLoader(false));
  };

  return (
    <div className={Styles.bg}>
      <Loader isLoader={isLoader} />

      {isShowModal ? (
        <ViewComplaintList
          data={viewData}
          isShow={isShowModal}
          onHide={() => setIsShowModal(false)}
        />
      ) : null}

      <div className="bg-white">
        <div className={Styles.btnContainer}>
          <p className="fontBold">
            Complaint <span className="orange">List</span>
          </p>
          <div>
            <img
              className={Styles.searchIcon}
              src={searchIcon}
              alt="search"
              onClick={() => setShowFilter(!showFilter)}
            />
            <button
              className={Styles.CsvBtn + " kstoreFont"}
              onClick={() => handleComplaintList(1, 1)}
            >
              Export to Csv
              <img
                className={Styles.download}
                src={downloadIcon}
                alt="download"
              />
            </button>
          </div>
        </div>

        {showFilter ? (
          <div className={Styles.filterParentContainer + " row"}>
            <div className={Styles.categories + " col-md-3 col-sm-12"}>
              <label className="label">Ticket No</label>
              <input
                type="text"
                placeholder="Ticket No"
                className={Styles.filterInput + " contentFont"}
                value={filterData.ticket_no}
                onChange={(e) =>
                  setFilterData({
                    ...filterData,
                    ticket_no: e.target.value,
                  })
                }
              />
            </div>
            <div className={Styles.categories + " col-md-3 col-sm-12"}>
              <label className="label">Complaint Status</label>
              <select
                className={Styles.filterInput + " contentFont"}
                value={filterData.status}
                onChange={(e) =>
                  setFilterData({
                    ...filterData,
                    status: e.target.value,
                  })
                }
              >
                <option value="">Select One</option>
                <option value="0">Open</option>
                <option value="1">Completed</option>
                <option value="2">Verified</option>
              </select>
            </div>
            <div className={Styles.categories + " col-md-3 col-sm-12"}>
              <label className="label">From Date</label>
              <DatePicker
                value={filterData.fromDate}
                className={Styles.filterInput + " contentFont"}
                format={"DD/MM/YYYY"}
                onChange={(val) => {
                  setFilterData({
                    ...filterData,
                    fromDate: val,
                  });
                  if (val > filterData.toDate) {
                    setFilterData((pre) => ({ ...pre, toDate: "" }));
                  }
                }}
              />
            </div>
            <div className={Styles.categories + " col-md-3 col-sm-12"}>
              <label className="label">To Date</label>
              <DatePicker
                value={filterData.toDate}
                className={Styles.filterInput + " contentFont"}
                format={"DD/MM/YYYY"}
                disabledDate={(current) => {
                  return current < filterData.fromDate;
                }}
                onChange={(val) => {
                  setFilterData({
                    ...filterData,
                    toDate: val,
                  });
                }}
              />
            </div>

            <div className={Styles.searchBtnContainer + " col-md-12 col-sm-12"}>
              <button
                onClick={() => {
                  handleReset();
                }}
                className={Styles.resetBtn}
              >
                Reset
              </button>
              <button
                onClick={() => {
                  handleComplaintList();
                  setIsLoader(true);
                }}
                className={Styles.searchBtn}
              >
                Search
              </button>
            </div>
          </div>
        ) : null}

        <div className={Styles.tableParentContainer}>
          <div className={Styles.table + " d-flex justify-content-center"}>
            <table>
              <thead>
                <tr>
                  <th className={Styles.cartList + " text-center"}>S.No</th>
                  <th className={Styles.cartList + " text-center"}>
                    Ticket No
                  </th>
                  <th className={Styles.cartList + " text-center"}>
                    No of Products
                  </th>
                  <th className={Styles.cartList + " text-center"}>Message</th>
                  <th className={Styles.cartList + " text-center"}>
                    Complaint Status
                  </th>
                  <th className={Styles.cartList + " text-center"}>Date</th>
                  <th className={Styles.cartList + " text-center"}>Action</th>
                </tr>
              </thead>
              <tbody>
                {complaintlist?.items?.map((e, index) => (
                  <tr key={e?.purchase_product_id} className={Styles.tr}>
                    <td className={Styles.td + " text-center"}>
                      {(complaintlist?.page - 1) * complaintlist?.size +
                        (index + 1)}
                    </td>
                    <td className="text-center contentFont">{e.ticket_no}</td>
                    <td className="text-center contentFont">
                      {e?.no_of_products}
                    </td>
                    <td className="text-center contentFont">{e?.message}</td>
                    <td className="text-center contentFont">
                      {e?.complaints_status}
                    </td>
                    <td className="text-center contentFont">{e?.date}</td>
                    <td className="text-center contentFont">
                      <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip>View</Tooltip>}
                      >
                        <img
                          className={Styles.complaintsImg}
                          src={view}
                          alt="complaint"
                          onClick={() => {
                            setViewData(e);
                            setIsShowModal(true);
                          }}
                        />
                      </OverlayTrigger>
                    </td>
                  </tr>
                ))}
                {complaintlist?.items?.length === 0 ? (
                  <tr>
                    <td
                      colSpan={8}
                      className="fs-6 text-center text-secondary p-3"
                    >
                      Data Not Found
                    </td>
                  </tr>
                ) : null}
              </tbody>
            </table>
          </div>
        </div>

        <div
          style={{ display: complaintlist?.total <= 10 ? "none" : "flex" }}
          className={Styles.cartBtnRightContainer}
        >
          <ReactPaginate
            previousLabel={<img src={LeftArrow} alt="left" />}
            nextLabel={<img src={RightArrow} alt="right" />}
            breakLabel="..."
            pageCount={Math.ceil(complaintlist?.total) / 10}
            marginPagesDisplayed={1}
            pageRangeDisplayed={1}
            onPageChange={({ selected }) => handleComplaintList(selected + 1)}
            forcePage={page}
            containerClassName={"pagination m-0"}
            pageClassName={"page-item"}
            pageLinkClassName={
              "page-link text-secondary rounded-circle bg-light pt-1 pb-1 ps-2 pe-2 ms-1 me-1 border-0 shadow-none"
            }
            previousClassName={"page-item"}
            previousLinkClassName={
              "page-link border-0 text-dark rounded-circle p-1 bg_orange shadow-none me-1"
            }
            nextClassName={"page-item "}
            nextLinkClassName={
              "page-link border-0 text-dark bg_orange p-1 rounded-circle shadow-none ms-1"
            }
            activeClassName={"active"}
          />
        </div>
      </div>
    </div>
  );
}
