import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import Styles from "./ViewDetails.module.css";
import { viewPaymentRequestService } from "../../../Services/ApiServices";
import cancelIcon from "../../../Assets/cross.png";
import success from "../../../Assets/success_img.png";
import waiting from "../../../Assets/waiting_img.png";
import reject from "../../../Assets/reject_img.png";
import noImgFound from "../../../Assets/no_image.svg";
import Loader from "../../../SharedComponents/Loader/Loader";

export default function ViewDetails(props) {
  const [isLoader, setIsLoader] = useState(false);
  const [viewDetailsData, setViewDetailsData] = useState({});

  useEffect(() => {
    handleView();
  }, []);

  const handleView = () => {
    setIsLoader(true);
    viewPaymentRequestService(parseInt(props.id))
      .then((res) => setViewDetailsData(res.data))
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast(err.response.data.detail[0].msg, { type: "error" });
        } else {
          toast("Something went wrong!!");
        }
      })
      .finally(() => setIsLoader(false));
  };

  return (
    <div>
      <Loader isLoader={isLoader} />
      <Modal
        size="lg"
        show={props.isOpen}
        onHide={props.onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className={Styles.cancelImgParentContainer}>
            <div>
              <p className="fontBold">
                View Payment <span className="orange">Details</span>
              </p>
            </div>
            <div className={Styles.cancelImgContainer}>
              <img
                onClick={props.onHide}
                src={cancelIcon}
                alt="cancel"
                className={Styles.cancelImg}
              />
            </div>
          </div>
          <div>
            <div className={Styles.msgIconContainer}>
              <div
                style={{
                  backgroundColor:
                    viewDetailsData?.approve_status === 1 ? "#39B200" : "white",
                }}
                className={Styles.msgIconParentContainer}
              >
                <img
                  style={{
                    width:
                      viewDetailsData?.approve_status === 1 ? "20px" : "40px",
                    height:
                      viewDetailsData?.approve_status === 1 ? "20px" : "40px",
                  }}
                  className={Styles.msgIcon}
                  src={
                    viewDetailsData?.approve_status === 0
                      ? waiting
                      : viewDetailsData?.approve_status === 1
                      ? success
                      : viewDetailsData?.approve_status === 2
                      ? reject
                      : null
                  }
                />
              </div>
            </div>
            <p className={Styles.message}>
              {" "}
              {viewDetailsData?.approve_status === 0
                ? "Your online payment has been processed...Please Wait"
                : viewDetailsData?.approve_status === 1
                ? "Your online payment has been processed successfully"
                : viewDetailsData?.approve_status === 2
                ? "Your online payment has been Rejected"
                : null}
            </p>
            <div>
              <div className={Styles.detailsParentContainer}>
                <p className={Styles.detail}>Payee Name</p>
                <p className={Styles.detail}>{viewDetailsData?.payee_name}</p>
              </div>
              <div className={Styles.detailsParentContainer}>
                <p className={Styles.detail}>Payment Mode</p>
                <p className={Styles.detail}>
                  {viewDetailsData?.payment_mode === 1 ? "IMPS" : "NEFT"}
                </p>
              </div>
              <div className={Styles.detailsParentContainer}>
                <p className={Styles.detail}>Amount</p>
                <p className={Styles.detail}>
                  ₹{viewDetailsData?.amount?.toFixed(2)}
                </p>
              </div>
              <div className={Styles.detailsParentContainer}>
                <p className={Styles.detail}>Bank Reference id</p>
                <p className={Styles.detail}>
                  {viewDetailsData?.bank_reference_id}
                </p>
              </div>
              <div className={Styles.detailsParentContainer}>
                <p className={Styles.detail}>Date</p>
                <p className={Styles.detail}>
                  {viewDetailsData?.requested_datetime
                    ? viewDetailsData?.requested_datetime
                    : "-"}
                </p>
              </div>
              <div className={Styles.detailsParentContainer}>
                <p className={Styles.detail}>Approve or Rejected Date</p>
                <p className={Styles.detail}>
                  {" "}
                  {viewDetailsData?.approved_date_or_rejected_date
                    ? viewDetailsData?.approved_date_or_rejected_date
                    : "-"}
                </p>
              </div>
              <div className={Styles.detailsParentContainer}>
                <p className={Styles.detail}>Status</p>
                <p className={Styles.detail}>
                  {viewDetailsData?.approve_status_type}
                </p>
              </div>
              <div className={Styles.detailsParentContainer}>
                <p className={Styles.detail}>Remarks</p>
                <p className={Styles.detail}>
                  {viewDetailsData?.remarks || "-"}
                </p>
              </div>
            </div>

            <div className={Styles.detailsParentContainer}>
              <p className={Styles.detail}>Proff</p>
              <div className={Styles.ImgContainer}>
                <img
                  style={{
                    width: viewDetailsData?.proof_image ? "400px" : "170px",
                    height: viewDetailsData?.proof_image ? "200px" : "120px",
                  }}
                  src={
                    viewDetailsData?.proof_image
                      ? viewDetailsData?.proof_image
                      : noImgFound
                  }
                />
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
