import { Offcanvas } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import Styles from "./Products.module.css";
import Checkbox from "./CheckBox";
import ProductItem from "./ProductItem";
import CategoryDropdown from "./DropDown";
import {
  dashboardBalanceService,
  productBrandService,
  ProductCategoryService,
  productListService,
} from "../../../Services/ApiServices";
import search from "../../../Assets/search.svg";
import LeftArrow from "../../../Assets/modal_left_arrow.png";
import RightArrow from "../../../Assets/modal_right_arrow.png";
import { TabTitle } from "../../../Utility/TabTitle";
import notFoundImage from "../../../Assets/productNotFound.svg";
import Loader from "../../../SharedComponents/Loader/Loader";
import cart from "../../../Assets/mbl_cart.png";
import {
  HandleCustomerCartCount,
  handleProductCartCount,
} from "../../../Redux/Action";

export default function Products() {
  TabTitle("KSTORE - Products");
  let navigate = useNavigate();
  let { state } = useLocation();
  let { cartCount } = useSelector((state) => state);
  let dispatch = useDispatch();
  const [categoryName, setCategoryName] = useState("");
  const [show, setShow] = useState(false);
  const [productDetails, setProductDetails] = useState(null);
  const [filterDetails, setFilterDetails] = useState([]);
  const [productSearch, setProductSearch] = useState("");
  const [filter, setFilter] = useState("");
  const [brandData, setBrandData] = useState([]);
  const [filterBrandData, setFilterBrandData] = useState([]);
  const [id, setId] = useState(1);
  const [isLoader, setIsLoader] = useState(false);
  const [filterIds, setFilterIds] = useState({ sub_id: "", cat_id: "" });
  const handleClose = () => setShow(false);
  useEffect(() => {
    handleGetCategoryList();
    handleProductCount();
    handleCheckbox();
  }, []);
  useEffect(() => {
    handleGetProduct(filterIds.sub_id, filterIds.cat_id);
  }, [filter]);

  const handleProductCount = () => {
    dashboardBalanceService()
      .then((res) => {
        dispatch(
          HandleCustomerCartCount(parseInt(res.data.customer_cart_count))
        );
        dispatch(handleProductCartCount(parseInt(res.data.cart_count)));
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast.error(err.response.data.detail[0].msg);
        } else {
          toast.error("Something went wrong!!");
        }
      });
  };

  const handleGetCategoryList = () => {
    ProductCategoryService()
      .then((res) => setFilterDetails(res.data.items))
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast.error(err.response.data.detail[0].msg);
        } else {
          toast.error("Something went wrong!!");
        }
      });
  };

  const handleCheckbox = (id = "") => {
    let formData = new FormData();
    formData.append("category_id", id);
    productBrandService(formData)
      .then((res) => {
        setBrandData(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast.error(err.response.data.detail[0].msg);
        } else {
          toast.error("Something went wrong!!");
        }
      });
  };

  const handleGetProduct = (
    sub_category_id = "",
    category_id = "",
    category_name = "",
    page = 1
  ) => {
    setIsLoader(true);
    setFilterIds({ sub_id: sub_category_id, cat_id: category_id });
    if (category_id !== "") {
      handleCheckbox(category_id);
    }
    setCategoryName(category_name);
    let formData = new FormData();
    formData.append("product_name", productSearch);
    formData.append("list_type", state !== null ? parseInt(state) : 2);
    if (category_id && sub_category_id) {
      formData.append("sub_category_id", parseInt(sub_category_id));
      formData.append("category_id", parseInt(category_id));
    } else if (category_id) {
      formData.append("category_id", parseInt(category_id));
    }
    if (filterBrandData.length !== 0) {
      formData.append("brand", "[" + filterBrandData + "]");
    }
    if (filter !== "") {
      let splitData = filter.split("-");
      if (splitData[1] === "1") {
        formData.append("order_by", "product_name");
        formData.append("order", splitData[0]);
      }
      if (splitData[1] === "2") {
        formData.append("order_by", "selling_price");
        formData.append("order", splitData[0]);
      }
    }
    productListService(formData, page, 12)
      .then((res) => {
        setProductDetails(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast.error(err.response.data.detail[0].msg, { type: "error" });
        } else {
          toast.error("Something went wrong!!", { type: "error" });
        }
      })
      .finally(() => setIsLoader(false));
  };

  const hanldeBrandData = (brandID) => {
    if (filterBrandData.includes(brandID)) {
      setFilterBrandData(filterBrandData.filter((e) => e !== brandID));
    } else {
      setFilterBrandData([...filterBrandData, brandID]);
    }
  };

  const handleAllproductId = (val) => {
    setId(0);
  };

  return (
    <div className={Styles.overallContainer}>
      <Loader isLoader={isLoader} />
      <div className={Styles.mblFilter}>
        <Offcanvas placement="bottom h-75" show={show} onHide={handleClose}>
          <Offcanvas.Header className={Styles.filterHeading}>
            <Offcanvas.Title className="w-100">
              <div className={Styles.searchChildContainer}>
                <input
                  className={Styles.searchInput + " label"}
                  type="text"
                  value={productSearch}
                  placeholder="Search products..."
                  onChange={(e) => setProductSearch(e.target.value)}
                />
                <div className="align-self-center">
                  <img
                    className={Styles.searchImg}
                    onClick={() => {
                      handleGetProduct(filterIds.sub_id, filterIds.cat_id);
                      handleClose();
                    }}
                    src={search}
                    alt="search"
                  />
                </div>
              </div>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className={Styles.tabLeftContainer}>
              <div>
                <div className="d-flex justify-content-between">
                  <h4 className="fontBold">Categories</h4>
                </div>
              </div>
              <div className="d-flex">
                <hr className={Styles.hr1} />
                <hr className={Styles.hr2} />
              </div>

              <div className={Styles.overall}>
                {filterDetails?.map((e) => (
                  <CategoryDropdown
                    key={e.category_id}
                    data={e}
                    // handleselectedData={handleselectedData}
                    categoryID={filterIds}
                    handleGetProduct={(sub_id, cat_id, cat_name) => {
                      setFilter("");
                      setProductSearch("");
                      handleGetProduct(sub_id, cat_id, cat_name);
                    }}
                  />
                ))}
              </div>
              <div
                style={{
                  display: brandData.length === 0 ? "none" : null,
                }}
              >
                <div className="d-flex justify-content-between mt-4">
                  <h4 className="fontBold">Brand</h4>
                  <span
                    onClick={() => {
                      setFilter("");
                      setProductSearch("");
                      handleGetProduct(filterIds.sub_id, filterIds.cat_id);
                      handleClose();
                    }}
                    className={Styles.applyBtn}
                  >
                    Apply
                  </span>
                </div>
              </div>
              <div className="d-flex">
                <hr
                  style={{
                    display: brandData.length === 0 ? "none" : null,
                  }}
                  className={Styles.hr1}
                />
                <hr
                  style={{
                    display: brandData.length === 0 ? "none" : null,
                  }}
                  className={Styles.hr2}
                />
              </div>
              <div>
                <Checkbox
                  hanldeBrandData={hanldeBrandData}
                  checkboxData={brandData}
                  filterData={filterBrandData}
                />
              </div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
      <div className={Styles.leftContainer}>
        <div>
          <div className="d-flex justify-content-between">
            <h4 className="fontBold">Categories</h4>
          </div>
        </div>
        <div className="d-flex">
          <hr className={Styles.hr1 + " m-0"} />
          <hr className={Styles.hr2 + " m-0"} />
        </div>
        <div className={Styles.overall}>
          <p
            className={Styles.heading + " dropdownHeading"}
            onClick={() => {
              handleGetProduct();
              setId(1);
            }}
            style={{
              color: parseInt(id) === 1 ? "#FF7C25" : "black",
            }}
          >
            All Produts
          </p>
          {filterDetails?.map((e) => (
            <CategoryDropdown
              key={e.category_id}
              data={e}
              handleAllproductId={handleAllproductId}
              // handleselectedData={handleselectedData}
              categoryID={filterIds}
              handleGetProduct={(sub_id, cat_id, cat_name) => {
                setFilter("");
                setProductSearch("");
                handleGetProduct(sub_id, cat_id, cat_name);
              }}
            />
          ))}
        </div>
        <div>
          <div className="d-flex justify-content-between mt-4">
            <h4 className="fontBold">Brand</h4>
            <span
              onClick={() => {
                setFilter("");
                setProductSearch("");
                handleGetProduct(filterIds.sub_id, filterIds.cat_id);
              }}
              className={Styles.applyBtn}
            >
              Apply
            </span>
          </div>
        </div>
        <div className="d-flex">
          <hr className={Styles.hr1 + " m-0"} />
          <hr className={Styles.hr2 + " m-0"} />
        </div>
        <div>
          <Checkbox
            hanldeBrandData={hanldeBrandData}
            checkboxData={brandData}
            filterData={filterBrandData}
          />
        </div>
      </div>
      <div
        style={{ height: productDetails?.length === 0 ? "100vh" : "unset" }}
        className={Styles.rightContainer}
      >
        <div className={Styles.rightFilterContainer}>
          <div className={Styles.searchChildContainer}>
            <input
              className={Styles.searchInput + " kstoreFont"}
              type="text"
              value={productSearch}
              placeholder="Search products..."
              onChange={(e) => setProductSearch(e.target.value)}
            />
            <div className="align-self-center">
              <img
                className={Styles.searchImg}
                onClick={() => {
                  handleGetProduct(filterIds.sub_id, filterIds.cat_id);
                }}
                src={search}
                alt="search"
              />
            </div>
          </div>
          <div className={Styles.mblFilterContainer}>
            <div className={Styles.filter} onClick={() => setShow(true)}>
              <span
                className={Styles.filterText + " contentFont"}
                onClick={() => setShow(true)}
              >
                Filter
              </span>
            </div>
            <div className={Styles.sortText}>
              <span className={Styles.sort + " align-self-center kstoreFont2"}>
                Sort By
              </span>
              <div className={Styles.selectContainer}>
                <select
                  value={filter}
                  onChange={(e) => setFilter(e.target.value)}
                  className={Styles.empty + " pt-3 kstoreFont2"}
                >
                  <option value="">select</option>
                  <option value="asc-1">Name (A to Z)</option>
                  <option value="desc-1">Name (Z to A)</option>
                  <option value="asc-2">Price (High to Low)</option>
                  <option value="desc-2">Price (Low to High)</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        {productDetails && productDetails?.length === 0 ? (
          <div className={Styles.notFoundImgContainer}>
            <img
              className={Styles.notFoundImg}
              src={notFoundImage}
              alt="notfound"
            />
          </div>
        ) : (
          <div className={Styles.mblProducts + " row"}>
            {productDetails?.items?.map((e) => (
              <ProductItem
                categoryname={categoryName}
                data={e}
                key={e.product_id}
                productType={state !== null ? parseInt(state) : 2}
              />
            ))}
          </div>
        )}

        {cartCount !== 0 ? (
          <div
            className={Styles.mblcart}
            onClick={() => navigate("/store/cartlist")}
          >
            <img className={Styles.cartImg} src={cart} alt="cart" />
            <div className={Styles.notification}>
              <span className={Styles.badge}>{cartCount}</span>
            </div>
          </div>
        ) : null}

        <div
          style={{ display: productDetails?.total <= 10 ? "none" : "flex" }}
          className={Styles.paginationContainer}
        >
          <ReactPaginate
            previousLabel={<img src={LeftArrow} alt="left" />}
            nextLabel={<img src={RightArrow} alt="right" />}
            breakLabel="..."
            pageCount={Math.ceil(productDetails?.total) / 10}
            marginPagesDisplayed={1}
            pageRangeDisplayed={1}
            onPageChange={({ selected }) =>
              handleGetProduct(
                filterIds?.sub_id,
                filterIds?.cat_id,
                "",
                selected + 1
              )
            }
            containerClassName={
              "pagination m-0 d-flex justify-content-end me-4 bg-secondory"
            }
            pageClassName={"page-item"}
            pageLinkClassName={
              "page-link text-secondary rounded-circle bg-light pt-1 pb-1 ps-2 pe-2 ms-1 me-1 border-0 shadow-none"
            }
            forcePage={""}
            previousClassName={"page-item"}
            previousLinkClassName={
              "page-link border-0 text-dark p-1 rounded-circle bg_orange shadow-none me-1"
            }
            nextClassName={"page-item "}
            nextLinkClassName={
              "page-link border-0 text-dark p-1 bg_orange rounded-circle shadow-none ms-1"
            }
            activeClassName={"active"}
          />
        </div>
      </div>
    </div>
  );
}
