import toast from "react-hot-toast";
import { Modal } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import { useEffect, useState } from "react";
import Styles from "./Testimonials.module.css";
import { testimonialsService } from "../../Services/ApiServices";

export default function Testmonials() {
  const [testimonialsDetails, setTestimonialsDetails] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [url, setUrl] = useState("");
  useEffect(() => {
    handleTestimonials();
  }, []);

  const handleTestimonials = () => {
    testimonialsService()
      .then((res) => {
        setTestimonialsDetails(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast.error(err.response.data.detail[0].msg, { type: "error" });
        } else {
          toast.error("Something went wrong!!");
        }
      });
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <>
      <div id="testimonial" className="mt-5">
        <div className="d-flex justify-content-center">
          <h1 className="text-white kstoreFont">
            Client <span className="orange">Testimonials</span>
          </h1>
        </div>
        <Carousel
          autoPlay
          autoPlaySpeed={1000}
          arrows={false}
          infinite={true}
          responsive={responsive}
          className="container"
        >
          {testimonialsDetails.map((e, index) => (
            <div key={index} className={Styles.imgContainer}>
              <img
                onClick={() => {
                  setUrl(e);
                  setShowModal(true);
                }}
                className={Styles.img}
                src={e.image}
                alt="img"
              />
              <h5 className="mt-3">{e.title}</h5>
              <p>{e.description}</p>
            </div>
          ))}
        </Carousel>
        ;
      </div>
      <Modal
        centered
        size="lg"
        show={showModal}
        onHide={() => setShowModal(false)}
      >
        <Modal.Body className="d-flex justify-content-center">
          {url.video_link !== "" ? (
            <iframe className={Styles.video} src={url.video_link} />
          ) : (
            <img className={Styles.urlimg} src={url.image} />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
