import React from "react";
import Styles from "./ProductsAttributesContainer.module.css";

export default function ProductsAttributesContainer({
  productsAttributes,
  onPressSeachButton,
  selectedProductsAttributes = [],
  handleProductAttributeChecked,
}) {
  const isSelectedItem = (id) => {
    let isSelected = [...selectedProductsAttributes].find(
      (itemId) => itemId === id
    );
    if (isSelected) {
      return true;
    }
    return false;
  };

  return (
    <div className={Styles.attributesContainer}>
      {productsAttributes.map((item, index) => {
        return (
          <div key={index}>
            <div className={Styles.headingTextContainer}>
              <div className="d-flex justify-content-between">
                <h4 className="fontBold">{item.attribute}</h4>
              </div>
            </div>
            <div className="d-flex">
              <hr className={Styles.hr1 + " m-0"} />
              <hr className={Styles.hr2 + " m-0"} />
            </div>

            <div className={Styles.checkboxContainer}>
              {item.product_attribute_values.map((subItem, subIndex) => {
                return (
                  <div
                    key={subIndex}
                    className={Styles.checkbox + " form-check mt-3"}
                  >
                    <input
                      className={Styles.input + " form-check-input"}
                      type="checkbox"
                      id={subItem.product_attribute_value_id}
                      checked={isSelectedItem(
                        subItem.product_attribute_value_id
                      )}
                      onChange={(e) => {
                        handleProductAttributeChecked(
                          subItem.product_attribute_value_id,
                          e.target.checked
                        );
                      }}
                    />

                    <label
                      className="form-check-label dropdownHeading"
                      htmlFor={subItem.product_attribute_value_id}
                    >
                      {subItem.value_name}
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
      <button className={Styles.searchBtn} onClick={onPressSeachButton}>
        Filter
      </button>
    </div>
  );
}
