import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import moment from "moment";
import toast from "react-hot-toast";
import FileSaver from "file-saver";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import Styles from "./BillList.module.css";
import PaymentView from "./PaymentView";
import ViewBillList from "./ViewBillList";
import {
  billListService,
  billPdfService,
  cancelBillService,
} from "../../../Services/ApiServices";
import LeftArrow from "../../../Assets/modal_left_arrow.png";
import RightArrow from "../../../Assets/modal_right_arrow.png";
import deleteIcon from "../../../Assets/delete.svg";
import viewIcon from "../../../Assets/view.svg";
import Loader from "../../../SharedComponents/Loader/Loader";
import downloadIcon from "../../../Assets/download_icon.png";
import download from "../../../Assets/download.png";
import searchIcon from "../../../Assets/searchFilter.png";
import PaymentBillIcon from "../../../Assets/payment_billlist.png";
import paymentSuccess from "../../../Assets/paymentSuccess.png";
import paymentWaiting from "../../../Assets/paymentWaiting.png";
import paymentCancel from "../../../Assets/paymentCancel.png";

export default function BillList() {
  let navigate = useNavigate();
  let { search } = useLocation();
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [viewId, setViewId] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [isPaymentView, setIsPaymentView] = useState(false);
  const [showPaymentStatus, setShowPaymentStatus] = useState(false);
  const [paymentBillID, setPaymentBillID] = useState("");
  const [filterData, setFilterData] = useState({
    customerName: "",
    fromDate: "",
    toDate: "",
    MobileNo: "",
    productName: "",
  });

  const [paymentStatusDetails, setPaymentStatusDetails] = useState({
    paymentMsg: "",
    paymentStatus: "",
  });

  const [paymentDelete, setPaymentDelete] = useState({
    id: null,
    status: false,
  });

  useEffect(() => {
    if (search !== "") {
      // showPaymentStatus(true);
      let data = search;
      let urlToString = data.replace(/%20/g, " ").split("&");
      let msg = urlToString[0].split("=")[1].replace(".", "");
      let status = urlToString[1].split("=")[1];
      setPaymentStatusDetails({ paymentMsg: msg, paymentStatus: status });
      setShowPaymentStatus(true);
    }
  }, []);

  useEffect(() => {
    handleListBill();
  }, []);

  const handleListBill = (pageNum = 1, csv) => {
    setIsLoader(true);
    let formData = new FormData();

    formData.append("customer_name", filterData.customerName);

    if (filterData.MobileNo !== "") {
      formData.append("mobile_no", filterData.MobileNo);
    }
    if (filterData.productName !== "") {
      formData.append("bill_no", filterData.productName);
    }

    if (filterData.fromDate) {
      formData.append(
        "from_date",
        dayjs(filterData.fromDate).format("YYYY-MM-DD")
      );
    }
    if (filterData.toDate) {
      formData.append("to_date", dayjs(filterData.toDate).format("YYYY-MM-DD"));
    }
    if (csv !== undefined) {
      formData.append("download", parseInt(csv));
    }
    billListService(formData, pageNum, 10)
      .then((res) => {
        if (csv !== undefined) {
          let url = res.data;
          let urlArr = url.split(".");
          let ext = urlArr[urlArr.length - 1];
          let dateTime =
            moment().format("DD-MM-YYYY") + "," + moment().format("hh:mm:ss a");
          let fileName = dateTime + "." + ext;
          FileSaver.saveAs(url, fileName);
        } else {
          setTableData(res.data);
          setPage(pageNum - 1);
        }
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast.error(err.response.data.detail[0].msg, { type: "error" });
        } else {
          toast.error("Data Not Found !!", { type: "error" });
        }
      })
      .finally(() => setIsLoader(false));
  };

  const handleDownload = (id) => {
    setIsLoader(true);
    billPdfService(parseInt(id))
      .then((res) => {
        let url = res.data.billing_url;
        let urlArr = url.split(".");
        let ext = urlArr[urlArr.length - 1];
        let dateTime =
          moment().format("DD-MM-YYYY") + "," + moment().format("hh:mm:ss a");
        let fileName = dateTime + "." + ext;
        FileSaver.saveAs(url, fileName);
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast.error(err.response.data.detail[0].msg);
        } else {
          toast.error("Something went wrong test!!");
        }
      })
      .finally(() => setIsLoader(false));
  };

  const handleReset = (pageNum = 1, csv) => {
    setIsLoader(true);
    setFilterData({
      customerName: "",
      fromDate: "",
      toDate: "",
      MobileNo: "",
      productName: "",
    });
    billListService("", pageNum, 10)
      .then((res) => {
        if (csv !== undefined) {
          let url = res.data;
          let urlArr = url.split(".");
          let ext = urlArr[urlArr.length - 1];
          let dateTime =
            moment().format("DD-MM-YYYY") + "," + moment().format("hh:mm:ss a");
          let fileName = dateTime + "." + ext;
          FileSaver.saveAs(url, fileName);
        } else {
          setTableData(res.data);
          setPage(pageNum - 1);
        }
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast.error(err.response.data.detail[0].msg, { type: "error" });
        } else {
          toast.error("Data Not Found !!", { type: "error" });
        }
      })
      .finally(() => setIsLoader(false));
  };

  const handleDelete = (id) => {
    setIsLoader(true);
    let formData = new FormData();
    formData.append("bill_id", id);
    cancelBillService(formData)
      .then((res) => handleListBill())
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast.error(err.response.data.detail[0].msg);
        } else {
          toast.error("Something went wrong!!");
        }
      })
      .finally(() => setIsLoader(false));
  };

  return (
    <div className={Styles.parentContainer + " container-fluid"}>
      <Loader isLoader={isLoader} />
      {showModal ? (
        <ViewBillList
          isShow={showModal}
          id={viewId}
          onHide={() => setShowModal(false)}
        />
      ) : null}

      {isPaymentView ? (
        <PaymentView
          onShow={isPaymentView}
          onHide={() => setIsPaymentView(false)}
          id={paymentBillID}
          handleListBill={handleListBill}
          tableData={tableData}
        />
      ) : null}

      {showPaymentStatus ? (
        <Modal
          centered
          size="md"
          show={showPaymentStatus}
          onHide={() => setShowPaymentStatus(false)}
        >
          <Modal.Body>
            <div>
              <div className="text-center">
                <img
                  src={
                    parseInt(paymentStatusDetails.paymentStatus) === 1
                      ? paymentSuccess
                      : parseInt(paymentStatusDetails.paymentStatus) === 0
                      ? paymentWaiting
                      : parseInt(paymentStatusDetails.paymentStatus) === 2
                      ? paymentCancel
                      : null
                  }
                  alt="success"
                />
              </div>
              <p className={Styles.paymentMsg}>
                {paymentStatusDetails.paymentMsg}
              </p>
            </div>
          </Modal.Body>
        </Modal>
      ) : null}
      <Modal
        size="md"
        centered
        show={paymentDelete.status}
        onHide={() => setPaymentDelete({ id: null, status: false })}
      >
        <Modal.Body>
          <div>
            <p className="text-start kstoreFont text-secondary fs-6 fw-bold">
              Are you sure want to delete?
            </p>
            <div className={Styles.deleteConfirmationBtnContainer}>
              <div className={Styles.btnParent}>
                <button
                  className={
                    Styles.deleteConfirmationCancelBtn +
                    " contentFont text-uppercase"
                  }
                  onClick={() => setPaymentDelete({ id: null, status: false })}
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    handleDelete(paymentDelete.id);
                    setPaymentDelete({ id: null, status: false });
                  }}
                  className={
                    Styles.deleteConfirmationOkBtn +
                    " contentFont text-uppercase"
                  }
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <div className={Styles.childContainerParent}>
        <div className={Styles.childContainer}>
          <div className={Styles.searchParentContainer}>
            <p className="fontBold">
              In<span className="orange">voice</span>
            </p>
            <div className={Styles.newBillBtnContainer + " align-self-center"}>
              <img
                className={Styles.searchIcon}
                src={searchIcon}
                alt="search"
                onClick={() => setShowFilter(!showFilter)}
              />
              <button
                className={Styles.CsvBtn + " kstoreFont"}
                onClick={() => handleListBill(1, 1)}
              >
                Export to Csv
                <img
                  className={Styles.download}
                  src={downloadIcon}
                  alt="download"
                />
              </button>
              <button
                className={Styles.newBillBtn + " kstoreFont"}
                onClick={() => navigate("/store/categories", { state: 1 })}
              >
                New Invoice
              </button>
            </div>
          </div>
          {showFilter ? (
            <div className={Styles.filterParentContainer + " row"}>
              <div className={Styles.categories + " col-md-4 col-sm-12"}>
                <label className="label">Customer Name</label>
                <input
                  type="text"
                  placeholder="Customer Name"
                  className={Styles.filterInput + " contentFont"}
                  value={filterData.customerName}
                  onChange={(e) =>
                    setFilterData({
                      ...filterData,
                      customerName: e.target.value,
                    })
                  }
                />
              </div>
              <div className={Styles.categories + " col-md-4 col-sm-12"}>
                <label className="label">Mobile No</label>
                <input
                  type="text"
                  placeholder="Mobile Number"
                  className={Styles.filterInput + " contentFont"}
                  value={filterData.MobileNo}
                  maxLength="10"
                  onChange={(e) =>
                    setFilterData({ ...filterData, MobileNo: e.target.value })
                  }
                  onKeyPress={(e) => {
                    if (!(e.key === "0" || parseInt(e.key))) {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
              <div className={Styles.categories + " col-md-4 col-sm-12"}>
                <label className="label">Invoice No</label>
                <input
                  type="text"
                  placeholder="Invoice no"
                  className={Styles.filterInput + " contentFont"}
                  value={filterData.productName}
                  onChange={(e) =>
                    setFilterData({
                      ...filterData,
                      productName: e.target.value,
                    })
                  }
                />
              </div>
              <div className={Styles.categories + " col-md-4 col-sm-12"}>
                <label className="label">From Date</label>
                <DatePicker
                  value={filterData.fromDate}
                  className={Styles.filterInput + " contentFont"}
                  format={"DD/MM/YYYY"}
                  onChange={(val) => {
                    setFilterData({
                      ...filterData,
                      fromDate: val,
                    });
                    if (val > filterData.toDate) {
                      setFilterData((pre) => ({ ...pre, toDate: "" }));
                    }
                  }}
                />
              </div>
              <div className={Styles.categories + " col-md-4 col-sm-12"}>
                <label className="label">To Date</label>
                <DatePicker
                  value={filterData.toDate}
                  className={Styles.filterInput + " contentFont"}
                  format={"DD/MM/YYYY"}
                  disabledDate={(current) => {
                    return current < filterData.fromDate;
                  }}
                  onChange={(val) => {
                    setFilterData({
                      ...filterData,
                      toDate: val,
                    });
                  }}
                />
              </div>

              <div
                className={Styles.searchBtnContainer + " col-md-4 col-sm-12"}
              >
                <button
                  onClick={() => {
                    handleReset();
                  }}
                  className={Styles.resetBtn}
                >
                  Reset
                </button>
                <button
                  onClick={() => {
                    handleListBill();
                    setIsLoader(true);
                  }}
                  className={Styles.searchBtn}
                >
                  Search
                </button>
              </div>
            </div>
          ) : null}
          <div className={Styles.tableParentContainer}>
            <div className={Styles.TableChildContainer}>
              <table>
                <thead>
                  <tr>
                    <th className={Styles.tableHead + " text-center "}>S.NO</th>
                    <th className={Styles.productNameTh + " text-center "}>
                      Date
                    </th>
                    <th className={Styles.tableHead + " text-center "}>
                      Invoice No
                    </th>
                    <th className={Styles.tableHead + " text-start "}>
                      Customer Name
                    </th>
                    <th className={Styles.tableHead + " text-center "}>
                      Mobile No
                    </th>
                    <th className={Styles.tableHead + " text-center "}>
                      Payment Type
                    </th>
                    <th className={Styles.tableHead + " text-center "}>
                      Bill Status
                    </th>
                    <th className={Styles.tableHead + " text-center "}>
                      Total Amount
                    </th>
                    <th className={Styles.actionTableHead + " text-center "}>
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {tableData?.items !== undefined &&
                    tableData?.items.length > 0 &&
                    tableData?.items?.map((e, index) => (
                      <tr key={e.product_id}>
                        <td className={Styles.th + " contentFont text-center"}>
                          {(tableData?.page - 1) * tableData?.size +
                            (index + 1)}
                        </td>
                        <td
                          className={
                            Styles.productName + " contentFont text-center"
                          }
                        >
                          {e?.billed_date ? e?.billed_date : "-"}
                        </td>
                        <td className="contentFont text-center">
                          {e?.bill_no ? e?.bill_no : "-"}
                        </td>
                        <td className="contentFont text-start">
                          {e?.customer_name}
                        </td>
                        <td className="contentFont text-center">
                          {e?.mobile_no}
                        </td>
                        <td className="contentFont text-center">
                          {e?.payment_type}
                        </td>
                        <td className="contentFont text-center">
                          {e?.bill_status_type}
                        </td>
                        <td
                          className={Styles.price + " contentFont text-center"}
                        >
                          <p className={Styles.priceSymbol}>₹</p>
                          <p className={Styles.priceAmt}>
                            {e?.price.toFixed(2)}
                          </p>
                        </td>
                        <td className={Styles.actionContainer}>
                          <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip>View</Tooltip>}
                          >
                            <img
                              src={viewIcon}
                              alt="view"
                              className={Styles.viewImg}
                              onClick={() => {
                                setShowModal(true);
                                setViewId(e?.bill_id);
                              }}
                            />
                          </OverlayTrigger>
                          <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip>Download</Tooltip>}
                          >
                            <img
                              style={{
                                display:
                                  e?.bill_complete_status !== 1 ? "none" : null,
                              }}
                              src={download}
                              alt="download"
                              className={Styles.viewImgDownload}
                              onClick={() => handleDownload(e?.bill_id)}
                            />
                          </OverlayTrigger>
                          <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip>Repayment</Tooltip>}
                          >
                            <img
                              style={{
                                display: e?.failed_status !== 1 ? "none" : null,
                              }}
                              src={PaymentBillIcon}
                              alt="payment"
                              className={Styles.viewImgDownload}
                              onClick={() => {
                                setIsPaymentView(true);
                                setPaymentBillID(e?.bill_id);
                              }}
                            />
                          </OverlayTrigger>
                          <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip>Delete</Tooltip>}
                          >
                            <img
                              style={{
                                display: e?.is_deleteable !== 1 ? "none" : null,
                              }}
                              src={deleteIcon}
                              alt="delete"
                              className={Styles.viewImgDownload}
                              onClick={() => {
                                setPaymentDelete({
                                  id: e?.bill_id,
                                  status: true,
                                });
                              }}
                            />
                          </OverlayTrigger>
                        </td>
                      </tr>
                    ))}

                  {tableData?.items?.length === 0 ? (
                    <tr>
                      <td
                        colSpan={7}
                        className="fs-6 text-secondary text-center p-3"
                      >
                        Data Not Found
                      </td>
                    </tr>
                  ) : null}
                </tbody>
              </table>
            </div>
          </div>

          <div
            style={{ display: tableData?.total <= 10 ? "none" : "block" }}
            className={Styles.paginationContainer}
          >
            <ReactPaginate
              previousLabel={<img src={LeftArrow} alt="left" />}
              nextLabel={<img src={RightArrow} alt="right" />}
              breakLabel="..."
              pageCount={Math.ceil(tableData.total) / 10}
              marginPagesDisplayed={1}
              pageRangeDisplayed={1}
              onPageChange={({ selected }) => handleListBill(selected + 1)}
              containerClassName={
                "pagination m-0 d-flex justify-content-end me-4 bg-secondory"
              }
              pageClassName={"page-item"}
              pageLinkClassName={
                "page-link text-secondary rounded-circle bg-light pt-1 pb-1 ps-2 pe-2 ms-1 me-1 border-0 shadow-none"
              }
              forcePage={page}
              previousClassName={"page-item"}
              previousLinkClassName={
                "page-link border-0 text-dark p-1 rounded-circle bg_orange shadow-none me-1"
              }
              nextClassName={"page-item "}
              nextLinkClassName={
                "page-link border-0 text-dark p-1 bg_orange rounded-circle shadow-none ms-1"
              }
              activeClassName={"active"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
