import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import FileSaver from "file-saver";
import Styles from "./AddtoCart.module.css";
import { TabTitle } from "../../../Utility/TabTitle";
import del from "../../../Assets/delete.svg";
import viewIcon from "../../../Assets/view.svg";
import {
  deleteCustomerWishlistService,
  listCustomerWishlistService,
  viewCustomerWishlistService,
} from "../../../Services/ApiServices";
import LeftArrow from "../../../Assets/modal_left_arrow.png";
import RightArrow from "../../../Assets/modal_right_arrow.png";
import { HandleCustomerCartCount } from "../../../Redux/Action";
import searchIcon from "../../../Assets/searchFilter.png";
import ViewCustomerWishlist from "./ViewCustomerWishlist";
import downloadIcon from "../../../Assets/download_icon.png";

export default function AddToCart() {
  TabTitle("KSTORE - Add-to-cart");
  let navigate = useNavigate();
  let dispatch = useDispatch();
  let count = useSelector((state) => state.count);
  const [cartData, setCartData] = useState([]);
  const [viewId, setViewId] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [page, setPage] = useState(0);
  const [isWishlistView, setIsWishListView] = useState(false);
  const [filterData, setFilterData] = useState({
    customerName: "",
    fromDate: "",
    toDate: "",
    MobileNo: "",
    productName: "",
  });
  const [isConfirmation, setIsConfirmation] = useState({
    status: false,
    id: "",
  });

  useEffect(() => {
    handleCart();
  }, []);

  const handleCart = (pageNumber = 1, csv) => {
    setIsLoader(true);
    let formData = new FormData();
    formData.append("cart_type", 1);
    if (filterData.customerName !== "") {
      formData.append("customer_name", filterData.customerName);
    }
    if (filterData.MobileNo !== "") {
      formData.append("mobile_no", filterData.MobileNo);
    }
    if (filterData.customerName !== "") {
      formData.append("product_name", filterData.productName);
    }
    if (filterData.fromDate) {
      formData.append(
        "from_date",
        dayjs(filterData.fromDate).format("YYYY-MM-DD")
      );
    }
    if (filterData.toDate) {
      formData.append("to_date", dayjs(filterData.toDate).format("YYYY-MM-DD"));
    }
    if (csv !== undefined) {
      formData.append("download", parseInt(csv));
    }
    listCustomerWishlistService(formData, pageNumber, 10)
      .then((res) => {
        if (csv !== undefined) {
          let url = res.data;
          let urlArr = url.split(".");
          let ext = urlArr[urlArr.length - 1];
          let dateTime =
            moment().format("DD-MM-YYYY") + "," + moment().format("hh:mm:ss a");
          let fileName = dateTime + "." + ext;
          FileSaver.saveAs(url, fileName);
        } else {
          setCartData(res.data);
          setPage(pageNumber - 1);
        }
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast(err.response.data.detail[0].msg, { type: "error" });
        } else {
          toast("Something went wrong!!", { type: "error" });
        }
      })
      .finally(() => setIsLoader(false));
  };

  const handleClearCart = (id) => {
    let formData = new FormData();
    formData.append("customer_wishlist_id", id);
    deleteCustomerWishlistService(formData)
      .then((res) => {
        toast(res.data, { type: "success" });
        let filterCartList = cartData.items.filter(
          (e) => e.customer_wishlist_id !== id
        );
        setCartData({ ...cartData, items: filterCartList });
        dispatch(HandleCustomerCartCount(count - 1));
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast(err.response.data.detail[0].msg, { type: "error" });
        } else {
          toast("Something went wrong!!", { type: "error" });
        }
      });
  };

  const handleBillData = (id) => {
    viewCustomerWishlistService(id)
      .then((res) => handleViewBill(res.data))
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast.error(err.response.data.detail[0].msg, { type: "error" });
        } else {
          toast.error("Something went wrong!!", { type: "error" });
        }
      });
  };

  const handleViewBill = (viewData) => {
    navigate("/store/categories", {
      state: { ...viewData, fromCustomerWishlist: true },
    });
  };

  return (
    <div className={Styles.bg}>
      {isWishlistView ? (
        <ViewCustomerWishlist
          state={isWishlistView}
          onHide={() => setIsWishListView(false)}
          id={viewId}
        />
      ) : null}

      {isLoader ? (
        <div className={Styles.loaderParent}>
          <div className={Styles.loader}></div>
        </div>
      ) : null}
      <div className="bg-white">
        <div className={Styles.titleContainer}>
          <p className={Styles.title + " fontBold"}>
            Customer Viewed <span className="orange">List</span>{" "}
          </p>
          <div>
            <img
              className={Styles.searchIcon}
              src={searchIcon}
              alt="search"
              onClick={() => setShowFilter(!showFilter)}
            />
            <button
              className={Styles.CsvBtn + " kstoreFont"}
              onClick={() => handleCart(1, 1)}
            >
              Export to Csv
              <img
                className={Styles.download}
                src={downloadIcon}
                alt="download"
              />
            </button>
          </div>
        </div>
        {showFilter ? (
          <div className={Styles.filterParentContainer + " row"}>
            <div className={Styles.categories + " col-md-3 col-sm-12"}>
              <label className="label">Customer Name</label>
              <input
                type="text"
                placeholder="Customer Name"
                className={Styles.filterInput + " contentFont"}
                value={filterData.customerName}
                onChange={(e) =>
                  setFilterData({ ...filterData, customerName: e.target.value })
                }
              />
            </div>
            <div className={Styles.categories + " col-md-3 col-sm-12"}>
              <label className="label">Mobile No</label>
              <input
                type="text"
                placeholder="Mobile Number"
                className={Styles.filterInput + " contentFont"}
                value={filterData.MobileNo}
                maxLength="10"
                onChange={(e) =>
                  setFilterData({ ...filterData, MobileNo: e.target.value })
                }
                onKeyPress={(e) => {
                  if (e.key === "0" || parseInt(e.key)) {
                  } else {
                    e.preventDefault();
                  }
                }}
              />
            </div>
            <div className={Styles.categories + " col-md-3 col-sm-12"}>
              <label className="label">From Date</label>
              <DatePicker
                value={filterData.fromDate}
                className={Styles.filterInput + " contentFont"}
                format={"DD/MM/YYYY"}
                onChange={(val) => {
                  setFilterData({
                    ...filterData,
                    fromDate: val,
                  });
                  if (val > filterData.toDate) {
                    setFilterData((pre) => ({ ...pre, toDate: "" }));
                  }
                }}
              />
            </div>
            <div className={Styles.categories + " col-md-3 col-sm-12"}>
              <label className="label">To Date</label>
              <DatePicker
                value={filterData.toDate}
                className={Styles.filterInput + " contentFont"}
                format={"DD/MM/YYYY"}
                disabledDate={(current) => {
                  return current < filterData.fromDate;
                }}
                onChange={(val) => {
                  setFilterData({
                    ...filterData,
                    toDate: val,
                  });
                }}
              />
            </div>

            <div className=" d-flex justify-content-md-end justify-content-sm-between col-md-12 col-sm-12 align-self-end mb-3">
              <button
                onClick={() =>
                  setFilterData({
                    customerName: "",
                    fromDate: "",
                    toDate: "",
                    MobileNo: "",
                    productName: "",
                  })
                }
                className={Styles.resetBtn + " me-2"}
              >
                reset
              </button>
              <button onClick={() => handleCart()} className={Styles.searchBtn}>
                Search
              </button>
            </div>
          </div>
        ) : null}
        <div className={Styles.tableParentContainer}>
          <div className={Styles.table + " d-flex justify-content-center"}>
            <table>
              <thead>
                <tr className={Styles.heading}>
                  <th className={Styles.th + " text-center"}>S.No</th>
                  <th className={Styles.th + " text-center"}>Date</th>
                  <th className={Styles.th + " text-center"}>Customer Name</th>
                  <th className={Styles.th + " text-center"}>Mobile No</th>
                  <th className={Styles.th + " text-center label"}>
                    No of Products
                  </th>
                  <th className={Styles.th + " text-center label"}>Total</th>
                  <th className={Styles.th + " text-center"}>Action</th>
                </tr>
              </thead>
              <tbody>
                {cartData.items &&
                  cartData.items.length > 0 &&
                  cartData.items.map((e, index) => (
                    <tr key={e.cart_id} className={Styles.tr}>
                      <td className="text-center contentFont">
                        {(cartData.page - 1) * cartData.size + (index + 1)}
                      </td>
                      <td className={Styles.td + " contentFont text-center"}>
                        {e?.requested_at}
                      </td>
                      <td className="contentFont text-center">
                        {e?.customer_name}
                      </td>
                      <td className="contentFont text-center">
                        {e?.mobile_no}
                      </td>
                      <td className="contentFont text-center">
                        {e?.count_of_products}
                      </td>
                      <td className="contentFont text-center">
                        ₹ {e?.total_amount.toFixed(2)}
                      </td>
                      <td className="text-center">
                        <OverlayTrigger
                          placement="bottom"
                          overlay={<Tooltip>View</Tooltip>}
                        >
                          <img
                            onClick={() => {
                              setViewId(e?.customer_wishlist_id);
                              setIsWishListView(true);
                            }}
                            className={Styles.del}
                            src={viewIcon}
                            alt="view"
                          />
                        </OverlayTrigger>
                        <OverlayTrigger
                          placement="bottom"
                          overlay={<Tooltip>Delete</Tooltip>}
                        >
                          <img
                            className={Styles.del}
                            src={del}
                            onClick={() => {
                              setIsConfirmation({
                                ...isConfirmation,
                                status: true,
                                id: e.customer_wishlist_id,
                              });
                            }}
                            alt="del"
                          />
                        </OverlayTrigger>

                        <button
                          className={Styles.bill}
                          onClick={() =>
                            handleBillData(e?.customer_wishlist_id)
                          }
                        >
                          Bill
                        </button>
                      </td>
                    </tr>
                  ))}
                {cartData?.items?.length === 0 ? (
                  <tr>
                    <td
                      colSpan={6}
                      className="fs-6 text-center text-secondary p-3"
                    >
                      Data Not Found
                    </td>
                  </tr>
                ) : null}
              </tbody>
            </table>
          </div>
        </div>

        <div
          style={{ display: cartData.total <= 10 ? "none" : "flex" }}
          className={Styles.cartBtnRightContainer}
        >
          <ReactPaginate
            previousLabel={<img src={LeftArrow} alt="left" />}
            nextLabel={<img src={RightArrow} alt="right" />}
            breakLabel="..."
            pageCount={Math.ceil(cartData.total) / 10}
            marginPagesDisplayed={1}
            pageRangeDisplayed={1}
            onPageChange={({ selected }) => handleCart(selected + 1)}
            forcePage={page}
            containerClassName={"pagination m-0"}
            pageClassName={"page-item"}
            pageLinkClassName={
              "page-link text-secondary rounded-circle bg-light pt-1 pb-1 ps-2 pe-2 ms-1 me-1 border-0 shadow-none"
            }
            previousClassName={"page-item"}
            previousLinkClassName={
              "page-link border-0 text-dark rounded-circle p-1 bg_orange shadow-none me-1"
            }
            nextClassName={"page-item "}
            nextLinkClassName={
              "page-link border-0 text-dark bg_orange p-1 rounded-circle shadow-none ms-1"
            }
            activeClassName={"active"}
          />
        </div>

        <Modal
          size="md"
          centered
          show={isConfirmation.status}
          onHide={() => setIsConfirmation({ ...isConfirmation, status: false })}
        >
          <Modal.Body>
            <div>
              <p className="text-start kstoreFont text-secondary fs-6 fw-bold">
                Are you sure want to delete?
              </p>
              <div className={Styles.deleteConfirmationBtnContainer}>
                <div className={Styles.btnParent}>
                  <button
                    className={
                      Styles.deleteConfirmationCancelBtn +
                      " contentFont text-uppercase"
                    }
                    onClick={() =>
                      setIsConfirmation({ ...isConfirmation, status: false })
                    }
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => {
                      handleClearCart(isConfirmation.id);
                      setIsConfirmation({ ...isConfirmation, status: false });
                    }}
                    className={
                      Styles.deleteConfirmationOkBtn +
                      " contentFont text-uppercase"
                    }
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}
