import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import Styles from "./AddtoCart.module.css";
import { TabTitle } from "../../../Utility/TabTitle";
import {
  addtocartService,
  cartListService,
  deleteCartListService,
} from "../../../Services/ApiServices";
import emptyImg from "../../../Assets/no_image.svg";
import LeftArrow from "../../../Assets/modal_left_arrow.png";
import RightArrow from "../../../Assets/modal_right_arrow.png";
import { handleProductCartCount } from "../../../Redux/Action";
import del from "../../../Assets/delete.svg";

export default function CartList() {
  TabTitle("KSTORE - Add-to-cart");
  let dispatch = useDispatch();
  let { cartCount } = useSelector((state) => state);
  const [cartData, setCartData] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [readmoreId, setReadmoreId] = useState("");
  const [page, setPage] = useState(0);
  const [isConfirmation, setIsConfirmation] = useState({
    status: false,
    id: "",
  });

  useEffect(() => {
    handleCart();
  }, []);

  const handleCart = (pageNumber = 1) => {
    setIsLoader(true);
    let formData = new FormData();
    formData.append("cart_type", 1);

    cartListService(formData, pageNumber, 10)
      .then((res) => {
        setCartData(res.data);
        setPage(pageNumber - 1);
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast(err.response.data.detail[0].msg, { type: "error" });
        } else {
          toast("Something went wrong!!", { type: "error" });
        }
      })
      .finally(() => setIsLoader(false));
  };

  const handleCartCount = (price, id, qty, productID) => {
    let formData = new FormData();
    formData.append("product_id", parseInt(productID));
    formData.append("quantity", parseInt(qty));
    formData.append("card_type", 1);
    let count_amount = price * qty;
    let refData = { ...cartData };
    let refIndex = refData.items.findIndex((e) => e.cart_id === id);
    if (refIndex !== -1) {
      refData.items[refIndex].quantity = qty;
      refData.items[refIndex].total_amount = count_amount;
      setCartData(refData);
    }
    if (qty !== "") {
      addtocartService(formData)
        .then((res) => {})
        .catch((err) => {
          if (err?.response?.data?.detail) {
            toast(err.response.data.detail[0].msg, { type: "error" });
          } else {
            toast("Something went wrong!!", { type: "error" });
          }
        });
    }
  };

  const handleClearCart = (id) => {
    let formData = new FormData();
    formData.append("cart_id", id);
    deleteCartListService(formData)
      .then((res) => {
        toast(res.data, { type: "success" });
        let filterCartList = cartData.items.filter((e) => e.cart_id !== id);
        setCartData({ ...cartData, items: filterCartList });
        dispatch(handleProductCartCount(cartCount - 1));
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast(err.response.data.detail[0].msg, { type: "error" });
        } else {
          toast("Something went wrong!!", { type: "error" });
        }
      });
  };

  return (
    <div className={Styles.bg}>
      {isLoader ? (
        <div className={Styles.loaderParent}>
          <div className={Styles.loader}></div>
        </div>
      ) : null}
      <div className="bg-white">
        <p className={Styles.title + " fontBold"}>
          Cart <span className="orange">List</span>{" "}
        </p>
        <div className={Styles.tableParentContainer}>
          <div className={Styles.table + " d-flex justify-content-center"}>
            <table>
              <thead>
                <tr className={Styles.heading}>
                  <th className={Styles.th + " text-center"}>S.No</th>
                  <th className={Styles.cartProductName + " text-center"}>
                    Product Name
                  </th>
                  <th className={Styles.th + " text-center"}>Category</th>
                  <th className={Styles.th + " text-center"}>Sub Category</th>
                  <th className={Styles.th + " text-center"}>Quantity</th>
                  <th className={Styles.th + " text-center"}>Price</th>
                  <th className={Styles.cartList + " text-center"}>Total</th>
                  <th className={Styles.cartList + " text-center"}>Action</th>
                </tr>
              </thead>
              <tbody>
                {cartData.items &&
                  cartData.items.length > 0 &&
                  cartData.items.map((e, index) => (
                    <tr key={e.cart_id} className={Styles.tr}>
                      <td className="text-center">
                        {(cartData.page - 1) * cartData.size + (index + 1)}
                      </td>
                      <td
                        className={
                          Styles.productContainer + " contentFont text-center"
                        }
                      >
                        <div className={Styles.imgContainer}>
                          <img
                            className={Styles.img}
                            src={
                              e.product_images.length > 0
                                ? e.product_images[0].product_image
                                : emptyImg
                            }
                            alt="cart"
                          />
                        </div>
                        <div className="w-50 align-self-center">
                          <p
                            className={
                              Styles.productDetails +
                              " mb-0 contentFont text-center"
                            }
                          >
                            {readmoreId.includes(e.cart_id)
                              ? e.product_name
                              : e.product_name.substring(0, 37)}
                            {e.product_name.length > 37 &&
                            !readmoreId.includes(e.cart_id)
                              ? "..."
                              : null}
                            <span
                              style={{
                                display:
                                  e.product_name.length < 37 ||
                                  readmoreId === e.cart_id
                                    ? "none"
                                    : "block",
                              }}
                              className={Styles.readmore + " orange cp"}
                              onClick={() => {
                                if (readmoreId.includes(e.cart_id)) {
                                  let filterData = readmoreId.filter(
                                    (filterId) => filterId !== e.cart_id
                                  );
                                  setReadmoreId(filterData);
                                } else {
                                  setReadmoreId([...readmoreId, e.cart_id]);
                                }
                              }}
                            >
                              {readmoreId.includes(e.cart_id)
                                ? "Show Less"
                                : "Read More"}
                            </span>
                          </p>
                        </div>
                      </td>
                      <td className="text-center contentFont">
                        {e?.category_name ? e?.category_name : "-"}
                      </td>
                      <td className="text-center contentFont">
                        {e?.sub_category_name ? e?.sub_category_name : "-"}
                      </td>
                      <td className="text-center contentFont">
                        <input
                          className={Styles.productCount}
                          type="number"
                          defaultValue={e.quantity}
                          onKeyPress={(e) => {
                            if (e.key === "0" || parseInt(e.key)) {
                            } else {
                              e.preventDefault();
                            }
                          }}
                          min="1"
                          // disabled={!e.cart_id}
                          onChange={(userCount) => {
                            handleCartCount(
                              e?.price,
                              e?.cart_id,
                              userCount.target.value,
                              e?.product_id
                            );
                          }}
                        />
                      </td>
                      <td className="contentFont text-center">
                        {"₹" + e?.price.toFixed(2)}
                      </td>
                      <td
                        className={
                          Styles.td + " orange contentFont text-center"
                        }
                      >
                        {"₹" + e.total_amount.toFixed(2)}
                      </td>
                      <td className="text-center">
                        <img
                          className={Styles.del}
                          src={del}
                          onClick={() => {
                            setIsConfirmation({
                              ...isConfirmation,
                              status: true,
                              id: e.cart_id,
                            });
                          }}
                          alt="del"
                        />
                      </td>
                    </tr>
                  ))}
                {cartData?.items?.length === 0 ? (
                  <tr>
                    <td
                      colSpan={8}
                      className="fs-6 text-center text-secondary p-3"
                    >
                      Data Not Found
                    </td>
                  </tr>
                ) : null}
              </tbody>
            </table>
          </div>
        </div>

        <div className={Styles.cartBtnContainer + " mt-3"}>
          <div
            style={{ display: cartData.total <= 10 ? "none" : "flex" }}
            className={Styles.cartBtnRightContainer}
          >
            <ReactPaginate
              previousLabel={<img src={LeftArrow} alt="left" />}
              nextLabel={<img src={RightArrow} alt="right" />}
              breakLabel="..."
              pageCount={Math.ceil(cartData.total) / 10}
              marginPagesDisplayed={1}
              pageRangeDisplayed={1}
              onPageChange={({ selected }) => handleCart(selected + 1)}
              forcePage={page}
              containerClassName={"pagination m-0"}
              pageClassName={"page-item"}
              pageLinkClassName={
                "page-link text-secondary rounded-circle bg-light pt-1 pb-1 ps-2 pe-2 ms-1 me-1 border-0 shadow-none"
              }
              previousClassName={"page-item"}
              previousLinkClassName={
                "page-link border-0 text-dark rounded-circle p-1 bg_orange shadow-none me-1"
              }
              nextClassName={"page-item "}
              nextLinkClassName={
                "page-link border-0 text-dark bg_orange p-1 rounded-circle shadow-none ms-1"
              }
              activeClassName={"active"}
            />
          </div>
        </div>

        <Modal
          size="md"
          centered
          show={isConfirmation.status}
          onHide={() => setIsConfirmation({ ...isConfirmation, status: false })}
        >
          <Modal.Body>
            <div>
              <p className="text-start kstoreFont text-secondary fs-6 fw-bold">
                Are you sure want to delete?
              </p>
              <div className={Styles.deleteConfirmationBtnContainer}>
                <div className={Styles.btnParent}>
                  <button
                    className={
                      Styles.deleteConfirmationCancelBtn +
                      " contentFont text-uppercase"
                    }
                    onClick={() =>
                      setIsConfirmation({ ...isConfirmation, status: false })
                    }
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => {
                      handleClearCart(isConfirmation.id);
                      setIsConfirmation({ ...isConfirmation, status: false });
                    }}
                    className={
                      Styles.deleteConfirmationOkBtn +
                      " contentFont text-uppercase"
                    }
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}
