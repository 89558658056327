import Styles from "./Success.module.css";
import success from "../../../Assets/customer_success.png";
import Header from "../../../SharedComponents/Header/Header";

export default function Success() {
  return (
    <>
      <Header />
      <div className={Styles.overallContainer + " container-fluid p-0"}>
        <div className={Styles.childContainer}>
          <img className={Styles.img} src={success} alt="success" />
          <p className={Styles.success}>Thanks for shopping with us!!!</p>
        </div>
      </div>
    </>
  );
}
