import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Styles from "./Categories.module.css";
import CategoriesList from "./CategoriesList";
import Popup from "./Popup";
import Filter from "./Filter";
import InventoryTypePopup from "./InventoryTypePopup";
import { TabTitle } from "../../../Utility/TabTitle";
import {
  billingService,
  ProductCategoryService,
  productListService,
} from "../../../Services/ApiServices";
import InventoryDropdown from "../Products/InvetoryDropdown";
import notFoundImage from "../../../Assets/productNotFound.svg";
import cart from "../../../Assets/mbl_cart.png";
import Loader from "../../../SharedComponents/Loader/Loader";
import LeftArrow from "../../../Assets/modal_left_arrow.png";
import RightArrow from "../../../Assets/modal_right_arrow.png";

export default function Categories() {
  TabTitle("KSTORE - Categories");
  let { state } = useLocation();
  const [data, setData] = useState(null);
  const [productsAttributes, setproductsAttributes] = useState([]);
  const [filterDetails, setFilterDetails] = useState([]);
  const [billList, setBillList] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [page, setPage] = useState("");
  const [searchData, setSearchData] = useState("");
  const [inventoryType, setInventoryType] = useState(false);
  const [displayMblViewBill, setDisplayMblViewBill] = useState(false);
  const [brandList, setBrandList] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [selectedProductAttributesIds, setselectedProductAttributesIds] =
    useState([]);
  const [isOpenInventoryType, setisOpenInventoryType] = useState(false);
  const [billOptions, setBillOption] = useState(1);

  useEffect(() => {
    handleGetProduct();
    handleGetCategoryList();
  }, [inventoryType]);

  useEffect(() => {
    if (billList?.length === 0) {
      let productDetails = JSON.parse(sessionStorage.getItem("billProducts"));
      setBillList(productDetails || []);
    }
  }, []);

  useEffect(() => {
    handleWishlistProducts();
  }, []);

  const handleWishlistProducts = () => {
    const session = sessionStorage.getItem("billProducts");
    if (state?.wishlist_products?.length > 0 && session === null) {
      let res = [];
      let temp = [...state?.wishlist_products];
      temp.map((ele, ind) => {
        ele.qty = ele.total_qty;
        ele.total_amount =
          ele.qty *
          (billOptions === 2
            ? ele.franchise_price
            : ele.franchise_customer_price);
        res.push({ ...ele, isFromWishList: true });
      });
      setBillList(res);
    }
  };

  const handleGetCategoryList = () => {
    ProductCategoryService()
      .then((res) => setFilterDetails(res.data.items))
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast(err.response.data.detail[0].msg, { type: "error" });
        } else {
          toast("Something went wrong!!", { type: "error" });
        }
      });
  };

  const handleGetProduct = (sub_category_id, category_id, pageNum = 1) => {
    setIsLoader(true);
    let formData = new FormData();
    formData.append("list_type", inventoryType ? 1 : 2);
    formData.append("search", searchData);
    formData.append(
      "brand",
      filterData.length > 0 ? "[" + filterData + "]" : ""
    );

    if (category_id && sub_category_id) {
      formData.append("sub_category_id", parseInt(sub_category_id));
      formData.append("category_id", parseInt(category_id));
    } else if (category_id) {
      formData.append("category_id", parseInt(category_id));
    }
    if (selectedProductAttributesIds.length > 0) {
      formData.append(
        "product_attribute_value_id",
        selectedProductAttributesIds
      );
    }

    productListService(formData, pageNum, 12)
      .then((res) => {
        setData(res.data);
        setBrandList(res.data.brands);
        setproductsAttributes(res.data.product_attribute);
        setPage(pageNum - 1);
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast.error(err.response.data.detail[0].msg);
        } else {
          toast.error("Something went wrong!!");
        }
      })
      .finally(() => setIsLoader(false));
  };

  const handleBillList = (data) => {
    let filter = billList?.findIndex((e) => e?.product_id === data?.product_id);
    if (filter === -1) {
      setBillList([
        ...billList,
        {
          ...data,
          total_amount:
            billOptions === 2
              ? data.franchise_price
              : data.franchise_customer_price,
        },
      ]);
      setDisabled(false);
    } else {
      handleDelete(data?.product_id);
    }
  };

  const clearBilllist = () => {
    setBillList([]);
  };

  const handleDelete = (id) => {
    setBillList(billList.filter((e) => e?.product_id !== id));
    setDisabled(false);
  };

  const handleQuantity = (id, qty) => {
    let ref = [...billList];
    let refIndex = billList.findIndex((e) => e?.product_id === id);
    if (refIndex !== -1) {
      if (
        qty !== "" &&
        qty >= (inventoryType ? 1 : ref[refIndex].min_quantity) &&
        qty <=
          (inventoryType ? ref[refIndex].quantity : ref[refIndex].max_quantity)
      ) {
        ref[refIndex].qty = parseInt(qty);
        ref[refIndex].total_amount = parseInt(
          qty *
            (billOptions === 2
              ? ref[refIndex]?.franchise_price
              : ref[refIndex]?.franchise_customer_price)
        );
      }
    }
    setBillList(ref);
    setDisabled(false);
  };

  const handleSubmit = (amt, data) => {
    let id = billList.map((e) => e.product_id);
    let quantity = billList.map((e) => parseInt(e.qty));
    let orderDetails = billList.map((e) => {
      return {
        product_id: e.product_id,
        quantity: e.qty,
        product_unit: 0,
        product_color: 0,
        amount: 0,
      };
    });
    let formData = new FormData();
    formData.append("product_id", "[" + id + "]");
    formData.append("quantity", "[" + quantity + "]");
    formData.append("order_details", JSON.stringify(orderDetails));
    formData.append("amount", amt);
    formData.append("customer_name", data.customerName);
    formData.append("mobile_no", data.mobileNo);
    formData.append("email", data.email);
    formData.append("address", data.address);
    formData.append("state", data.state);
    formData.append("city", data.city);
    formData.append("pincode", data.pincode);
    formData.append("gst_no", data.gst_no);
    formData.append("invoice_no", data.invoice_no);
    formData.append("order_from", inventoryType ? 1 : 0);
    // newBillingService(formData)
    billingService(formData)
      .then((res) => {
        toast("Billing Successfully", { type: "success" });
        setDisabled(true);
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast.error(err.response.data.detail[0].msg);
        } else {
          toast.error("Something went wrong!!");
        }
      });
  };

  const handleEditAmount = (id, price) => {
    let temp = [...billList];
    let tempIndex = billList.findIndex((e) => e?.product_id === id);
    if (tempIndex !== -1) {
      temp[tempIndex].franchise_customer_price = parseInt(price);
      temp[tempIndex].total_amount = parseInt(temp[tempIndex]?.qty * price);
    }
    setBillList(temp);
  };
  const handleViewDetails = (data = {}) => {
    let length = Object.keys(data).length;
    let res = [];
    let isAlreadyExist = billList?.find(
      (e) => e?.product_id === data?.product_id
    )
      ? true
      : false;

    if (length > 0 && !isAlreadyExist) {
      let tempData = {
        ...data,
        total_amount:
          parseInt(data.qty) *
          (billOptions === 2
            ? data.franchise_price
            : data.franchise_customer_price),
      };
      res.push(tempData);
    }

    setBillList([...billList, ...res]);
  };

  const handleProductsAttributesChecked = (id, isChecked) => {
    let tempAttributesIds = [...selectedProductAttributesIds];

    if (isChecked) {
      tempAttributesIds.push(id);
    } else {
      tempAttributesIds = tempAttributesIds.filter((itemId) => itemId !== id);
    }
    setselectedProductAttributesIds(tempAttributesIds);
  };

  const handleChangeInventoryType = () => {
    if (!inventoryType && billOptions !== 1) {
      setBillOption(1);
    }
    setInventoryType(!inventoryType);
    setBillList([]);
  };

  return (
    <div className={Styles.bg + " container-fluid"}>
      <Loader isLoader={isLoader} />
      {isOpenInventoryType ? (
        <InventoryTypePopup
          isOpen={isOpenInventoryType}
          onHide={() => {
            setisOpenInventoryType(false);
          }}
          handleSubmit={() => {
            handleChangeInventoryType();
          }}
        />
      ) : null}
      <div className={Styles.FilterContainer}>
        <Filter
          onSearch={handleGetProduct}
          brandList={brandList}
          searchData={searchData}
          selectedProductsAttributes={selectedProductAttributesIds}
          productsAttributes={productsAttributes}
          setsearchData={setSearchData}
          onPressSeachButton={handleGetProduct}
          handleProductAttributeChecked={handleProductsAttributesChecked}
          onChangeBrandFilter={(ele) => {
            let filter = ele.map((element) => element.brand_id);
            setFilterData(filter);
          }}
          inventoryType={inventoryType}
          onChangeInventoryType={() => {
            if (billList.length > 0) {
              setisOpenInventoryType(true);
            } else {
              handleChangeInventoryType();
            }
          }}
        />
      </div>
      <div className={Styles.LeftContainer}>
        <div>
          <div className={Styles.dropdownParent + " d-flex"}>
            <InventoryDropdown
              data={filterDetails}
              handleGetProduct={handleGetProduct}
              listType={state ?? 1}
            />
          </div>
        </div>
        {data?.length === 0 ? (
          <div className={Styles.notFoundImgContainer}>
            <img
              className={Styles.notFoundImg}
              src={notFoundImage}
              alt="notfound"
            />
          </div>
        ) : (
          <div className={Styles.categoriesListContainer}>
            {data !== null
              ? data?.items?.map((e) => (
                  <CategoriesList
                    handleBillList={handleBillList}
                    data={e}
                    type={inventoryType}
                    handleViewDetails={handleViewDetails}
                    key={e?.product_id}
                    highlight={billList?.some(
                      (data) => data?.product_id === e?.product_id
                    )}
                  />
                ))
              : null}
          </div>
        )}
        <div
          style={{ display: data?.total <= 12 ? "none" : "flex" }}
          className={Styles.paginationContainer}
        >
          <ReactPaginate
            previousLabel={<img src={LeftArrow} alt="left" />}
            nextLabel={<img src={RightArrow} alt="right" />}
            breakLabel="..."
            pageCount={Math.ceil(data?.total) / 10}
            marginPagesDisplayed={1}
            pageRangeDisplayed={1}
            onPageChange={({ selected }) =>
              handleGetProduct("", "", selected + 1)
            }
            containerClassName={
              "pagination m-0 d-flex justify-content-end me-4 bg-secondory"
            }
            pageClassName={"page-item"}
            pageLinkClassName={
              "page-link text-secondary rounded-circle bg-light pt-1 pb-1 ps-2 pe-2 ms-1 me-1 border-0 shadow-none"
            }
            forcePage={page}
            previousClassName={"page-item"}
            previousLinkClassName={
              "page-link border-0 text-dark p-1 rounded-circle bg_orange shadow-none me-1"
            }
            nextClassName={"page-item "}
            nextLinkClassName={
              "page-link border-0 text-dark p-1 bg_orange rounded-circle shadow-none ms-1"
            }
            activeClassName={"active"}
          />
        </div>
      </div>

      <div className={Styles.RightContainer}>
        <Popup
          billList={billList}
          disabled={disabled}
          fromCustomerWishlist={
            state?.fromCustomerWishlist !== undefined
              ? state?.fromCustomerWishlist
              : false
          }
          customerDetails={state}
          type={inventoryType}
          handleQuantity={handleQuantity}
          handleDelete={handleDelete}
          handleSubmit={handleSubmit}
          clearBilllist={clearBilllist}
          handleEditAmount={handleEditAmount}
          billOptions={billOptions}
          setBillOption={setBillOption}
        />
      </div>

      <div className={Styles.mblcart}>
        <img
          className={Styles.cartImg}
          onClick={() => {
            setDisplayMblViewBill(!displayMblViewBill);
            window.scrollTo(0, 0);
          }}
          src={cart}
          alt="cart"
        />
        <div className={Styles.notification}>
          <span className={Styles.badge}>{billList.length}</span>
        </div>
      </div>

      {displayMblViewBill ? (
        <div className={Styles.mblRightContainer}>
          <Popup
            billList={billList}
            disabled={disabled}
            type={inventoryType}
            handleQuantity={handleQuantity}
            handleDelete={handleDelete}
            handleSubmit={handleSubmit}
            clearBilllist={clearBilllist}
            handleEditAmount={handleEditAmount}
            billOptions={billOptions}
            setBillOption={setBillOption}
          />
        </div>
      ) : null}
    </div>
  );
}
