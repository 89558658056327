import Modal from "react-bootstrap/Modal";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Styles from "./ViewCustomerWishlist.module.css";
import { viewCustomerWishlistService } from "../../../Services/ApiServices";
import cancelIcon from "../../../Assets/cross.png";
import Profile from "../../../Assets/user_img.png";
import mail from "../../../Assets/email.png";
import phone from "../../../Assets/mbl.png";
import location from "../../../Assets/location_img.png";
import Loader from "../../../SharedComponents/Loader/Loader";

export default function ViewCustomerWishlist(props) {
  let navigate = useNavigate();
  const [viewData, setViewData] = useState(null);
  const [isLoader, setIsLoader] = useState(false);
  const [readmoreId, setReadmoreId] = useState([]);
  useEffect(() => {
    handleView();
  }, []);

  const handleView = () => {
    setIsLoader(true);
    viewCustomerWishlistService(props.id)
      .then((res) => setViewData(res.data))
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast.error(err.response.data.detail[0].msg, { type: "error" });
        } else {
          toast.error("Something went wrong!!", { type: "error" });
        }
      })
      .finally(() => setIsLoader(false));
  };

  const handleData = () => {
    navigate("/store/categories", {
      state: { ...viewData, fromCustomerWishlist: true },
    });
  };

  const handleTotalAmount = (data) => {
    const total_amount = data.reduce(
      (prev, current) =>
        prev + parseInt(current?.total_qty * current?.franchise_customer_price),
      0
    );
    return total_amount;
  };

  return (
    <div>
      <Loader isLoader={isLoader} />
      <Modal
        show={props.state}
        onHide={props.onHide}
        // {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        // style={{ maxWidth: "1024px" }}
      >
        <Modal.Body>
          <div className={Styles.cancelImgParentContainer}>
            <div>
              <p className="fontBold">
                View Customer <span className="orange">Product</span>
              </p>
            </div>
            <div className={Styles.cancelImgContainer}>
              <img
                onClick={props.onHide}
                src={cancelIcon}
                alt="cancel"
                className={Styles.cancelImg}
              />
            </div>
          </div>
          <div>
            <div className={Styles.usernameParentContainer}>
              <div className={Styles.usernameContainer}>
                <img className={Styles.img} src={Profile} alt="profile" />
                <p className={Styles.username}>{viewData?.customer_name}</p>
              </div>
            </div>
            <div className={Styles.detailContainer}>
              <img className={Styles.img} src={phone} alt="profile" />
              <p className={Styles.detail}>{viewData?.mobile_no}</p>
            </div>
            <div className={Styles.detailContainer}>
              <img className={Styles.img} src={mail} alt="profile" />
              <p className={Styles.detail}>
                {viewData?.email ? viewData?.email : "-"}
              </p>
            </div>
            <div className={Styles.detailContainer}>
              <img
                style={{ marginTop: "3px", alignSelf: "flex-start" }}
                className={Styles.location}
                src={location}
                alt="profile"
              />
              <p className={Styles.detail}>
                {viewData?.address}
                {","}
                <br />
                {viewData?.state_name}
                {","}
                <br />
                {viewData?.city_name}
                {"."}
              </p>
            </div>
          </div>

          {viewData?.wishlist_products?.length > 0 ? (
            <div className={Styles.parentContainer}>
              <div className={Styles.childContainer + " mt-3"}>
                <table>
                  <thead>
                    <tr>
                      <th className={Styles.th + "  label text-center"}>
                        S.No
                      </th>
                      <th className={Styles.productname + " label text-center"}>
                        Product Name
                      </th>
                      <th className={Styles.th + "  label text-center"}>Qty</th>
                      <th className=" label text-center">Price</th>
                      <th className=" label text-center">Total</th>
                    </tr>
                  </thead>
                  <tbody className={Styles.data}>
                    {viewData?.wishlist_products?.map((e, index) => (
                      <tr>
                        <td className="text-center">{index + 1}</td>
                        <td
                          className={
                            Styles.productname + " contentFont text-center"
                          }
                        >
                          {readmoreId.includes(e.product_id)
                            ? e.product_name
                            : e.product_name.substring(0, 37)}
                          {e.product_name.length > 37 &&
                          !readmoreId.includes(e.product_id)
                            ? "..."
                            : null}
                          <span
                            style={{
                              display:
                                e.product_name.length < 37 ? "none" : "block",
                            }}
                            className={Styles.readmore + " orange"}
                            onClick={() => {
                              if (readmoreId.includes(e.product_id)) {
                                let filterData = readmoreId.filter(
                                  (filterId) => filterId !== e.product_id
                                );
                                setReadmoreId(filterData);
                              } else {
                                setReadmoreId([...readmoreId, e.product_id]);
                              }
                            }}
                          >
                            {readmoreId.includes(e.product_id)
                              ? "Show Less"
                              : "Read More"}
                          </span>
                        </td>
                        <td className={Styles.td}>{e?.total_qty}</td>
                        <td className={Styles.td}>
                          {"₹ " + e?.franchise_customer_price?.toFixed(2)}
                        </td>
                        <td className={Styles.td}>
                          {"₹ " +
                            (
                              e?.total_qty * e?.franchise_customer_price
                            ).toFixed(2)}
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan={3} />
                      <td className="label text-center">Total</td>
                      <td className="label text-center">
                        ₹
                        {handleTotalAmount(
                          viewData?.wishlist_products
                        )?.toFixed(2)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          ) : null}

          <div className={Styles.btnContainer}>
            <button onClick={handleData} className={Styles.btn}>
              Generate Bill
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
