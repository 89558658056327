import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import FileSaver from "file-saver";
import moment from "moment";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import Styles from "./Myorder.module.css";
import { myorderService } from "../../../Services/ApiServices";
import LeftArrow from "../../../Assets/modal_left_arrow.png";
import RightArrow from "../../../Assets/modal_right_arrow.png";
import view from "../../../Assets/view.svg";
import searchIcon from "../../../Assets/searchFilter.png";
import downloadIcon from "../../../Assets/download_icon.png";
import Loader from "../../../SharedComponents/Loader/Loader";

export default function Myorder() {
  let navigate = useNavigate();
  const [myorderData, setMyOrderData] = useState(null);
  const [isLoader, setIsLoader] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [filterData, setFilterData] = useState({
    fromDate: "",
    toDate: "",
    billNumber: "",
  });
  const [page, setPage] = useState(0);

  useEffect(() => {
    handlemyorder();
  }, []);

  const handlemyorder = (pageNum = 1, csv) => {
    setIsLoader(true);
    let formData = new FormData();

    if (filterData.fromDate) {
      formData.append(
        "from_date",
        dayjs(filterData.fromDate).format("YYYY-MM-DD 00:00:00")
      );
    }
    if (filterData.toDate) {
      formData.append(
        "to_date",
        dayjs(filterData.toDate).format("YYYY-MM-DD 23:59:59")
      );
    }
    formData.append("bill_id", filterData.billNumber);
    if (csv !== undefined) {
      formData.append("download", parseInt(csv));
    }

    myorderService(pageNum, 10, formData)
      .then((res) => {
        if (csv !== undefined) {
          let url = res.data;
          let urlArr = url.split(".");
          let ext = urlArr[urlArr.length - 1];
          let dateTime =
            moment().format("DD-MM-YYYY") + "," + moment().format("hh:mm:ss a");
          let fileName = dateTime + "." + ext;
          FileSaver.saveAs(url, fileName);
        } else {
          setMyOrderData(res.data);
          setPage(pageNum - 1);
        }
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast.error(err.response.data.detail[0].msg, { type: "error" });
        } else {
          toast.error("Something went wrong !!", { type: "error" });
        }
      })
      .finally(() => setIsLoader(false));
  };

  const handleDownload = (url) => {
    let urlArr = url.split(".");
    let ext = urlArr[urlArr.length - 1];
    let dateTime =
      moment().format("DD-MM-YYYY") + "," + moment().format("hh:mm:ss a");
    let fileName = dateTime + "." + ext;
    FileSaver.saveAs(url, fileName);
  };

  const handleReset = (pageNum = 1) => {
    setIsLoader(true);
    setFilterData({ fromDate: "", toDate: "", billNumber: "" });
    myorderService(pageNum, 10, "")
      .then((res) => {
        setMyOrderData(res.data);
        setPage(pageNum - 1);
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast.error(err.response.data.detail[0].msg, { type: "error" });
        } else {
          toast.error("Something went wrong !!", { type: "error" });
        }
      })
      .finally(() => setIsLoader(false));
  };

  return (
    <div className={Styles.bg}>
      <Loader isLoader={isLoader} />

      <div className="bg-white">
        <div className={Styles.searchContainer}>
          <p className={Styles.title + " fontBold"}>
            My Order <span className="orange">List</span>{" "}
          </p>
          <div>
            <img
              onClick={() => setShowFilter(!showFilter)}
              className={Styles.searchImg}
              src={searchIcon}
              alt="search"
            />
            <button
              className={Styles.CsvBtn + " kstoreFont"}
              onClick={() => handlemyorder(1, 1)}
            >
              Export to Csv
              <img
                className={Styles.download}
                src={downloadIcon}
                alt="download"
              />
            </button>
          </div>
        </div>

        {showFilter ? (
          <div className={Styles.filterParentContainer + " row"}>
            <div className="col-md-3 col-sm-12">
              <label className="label">Bill Number</label>
              <input
                type="text"
                className={Styles.filterInput + " contentFont"}
                value={filterData.billNumber}
                onChange={(e) => {
                  setFilterData({ ...filterData, billNumber: e.target.value });
                }}
              />
            </div>
            <div className="col-md-3 col-sm-12">
              <label className="label">From Date</label>
              <DatePicker
                value={filterData.fromDate}
                className={Styles.filterInput + " contentFont"}
                format={"DD/MM/YYYY"}
                onChange={(val) => {
                  setFilterData({
                    ...filterData,
                    fromDate: val,
                  });
                  if (val > filterData.toDate) {
                    setFilterData((pre) => ({ ...pre, toDate: "" }));
                  }
                }}
              />
            </div>
            <div className="col-md-3 col-sm-12">
              <label className="label">To Date</label>
              <DatePicker
                value={filterData.toDate}
                className={Styles.filterInput + " contentFont"}
                format={"DD/MM/YYYY"}
                disabledDate={(current) => {
                  return current < filterData.fromDate;
                }}
                onChange={(val) => {
                  setFilterData({
                    ...filterData,
                    toDate: val,
                  });
                }}
              />
            </div>
            <div className=" d-flex justify-content-end align-self-end col-md-3 pt-2">
              <button
                onClick={() => handleReset()}
                className={Styles.resetBtn + " me-2"}
              >
                reset
              </button>
              <button
                onClick={() => handlemyorder()}
                className={Styles.searchBtn}
              >
                Search
              </button>
            </div>
          </div>
        ) : null}

        <div className={Styles.tableParentContainer}>
          <div className={Styles.table + " d-flex justify-content-center"}>
            <table>
              <thead>
                <tr>
                  <th className={Styles.th + " text-center"}>S.No</th>
                  <th className={Styles.cartList + " text-center"}>
                    Bill Number
                  </th>
                  <th className={Styles.cartList + " text-center"}>
                    Ordered Date
                  </th>
                  <th className={Styles.th + " text-center"}>Order Status</th>
                  <th className={Styles.th + " text-center"}>
                    Courier Name/Number
                  </th>
                  <th className={Styles.cartList + " text-center"}>
                    No of Products
                  </th>
                  <th className={Styles.cartList + " text-center"}>
                    Total Amount
                  </th>
                  <th className={Styles.th + " text-center"}>Action</th>
                </tr>
              </thead>
              <tbody>
                {myorderData?.items?.map((e, index) => (
                  <tr key={e?.purchase_product_id} className={Styles.tr}>
                    <td className={Styles.td + " text-center"}>
                      {(myorderData?.page - 1) * myorderData?.size +
                        (index + 1)}
                    </td>
                    <td className="text-center contentFont">
                      {e?.bill_no ? e?.bill_no : "-"}
                    </td>
                    <td className="text-center contentFont">
                      {e?.ordered_date ? e?.ordered_date : "-"}
                    </td>
                    <td className="text-center contentFont">
                      {e?.product_status_name}
                    </td>
                    <td className="text-center contentFont">
                      {e?.courier_name?.length > 0
                        ? e?.courier_name?.map((ele, index) => (
                            <p>
                              {ele
                                ? ele
                                : "-" +
                                  (e?.courier_name?.length - 1 === index
                                    ? ""
                                    : ",")}
                            </p>
                          ))
                        : "-"}
                    </td>
                    <td className="text-center contentFont">
                      {e?.no_of_products}
                    </td>
                    <td className="text-center contentFont">
                      {"₹" + e?.total_amount?.toFixed(2)}
                    </td>
                    <td className="text-center contentFont">
                      <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip>View</Tooltip>}
                      >
                        <img
                          className={Styles.complaintsImg}
                          src={view}
                          alt="view"
                          onClick={() =>
                            navigate("/store/myorderview", {
                              state: e.purchase_details_id,
                            })
                          }
                        />
                      </OverlayTrigger>
                      {e?.invoice_status === 1 ? (
                        <OverlayTrigger
                          placement="bottom"
                          overlay={<Tooltip>Download</Tooltip>}
                        >
                          <img
                            className={Styles.complaintsImg}
                            src={downloadIcon}
                            alt="download"
                            onClick={() => handleDownload(e?.invoice_url)}
                          />
                        </OverlayTrigger>
                      ) : null}
                    </td>
                  </tr>
                ))}
                {myorderData?.items?.length === 0 ? (
                  <tr>
                    <td
                      colSpan={8}
                      className="fs-6 text-center text-secondary p-3"
                    >
                      Data Not Found
                    </td>
                  </tr>
                ) : null}
              </tbody>
            </table>
          </div>
        </div>

        <div
          style={{ display: myorderData?.total <= 10 ? "none" : "flex" }}
          className={Styles.cartBtnRightContainer}
        >
          <ReactPaginate
            previousLabel={<img src={LeftArrow} alt="left" />}
            nextLabel={<img src={RightArrow} alt="right" />}
            breakLabel="..."
            pageCount={Math.ceil(myorderData?.total) / 10}
            marginPagesDisplayed={1}
            pageRangeDisplayed={1}
            onPageChange={({ selected }) => handlemyorder(selected + 1)}
            forcePage={page}
            containerClassName={"pagination m-0"}
            pageClassName={"page-item"}
            pageLinkClassName={
              "page-link text-secondary rounded-circle bg-light pt-1 pb-1 ps-2 pe-2 ms-1 me-1 border-0 shadow-none"
            }
            previousClassName={"page-item"}
            previousLinkClassName={
              "page-link border-0 text-dark rounded-circle p-1 bg_orange shadow-none me-1"
            }
            nextClassName={"page-item "}
            nextLinkClassName={
              "page-link border-0 text-dark bg_orange p-1 rounded-circle shadow-none ms-1"
            }
            activeClassName={"active"}
          />
        </div>
      </div>
    </div>
  );
}
