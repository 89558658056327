import React from "react";
import ReactSelect from "react-select";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import Styles from "./Filter.module.css";
import DropdownIcon from "../../../Assets/dropDownIcon.png";
import SearchIcon from "../../../Assets/searchGreyIcon.png";
import CloseIcon from "../../../Assets/closeGreyIcon.png";

export default function Filter({
  brandList = [],
  productsAttributes = [],
  onChangeBrandFilter,
  onPressSeachButton,
  onChangeInventoryType,
  selectedProductsAttributes = [],
  handleProductAttributeChecked,
  searchData,
  setsearchData,
  inventoryType,
}) {
  const customStyles = {
    control: () => ({
      backgroundColor: "#fff1e7",
      border: "1px solid rgba(0,0,0,0.1)",
      width: "100%",
      borderRadius: "5px",
      display: "flex",
      margin: "10px 0",
      height: "47px",
      fontSize: "12px ",
      zIndex: "1",
    }),
    indicatorsContainer: () => ({
      display: "flex",
      alignSelf: "center",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "black",
    }),
    input: (provided, state) => ({
      ...provided,
      color: "black",
      padding: "6px 5px",
    }),
  };

  const isSelectedItem = (id) => {
    let isSelected = [...selectedProductsAttributes].find(
      (itemId) => itemId === id
    );
    if (isSelected) {
      return true;
    }
    return false;
  };

  return (
    <div className={Styles.OverallFilterContainer}>
      <div className={Styles.SwitchParent}>
        <p className={Styles.switchTitle}>Only Inventory</p>
        <label className="switch">
          <input
            value={inventoryType}
            checked={inventoryType}
            onChange={onChangeInventoryType}
            type="checkbox"
          />
          <span className="slider round"></span>
        </label>
      </div>
      <div className={Styles.searchInputContainer}>
        <input
          className={Styles.searchInput}
          type="text"
          placeholder="Search Products..."
          value={searchData}
          onChange={(e) => setsearchData(e.target.value)}
        />
        {searchData ? (
          <img
            src={CloseIcon}
            style={{
              height: "10px",
              width: "10px",
              cursor: "pointer",
              margin: "5px",
            }}
            onClick={() => {
              setsearchData("");
            }}
          />
        ) : null}
        <div
          style={{
            height: "20px",
            width: "1px",
            margin: "0px 10px",
            backgroundColor: "hsl(0,0%,80%)",
          }}
        />
        <img
          src={SearchIcon}
          style={{ height: "20px", width: "20px", cursor: "pointer" }}
          onClick={onPressSeachButton}
        />
      </div>

      <ReactSelect
        className="basic-single contentFont"
        classNamePrefix="select"
        name="color"
        placeholder="Select Brand..."
        isClearable={true}
        isMulti
        styles={customStyles}
        options={brandList || []}
        getOptionLabel={(e) => e.brand_name}
        getOptionValue={(e) => e.brand_id}
        onChange={(ele) => {
          onChangeBrandFilter(ele);
        }}
      />
      <button className={Styles.searchBtn} onClick={onPressSeachButton}>
        Filter
      </button>
      <div className={Styles.AttributesContainer}>
        {productsAttributes.map((item, index) => {
          return (
            <div key={index}>
              <Accordion square elevation={0}>
                <AccordionSummary
                  classes={{ content: Styles.MuiAccordionSummaryContent }}
                  expandIcon={
                    <img
                      src={DropdownIcon}
                      style={{ height: "14px", width: "14px" }}
                    />
                  }
                >
                  <div style={{ width: "100%" }}>
                    <div className={Styles.headingTextContainer}>
                      <div className="d-flex justify-content-between">
                        <h4 className={Styles.filterTitle}>{item.attribute}</h4>
                      </div>
                    </div>
                    <div className="d-flex" style={{ width: "110%" }}>
                      <hr className={Styles.hr1 + " m-0"} />
                      <hr className={Styles.hr2 + " m-0"} />
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails
                  classes={{ root: Styles.MuiAccordionDetailsRoot }}
                >
                  <div className={Styles.checkboxContainer}>
                    {item.product_attribute_values.map((subItem, subIndex) => {
                      return (
                        <div
                          key={subIndex}
                          className={Styles.checkbox + " form-check mt-3"}
                        >
                          <input
                            className={Styles.input + " form-check-input"}
                            type="checkbox"
                            id={subItem.product_attribute_value_id}
                            checked={isSelectedItem(
                              subItem.product_attribute_value_id
                            )}
                            onChange={(e) => {
                              handleProductAttributeChecked(
                                subItem.product_attribute_value_id,
                                e.target.checked
                              );
                            }}
                          />

                          <label
                            className="form-check-label dropdownHeading"
                            htmlFor={subItem.product_attribute_value_id}
                          >
                            {subItem.value_name}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          );
        })}
      </div>
    </div>
  );
}
