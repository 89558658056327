import { useState } from "react";
import toast from "react-hot-toast";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import Styles from "./VerifyOTP.module.css";
import gif from "../../Assets/kstore.gif";
import { ChangePasswordService } from "../../Services/ApiServices";
import visibleImg from "../../Assets/visible.png";
import unvisible from "../../Assets/unvisible.png";
import Loader from "../../SharedComponents/Loader/Loader";

export default function VerifyOTP() {
  let navigate = useNavigate();
  const [isLoader, setIsLoader] = useState(false);
  const [isNewPassword, setIsNewPassword] = useState(false);
  const [isConfirmPassword, setIsConfirmPassword] = useState(false);

  const { handleSubmit, values, errors, touched, handleChange } = useFormik({
    initialValues: {
      new_password: "",
      confirm_password: "",
    },
    validationSchema: Yup.object({
      new_password: Yup.string().required("Required"),
      confirm_password: Yup.string()
        .oneOf([Yup.ref("new_password"), null], "Passwords must match")
        .required("Required"),
    }),
    onSubmit: (values) => {
      handleVerify(values);
    },
  });

  const handleVerify = (data) => {
    setIsLoader(true);
    let formData = new FormData();
    formData.append("new_password", data.new_password);
    ChangePasswordService(formData)
      .then((res) => {
        sessionStorage.setItem("otpStatus", 1);
        navigate("/store");
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast(err.response.data.detail[0].msg, { type: "error" });
        } else {
          toast("Something went wrong!!", { type: "error" });
        }
      })
      .finally(() => setIsLoader(false));
  };

  return (
    <>
      <Loader isLoader={isLoader} />
      <div className={Styles.parentContainer}>
        <div className={Styles.childContainer}>
          <div className="m-4">
            <img className={Styles.img} src={gif} alt="gif" />
          </div>
          <form onSubmit={(e) => e.preventDefault()}>
            <div className={Styles.inputContainer}>
              <p className={Styles.text + " m-2"}>Enter New Password</p>
              <div className={Styles.inputParent}>
                <input
                  className={Styles.input}
                  type={isNewPassword ? "text" : "password"}
                  name="new_password"
                  autoComplete="new-password"
                  value={values.new_password}
                  placeholder="Enter New Password"
                  onChange={handleChange}
                />
                <img
                  onClick={() => setIsNewPassword(!isNewPassword)}
                  className={Styles.visibleImg}
                  src={isNewPassword ? visibleImg : unvisible}
                />
              </div>
              {touched.new_password && errors.new_password ? (
                <div className={Styles.errMsg + " text-danger"}>
                  {errors.new_password}
                </div>
              ) : null}
              <p className={Styles.text + " m-2"}>Enter Confirm Password</p>
              <div className={Styles.inputParent}>
                <input
                  className={Styles.input}
                  type={isConfirmPassword ? "text" : "password"}
                  value={values.confirm_password}
                  name="confirm_password"
                  placeholder="Enter Confirm Password"
                  onChange={handleChange}
                />
                <img
                  onClick={() => setIsConfirmPassword(!isConfirmPassword)}
                  className={Styles.visibleImg}
                  src={isConfirmPassword ? visibleImg : unvisible}
                />
              </div>
              {touched.confirm_password && errors.confirm_password ? (
                <div className={Styles.errMsg + " text-danger"}>
                  {errors.confirm_password}
                </div>
              ) : null}

              <div className={Styles.btnContainer}>
                <button onClick={handleSubmit} className={Styles.btn}>
                  Verify
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
