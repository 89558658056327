import React from "react";
import Styles from "./NotFound.module.css";
import reject from "../../Assets/reject_img.png";
function NotFound() {
  return (
    <div className={Styles.container}>
      <div className={Styles.imgContainer}>
        <img className={Styles.img} src={reject} alt="not found" />
      </div>
      <p className={Styles.notfound}>Page Not Found</p>
    </div>
  );
}

export default NotFound;
