import Styles from "./Modal.module.css";
import Modal from "react-bootstrap/Modal";
import { Accordion } from "react-bootstrap";
import { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import ReactImageMagnify from "react-image-magnify";
import toast from "react-hot-toast";
import cancel from "../../../Assets/cross.png";
import { viewProductService } from "../../../Services/ApiServices";
import Loader from "../../../SharedComponents/Loader/Loader";
import { getVideoThumbnail } from "../../../Utility/Utility";

export default function ModalView(props) {
  const [slider1Ref, setslider1Ref] = useState(null);
  const [slider2Ref, setslider2Ref] = useState(null);
  const [selectedUnit, setselectedUnit] = useState({
    value: null,
    isError: false,
  });
  const [productCount, setProductCount] = useState({
    count: 1,
    isError: false,
    errorMsg: "",
  });
  const [productDetails, setproductDetails] = useState(null);
  const [data, setData] = useState([]);
  const [isLoader, setIsLoader] = useState(false);

  useEffect(() => {
    handleViewProduct();
  }, []);

  const handleViewProduct = () => {
    setIsLoader(true);
    let formData = new FormData();

    formData.append("product_id", props.productId);
    formData.append("product_type", props.type ? 1 : 2);

    viewProductService(formData)
      .then((res) => {
        let tempProductDetails = { ...res.data };
        let tempImagesColors = [...tempProductDetails.product_images].map(
          (item, index) => {
            if (index === 0) {
              return { ...item, isActive: true };
            } else {
              return { ...item, isActive: false };
            }
          }
        );
        tempProductDetails = {
          ...tempProductDetails,
          product_images: tempImagesColors,
        };
        setselectedUnit({
          isError: false,
          value: res.data.product_units ? res.data.product_units[0] : null,
        });
        if (tempProductDetails.min_quantity) {
          setProductCount({
            count: props.type ? "1" : tempProductDetails.min_quantity,
            isError: false,
            errorMsg: "",
          });
        }
        setproductDetails(tempProductDetails);
        setData(res.data.product_price);
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast(err.response.data.detail[0].msg);
        } else {
          toast("Something went wrong!!");
        }
        props.onHide();
      })
      .finally(() => setIsLoader(false));
  };

  const handlePressColor = (selectedItem) => {
    let tempProductDetails = { ...productDetails };
    let alreadySelectedItem = [...tempProductDetails.product_images].find(
      (item) => item.isActive
    );
    if (alreadySelectedItem.code !== selectedItem.code) {
      let tempImagesColors = [...tempProductDetails.product_images].map(
        (item) => {
          if (item.code === selectedItem.code) {
            return { ...item, isActive: true };
          } else {
            return { ...item, isActive: false };
          }
        }
      );
      tempProductDetails = {
        ...tempProductDetails,
        product_images: tempImagesColors,
      };
      setproductDetails(tempProductDetails);
    }
  };

  const handleProductCount = (data) => {
    if (data < 1 || isNaN(data)) {
      setProductCount({
        count: 0,
        isError: true,
        errorMsg: "Product Quantity is not valid",
      });
    } else if (data < (props.type ? 1 : productDetails.min_quantity)) {
      setProductCount({
        count: props.type ? 1 : productDetails.min_quantity,
        isError: false,
        errorMsg: `Product Quantity must be greaterthan ${
          props.type ? 1 : productDetails.min_quantity
        }`,
      });
    } else if (
      data >
      (props.type ? productDetails.quantity : productDetails.max_quantity)
    ) {
      setProductCount({
        count: props.type
          ? productDetails.quantity
          : productDetails.max_quantity,
        isError: false,
        errorMsg: `Product Quantity must be lessthan ${
          props.type ? productDetails.quantity : productDetails.max_quantity
        }`,
      });
    } else {
      setProductCount({ count: data, isError: false, errorMsg: "" });
    }
  };

  const getImagesVideosData = (isOnlyImage = false) => {
    if (productDetails) {
      let tempSelectedProductColor = [...productDetails?.product_images].find(
        (item) => item.isActive
      );
      if (tempSelectedProductColor) {
        if (isOnlyImage) {
          return tempSelectedProductColor.image;
        }
        let tempImageList = [...tempSelectedProductColor.image].map(
          (imgLink) => ({
            type: 1,
            link: imgLink,
          })
        ); // Type 1 => Image , Type 2 => Video
        if (tempSelectedProductColor.video) {
          let tempVideoList = [...tempSelectedProductColor.video].map(
            (videoLink) => ({ type: 2, link: videoLink })
          );
          return [...tempImageList, ...tempVideoList];
        }
        return [...tempImageList];
      }
    }
    return [];
  };

  const getSelectedProductColorItem = () => {
    let tempProductDetails = { ...productDetails };
    let selectedColorItem = tempProductDetails.product_images?.find(
      (item) => item.isActive
    );
    return selectedColorItem;
  };

  return (
    <div>
      <Loader isLoader={isLoader} />
      <Modal
        show={props.state}
        onHide={props.onHide}
        // {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        // style={{ maxWidth: "1024px" }}
      >
        <Modal.Body>
          {productDetails && (
            <div className={Styles.overallContainer}>
              <div className={Styles.leftContainer}>
                <div onClick={props.onHide} className={Styles.mblCancelParent}>
                  <div className={Styles.mblCancelContainer}>
                    <img
                      className={Styles.cancelImg}
                      src={cancel}
                      alt="cancel"
                    />
                  </div>
                </div>

                {props?.data?.status !== 1
                  ? getImagesVideosData() &&
                    getImagesVideosData().length > 0 && (
                      <>
                        <div className={Styles.videoImageContainer}>
                          <Slider
                            asNavFor={slider2Ref}
                            ref={(slider1) => setslider1Ref(slider1)}
                            infinite={false}
                            dots={true}
                            className={`${Styles.sliderContainer} mainslick`}
                            centerMode={true}
                            focusOnSelect={true}
                            speed={500}
                            slidesToShow={1}
                            slidesToScroll={1}
                            centerPadding="20px"
                          >
                            {getImagesVideosData().map((item, index) => {
                              return parseInt(item.type) === 2 ? (
                                <iframe
                                  key={index.toString()}
                                  className={Styles.modalVideo}
                                  src={item.link}
                                  alt="modalvideo"
                                />
                              ) : (
                                <ReactImageMagnify
                                  className={Styles.modalImg}
                                  imageClassName={Styles.modalImg}
                                  largeImage={{
                                    src: item.link,
                                    width: 1200,
                                    height: 1200,
                                  }}
                                  enlargedImageContainerClassName={
                                    Styles.enlargeImage
                                  }
                                  enlargedImagePortalId="enlargeImage"
                                  smallImage={{
                                    alt: "Wristwatch by Ted Baker London",
                                    isFluidWidth: true,
                                    src: item.link,
                                  }}
                                />
                              );
                            })}
                          </Slider>
                          <div
                            id="enlargeImage"
                            className={Styles.enlargedOverLaySec}
                          ></div>
                        </div>
                      </>
                    )
                  : null}
                <div className="mt-3">
                  {getImagesVideosData()?.length > 0 && (
                    <Slider
                      asNavFor={slider1Ref}
                      ref={(slider2) => setslider2Ref(slider2)}
                      infinite={false}
                      dots={false}
                      className={Styles.smallSliderContainer}
                      centerMode={false}
                      speed={500}
                      focusOnSelect={true}
                      slidesToShow={3}
                      slidesToScroll={3}
                    >
                      {getImagesVideosData().map((item, index) => (
                        <img
                          src={
                            parseInt(item.type) === 2
                              ? getVideoThumbnail(item.link)
                              : item.link
                          }
                          alt="Video"
                          className={Styles.thumbnailimg}
                        />
                      ))}
                    </Slider>
                  )}
                </div>
              </div>

              <div
                onClick={(ele) => ele.stopPropagation()}
                className={Styles.rightContainer}
              >
                <div onClick={props.onHide} className={Styles.cancelParent}>
                  <div className={Styles.cancelContainer}>
                    <img
                      className={Styles.cancelImg}
                      src={cancel}
                      alt="cancel"
                    />
                  </div>
                </div>
                <div className={Styles.categoryContainer}>
                  <p className={Styles.category + " orange fontBold"}>
                    {productDetails?.category_name}
                    {"/"}
                    {productDetails?.sub_category_name}
                  </p>
                </div>
                <div>
                  <h4 className={Styles.productname + " label"}>
                    {productDetails?.product_name}
                  </h4>
                  <div>
                    <span className={Styles.oldPrice}>
                      ₹ {productDetails?.price?.toFixed(2)}
                    </span>
                    <span className={Styles.newPrice}>
                      ₹ {productDetails?.franchise_customer_price?.toFixed(2)}
                    </span>
                  </div>
                  {props?.data?.status === 1 ? (
                    <p className="modalHeading orange">
                      Brand :
                      <span className="fw-normal fs-6 text-dark">
                        {productDetails?.brand_name}
                      </span>
                    </p>
                  ) : null}
                  <div // for inventory view
                    style={{
                      display: props?.data?.status === 1 ? "none" : null,
                    }}
                  ></div>
                  {productDetails?.product_units &&
                    productDetails?.product_units.length > 0 && (
                      <>
                        <div className="mb-2">
                          <span className="modalHeading orange">Unit : </span>
                          <select
                            style={{
                              display:
                                props?.data?.status === 1 ? "none" : null,
                            }}
                            className={Styles.unitSelectContainer}
                            onChange={(e) => {
                              let tempSelectedUnit =
                                productDetails?.product_units.find(
                                  (item) =>
                                    parseInt(item.id) ===
                                    parseInt(e.target.value)
                                );
                              if (tempSelectedUnit) {
                                setselectedUnit((pre) => ({
                                  ...pre,
                                  value: tempSelectedUnit,
                                  isError: false,
                                }));
                              } else {
                                setselectedUnit((pre) => ({
                                  ...pre,
                                  value: null,
                                  isError: true,
                                }));
                              }
                            }}
                          >
                            {productDetails?.product_units.map((item) => {
                              return (
                                <option value={item.id}>{item.unit_id}</option>
                              );
                            })}
                          </select>
                        </div>
                        {selectedUnit.isError ? (
                          <p className={Styles.errorMsg + " text-danger"}>
                            Select Valid Unit
                          </p>
                        ) : null}
                      </>
                    )}
                  <div>
                    <span className="modalHeading orange">Quantity : </span>
                    <input
                      // for inventory view
                      className={Styles.productCount}
                      type="number"
                      defaultValue={
                        productDetails?.min_quantity && !props.type
                          ? productDetails?.min_quantity
                          : "1"
                      }
                      // min={
                      //   productDetails?.min_quantity
                      //     ? productDetails?.min_quantity
                      //     : "1"
                      // }
                      // max={
                      //   productDetails?.max_quantity
                      //     ? productDetails?.max_quantity
                      //     : "1"
                      // }
                      value={productCount.count}
                      onChange={(e) => handleProductCount(e.target.value)}
                    />
                    <span // for inventory view
                      style={{
                        display: props?.data?.status !== 1 ? "none" : null,
                      }}
                    >
                      {productDetails?.quantity}
                    </span>

                    <button
                      // for inventory view
                      style={{
                        display: props?.data?.status === 1 ? "none" : null,
                      }}
                      className={Styles.addtocartBtn}
                      onClick={(e) => {
                        if (
                          productDetails?.product_units &&
                          productDetails?.product_units.length > 0 &&
                          (!selectedUnit.value || selectedUnit.isError)
                        ) {
                          return;
                        } else if (
                          productCount.isError ||
                          parseInt(productCount.count) <= 0
                        ) {
                          return;
                        }

                        props.handleViewData({
                          ...productDetails,
                          selectedUnit: selectedUnit.value,
                          colorItem: getSelectedProductColorItem(),
                          qty: productCount.count.toString(),
                        });
                        props.onHide();
                      }}
                    >
                      Add Items
                    </button>
                  </div>
                  {productCount.isError || productCount.errorMsg ? (
                    <p className={Styles.errorMsg + " text-danger"}>
                      {productCount.errorMsg}
                    </p>
                  ) : null}
                  {productDetails?.product_images.length > 0 && (
                    <p className="mt-3 mb-1">Color</p>
                  )}
                  {productDetails?.product_images.length > 0 ? (
                    <div className="d-flex">
                      {productDetails?.product_images?.map((item) => (
                        <div
                          onClick={() => {
                            handlePressColor(item);
                          }}
                          style={{
                            backgroundColor: item?.code,
                            border: item.isActive
                              ? "2px solid #ff7b26"
                              : "2px solid #000",
                          }}
                          className={Styles.color}
                        ></div>
                      ))}
                    </div>
                  ) : null}
                  <div className="mt-2 mb-2">
                    <p className="modalHeading orange">Description</p>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: productDetails?.product_description,
                      }}
                    />
                  </div>

                  <div // for inventory view
                    // style={{ display: props.data.status === 1 ? "none" : null }}
                    className="mt-3"
                  >
                    {data.length > 0 ? (
                      <Accordion>
                        <Accordion.Item eventKey="0" className={Styles.items}>
                          <Accordion.Header className="modalHeading text-capitalize">
                            Product Details
                          </Accordion.Header>
                          <Accordion.Body className={Styles.description}>
                            <div
                              style={{
                                display:
                                  data?.length === 0 ||
                                  props?.data?.status === 1
                                    ? "none"
                                    : null,
                              }}
                              className={Styles.priceContainer + " mt-3"}
                            >
                              <table>
                                <thead>
                                  <tr>
                                    <th
                                      className={
                                        Styles.th + " label text-center"
                                      }
                                    >
                                      From Qty
                                    </th>
                                    <th className=" label text-center">
                                      To Qty
                                    </th>
                                    <th className=" label text-center">
                                      Price
                                    </th>
                                    <th className=" label text-center">
                                      Franchise Price
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {data?.map((e) => (
                                    <tr className={Styles.data}>
                                      <td className={Styles.td}>
                                        {e?.from_qty}
                                      </td>
                                      <td className={Styles.td}>{e?.to_qty}</td>
                                      <td className={Styles.td}>
                                        {"₹ " + e?.price.toFixed(2)}
                                      </td>
                                      <td className={Styles.td}>
                                        {"₹ " + e?.franchise_pricing.toFixed(2)}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}
