import { useState } from "react";
import { OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Styles from "./CategoriesList.module.css";
import ModalView from "../Products/Modal";
import {
  addtocartService,
  removeWishlistService,
} from "../../../Services/ApiServices";
import emptyImg from "../../../Assets/no_image.svg";
import like from "../../../Assets/product_like.svg";
import wishlist_like from "../../../Assets/wishlist_like.svg";
import eye from "../../../Assets/product_eye.svg";
import { handleWishlistCount } from "../../../Redux/Action";

export default function CategoriesList(props) {
  let location = useLocation();
  let dispatch = useDispatch();
  const { wishlistCount } = useSelector((state) => state);
  const [wishlistStatus, setWishlistStatus] = useState(props.data.wish_list);
  const [isShowModal, setIsShowModal] = useState(false);

  const handleDeleteWishlist = (data) => {
    removeWishlistService(parseInt(data))
      .then((res) =>
        toast("Remove from Wishlist Succesfully", { type: "success" })
      )
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast.error(err.response.data.detail[0].msg, { type: "error" });
        } else {
          toast.error("Something went wrong!!", { type: "error" });
        }
      });
  };
  const hanldeCartOrWish = (id, count, type) => {
    let formData = new FormData();
    formData.append("product_id", parseInt(id));
    formData.append("quantity", parseInt(count));
    formData.append("card_type", parseInt(type));
    addtocartService(formData)
      .then((res) => {
        toast(
          type === 2
            ? "Added to Wishlist Successfully"
            : "Add to Cart Successfully",
          {
            type: "success",
          }
        );
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast(err.response.data.detail[0].msg, { type: "error" });
        } else {
          toast("Something went wrong!!", { type: "error" });
        }
      });
  };
  const handleWishlist = (data) => {
    setWishlistStatus(parseInt(data));
  };

  const handleViewData = (data) => {
    props.handleViewDetails(data);
  };
  return (
    <div
      className={Styles.overallContainer}
      onClick={() => {
        if (
          (props.data.product_unit && props.data.product_unit.length > 0) ||
          (props.data.product_image && props.data.product_image.length > 0)
        ) {
          setIsShowModal(true);
        } else {
          props?.handleBillList(props?.data);
        }
      }}
      style={{
        border: props?.highlight ? "1px solid #ff7b26" : null,
      }}
    >
      {isShowModal ? (
        <ModalView
          categoryname={props.categoryname}
          productId={props.data.product_id}
          state={isShowModal}
          handleViewData={handleViewData}
          type={props?.type}
          onHide={() => setIsShowModal(false)}
          hanldeCartOrWish={hanldeCartOrWish}
          handleWishlist={handleWishlist}
        />
      ) : null}
      <div className={Styles.imgContainer}>
        <img
          className={Styles.img}
          src={
            props?.data?.product_image ? props?.data?.product_image : emptyImg
          }
          alt="img"
        />
        <div
          style={{
            display:
              location.pathname === "/kansas_product/allproducts"
                ? "none"
                : null,
          }}
          className={Styles.optionsParentContainer}
        >
          {props.type ? null : (
            <>
              <OverlayTrigger
                placement="left"
                overlay={<Tooltip>Wishlist</Tooltip>}
              >
                <div
                  onClick={(ele) => ele.stopPropagation()}
                  className={Styles.optionsContainer}
                >
                  <img
                    className={Styles.optionsImg}
                    src={parseInt(wishlistStatus) === 1 ? wishlist_like : like}
                    onClick={(e) => {
                      setWishlistStatus(parseInt(wishlistStatus) === 1 ? 0 : 1);
                      if (parseInt(wishlistStatus) !== 0) {
                        handleDeleteWishlist(props.data.product_id);
                        dispatch(handleWishlistCount(wishlistCount - 1));
                      } else {
                        hanldeCartOrWish(props.data.product_id, 1, 2);
                        dispatch(handleWishlistCount(wishlistCount + 1));
                      }
                    }}
                    alt="like"
                  />
                </div>
              </OverlayTrigger>
            </>
          )}
          <OverlayTrigger placement="left" overlay={<Tooltip>View</Tooltip>}>
            <div
              onClick={(e) => e.stopPropagation()}
              className={Styles.optionsContainer}
            >
              <img
                className={Styles.optionsImg}
                onClick={(e) => {
                  setIsShowModal(!isShowModal);
                }}
                src={eye}
                alt="eye"
              />
            </div>
          </OverlayTrigger>
        </div>
        {parseInt(props?.data?.quantity) === 0 && false ? (
          <p className={Styles.outofstock}>Out of Stock</p>
        ) : null}
      </div>
      <div className="mt-3">
        <h4
          onClick={(e) => {
            setIsShowModal(!isShowModal);
          }}
          className={Styles.details + " kstoreFont"}
        >
          <OverlayTrigger
            placement="bottom"
            key={props?.data?.product_id}
            overlay={
              <Popover className={Styles.popover}>
                {props?.data?.product_name}
              </Popover>
            }
          >
            <p>{props?.data?.product_name?.substring(0, 37)}</p>
          </OverlayTrigger>
        </h4>
        {props.type ? (
          <div className={Styles.qtyContainer}>
            <h5 className={Styles.rate}>
              ₹ {props?.data?.customer_price_on_franchise}
            </h5>
            <h6 className={Styles.qty}>
              <span className="orange">Qty : </span> {props?.data?.quantity}
            </h6>
          </div>
        ) : (
          <div className={Styles.priceDisplay}>
            <span className={Styles.customerNewPrice}>
              ₹ {props?.data?.price.toFixed(2)}
            </span>
            <span className={Styles.oldPrice + " orange"}>
              ₹{props?.data?.franchise_customer_price.toFixed(2)}
            </span>
          </div>
        )}
      </div>
    </div>
  );
}
