import { Offcanvas } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import ReactPaginate from "react-paginate";
import {
  customerCategoryService,
  kansas_productListService,
} from "../../../Services/ApiServices";
import Styles from "./AllProducts.module.css";
import search from "../../../Assets/search.svg";
import { TabTitle } from "../../../Utility/TabTitle";
import notFoundImage from "../../../Assets/productNotFound.svg";
import Loader from "../../../SharedComponents/Loader/Loader";
import Checkbox from "../Products/CheckBox";
import ProductItem from "../Products/ProductItem";
import CategoryDropdown from "../Products/DropDown";
import Header from "../../../SharedComponents/Header/Header";
import LeftArrow from "../../../Assets/modal_left_arrow.png";
import RightArrow from "../../../Assets/modal_right_arrow.png";
import cart from "../../../Assets/shopping-cart.png";
import ProductsAttributesContainer from "./ProductsAttributesContainer";

export default function AllProducts() {
  TabTitle("KSTORE - Products");
  let navigate = useNavigate();
  let { state } = useLocation();
  let franchise_id = JSON.parse(sessionStorage.getItem("customerlog"));
  const [categoryName, setCategoryName] = useState("");
  const [id, setId] = useState(1);
  const [show, setShow] = useState(false);
  const [productDetails, setProductDetails] = useState(null);
  const [filterDetails, setFilterDetails] = useState([]);
  const [productSearch, setProductSearch] = useState("");
  const [filter, setFilter] = useState("");
  const [brandData, setBrandData] = useState([]);
  const [customerSelectedItem, setcustomerSelectedItem] = useState([]);
  const [filterBrandData, setFilterBrandData] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [selectedProductsAttributes, setselectedProductsAttributes] = useState(
    []
  );
  const [filterIds, setFilterIds] = useState({ sub_id: "", cat_id: "" });
  const [productsAttributes, setproductsAttributes] = useState([]);
  const handleClose = () => setShow(false);
  useEffect(() => {
    handleGetCategoryList();
  }, []);
  useEffect(() => {
    handleGetProduct(filterIds.sub_id, filterIds.cat_id);
  }, [filter]);

  useEffect(() => {
    const sessionLocation = sessionStorage.getItem("location_details");
    if (sessionLocation === null) {
      getLocation();
    }
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem("kansas_product")) {
      setcustomerSelectedItem(
        JSON.parse(sessionStorage.getItem("kansas_product"))
      );
    }
  }, []);

  // for location access
  const getLocation = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      sessionStorage.setItem(
        "location_details",
        JSON.stringify({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        })
      );
    });
  };

  const handleGetCategoryList = () => {
    customerCategoryService()
      .then((res) => setFilterDetails(res.data.items))
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast.error(err.response.data.detail[0].msg);
        } else {
          toast.error("Something went wrong!!");
        }
      });
  };

  const handleGetProduct = (
    sub_category_id = "",
    category_id = "",
    category_name = "",
    page = 1
  ) => {
    setIsLoader(true);
    setFilterIds({ sub_id: sub_category_id, cat_id: category_id });
    setCategoryName(category_name);
    let formData = new FormData();
    formData.append("franchise_id", franchise_id?.id);
    formData.append("product_name", productSearch);
    formData.append("list_type", state !== null ? parseInt(state) : 2);
    if (category_id && sub_category_id) {
      formData.append("sub_category_id", parseInt(sub_category_id));
      formData.append("category_id", parseInt(category_id));
    } else if (category_id) {
      formData.append("category_id", parseInt(category_id));
    }
    if (filterBrandData.length !== 0) {
      formData.append("brand", "[" + filterBrandData + "]");
    }
    if (selectedProductsAttributes.length > 0) {
      formData.append("product_attribute_value_id", selectedProductsAttributes);
    }

    if (filter !== "") {
      let splitData = filter.split("-");
      if (splitData[1] === "1") {
        formData.append("order_by", "product_name");
        formData.append("order", splitData[0]);
        formData.append("sub_category_id", filterIds?.sub_id);
        formData.append("category_id", filterIds?.cat_id);
      }
      if (splitData[1] === "2") {
        formData.append("order_by", "selling_price");
        formData.append("order", splitData[0]);
      }
    }
    kansas_productListService(formData, page, 12)
      .then((res) => {
        setBrandData(res.data.brands);
        setproductsAttributes(res.data.product_attribute);
        setProductDetails(res.data);
        if (category_id && sub_category_id) {
          handleClose();
        }
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast.error(err.response.data.detail[0].msg, { type: "error" });
        } else {
          toast.error("Something went wrong!!", { type: "error" });
        }
      })
      .finally(() => setIsLoader(false));
  };

  const hanldeBrandData = (brandID) => {
    if (filterBrandData.includes(brandID)) {
      setFilterBrandData(filterBrandData.filter((e) => e !== brandID));
    } else {
      setFilterBrandData([...filterBrandData, brandID]);
    }
  };

  const handleSelectedData = (data) => {
    if (
      customerSelectedItem.findIndex(
        (e) => e.product_id === data.product_id
      ) !== -1
    ) {
      let filterData = [...customerSelectedItem].filter(
        (e) => e.product_id !== data.product_id
      );
      setcustomerSelectedItem(filterData);
    } else {
      setcustomerSelectedItem((pre) => [
        ...pre,
        { ...data, qty: 1, total_amount: data?.selling_price },
      ]);
    }
  };

  const handleAllproductId = (val) => {
    setId(0);
  };

  const handleProductsAttributesChecked = (id, isChecked) => {
    let tempAttributesIds = [...selectedProductsAttributes];
    if (isChecked) {
      tempAttributesIds.push(id);
    } else {
      tempAttributesIds = tempAttributesIds.filter((itemId) => itemId !== id);
    }
    setselectedProductsAttributes(tempAttributesIds);
  };

  const handleClearFilter = (page = 1) => {
    setIsLoader(true);
    setProductSearch("");
    setFilterBrandData([]);
    setFilter("");
    setselectedProductsAttributes([]);
    let formData = new FormData();
    formData.append("franchise_id", franchise_id?.id);
    formData.append("list_type", state !== null ? parseInt(state) : 2);
    kansas_productListService(formData, page, 12)
      .then((res) => setProductDetails(res.data))
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast.error(err.response.data.detail[0].msg, { type: "error" });
        } else {
          toast.error("Something went wrong!!", { type: "error" });
        }
      })
      .finally(() => setIsLoader(false));
  };

  return (
    <>
      <Header />
      <div className={Styles.overallContainer}>
        <Loader isLoader={isLoader} />
        <div className={Styles.mblFilter}>
          <Offcanvas placement="bottom h-75" show={show} onHide={handleClose}>
            <Offcanvas.Header className={Styles.filterHeading}>
              <Offcanvas.Title className="w-100">
                <div className={Styles.searchChildContainer}>
                  <input
                    className={Styles.searchInput + " label"}
                    type="text"
                    value={productSearch}
                    placeholder="Search products..."
                    onChange={(e) => setProductSearch(e.target.value)}
                  />
                  <div className="align-self-center">
                    <img
                      className={Styles.searchImg}
                      onClick={() => {
                        try {
                          handleGetProduct(filterIds.sub_id, filterIds.cat_id);
                          handleClose();
                        } catch (error) {
                          console.log(error);
                        }
                      }}
                      src={search}
                      alt="search"
                    />
                  </div>
                </div>
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <div className={Styles.tabLeftContainer}>
                <div>
                  <div className="d-flex justify-content-between">
                    <h4 className="fontBold">Categories</h4>
                  </div>
                </div>
                <div className="d-flex">
                  <hr className={Styles.hr1} />
                  <hr className={Styles.hr2} />
                </div>

                <div className={Styles.overall}>
                  <p
                    className={Styles.heading + " dropdownHeading"}
                    onClick={() => {
                      setFilter("");
                      setProductSearch("");
                      handleGetProduct();
                      setId(1);
                    }}
                    style={{
                      color: parseInt(id) === 1 ? "#FF7C25" : "black",
                    }}
                  >
                    All Produts
                  </p>
                  {filterDetails?.map((e) => (
                    <CategoryDropdown
                      key={e.category_id}
                      data={e}
                      handleAllproductId={handleAllproductId}
                      categoryID={filterIds}
                      handleGetProduct={(sub_id, cat_id, cat_name) => {
                        setFilter("");
                        setProductSearch("");
                        handleGetProduct(sub_id, cat_id, cat_name);
                      }}
                    />
                  ))}
                </div>
                <div>
                  <div className="d-flex justify-content-between mt-4">
                    <h4 className="fontBold">Brand</h4>
                    <span
                      onClick={() => {
                        setFilter("");
                        setProductSearch("");
                        handleGetProduct(filterIds.sub_id, filterIds.cat_id);
                        handleClose();
                      }}
                      className={Styles.applyBtn}
                    >
                      Apply
                    </span>
                  </div>
                </div>
                <div className="d-flex">
                  <hr
                    style={{
                      display: brandData.length === 0 ? "none" : null,
                    }}
                    className={Styles.hr1}
                  />
                  <hr
                    style={{
                      display: brandData.length === 0 ? "none" : null,
                    }}
                    className={Styles.hr2}
                  />
                </div>

                <div>
                  <Checkbox
                    hanldeBrandData={hanldeBrandData}
                    checkboxData={brandData}
                    filterData={filterBrandData}
                  />
                </div>

                <ProductsAttributesContainer
                  productsAttributes={productsAttributes}
                  onPressSeachButton={() => {
                    setFilter("");
                    setProductSearch("");
                    handleGetProduct(filterIds.sub_id, filterIds.cat_id);
                    handleClose();
                  }}
                  handleProductAttributeChecked={
                    handleProductsAttributesChecked
                  }
                  selectedProductsAttributes={selectedProductsAttributes}
                />
              </div>
            </Offcanvas.Body>
          </Offcanvas>
        </div>
        <div className={Styles.leftContainer}>
          <div>
            <div className="d-flex justify-content-between">
              <h4 className="fontBold">Categories</h4>
            </div>
          </div>
          <div className="d-flex">
            <hr className={Styles.hr1 + " m-0"} />
            <hr className={Styles.hr2 + " m-0"} />
          </div>
          <div className={Styles.overall}>
            <p
              className={Styles.heading + " dropdownHeading"}
              onClick={() => {
                setFilter("");
                setProductSearch("");
                handleGetProduct();
                setId(1);
              }}
              style={{
                color: parseInt(id) === 1 ? "#FF7C25" : "black",
              }}
            >
              All Products
            </p>
            {filterDetails?.map((e) => (
              <CategoryDropdown
                key={e.category_id}
                data={e}
                handleAllproductId={handleAllproductId}
                categoryID={filterIds}
                handleGetProduct={(sub_id, cat_id, cat_name) => {
                  setFilter("");
                  setProductSearch("");
                  handleGetProduct(sub_id, cat_id, cat_name);
                }}
              />
            ))}
          </div>
          <div>
            <div className="d-flex justify-content-between">
              <h4 className="fontBold">Brand</h4>
              <span
                onClick={() =>
                  handleGetProduct(filterIds.sub_id, filterIds.cat_id)
                }
                className={Styles.applyBtn}
              >
                Apply
              </span>
            </div>
          </div>
          <div className="d-flex">
            <hr
              style={{
                display: brandData.length === 0 ? "none" : null,
              }}
              className={Styles.hr1 + " m-0"}
            />
            <hr
              style={{
                display: brandData.length === 0 ? "none" : null,
              }}
              className={Styles.hr2 + " m-0"}
            />
          </div>
          {filterIds.cat_id !== "" || brandData.length !== 0 ? (
            <div>
              <Checkbox
                hanldeBrandData={hanldeBrandData}
                checkboxData={brandData}
                filterData={filterBrandData}
              />
            </div>
          ) : null}
          <ProductsAttributesContainer
            productsAttributes={productsAttributes}
            onPressSeachButton={() => {
              handleGetProduct(filterIds.sub_id, filterIds.cat_id);
            }}
            handleProductAttributeChecked={handleProductsAttributesChecked}
            selectedProductsAttributes={selectedProductsAttributes}
          />
        </div>
        <div
          style={{ height: productDetails?.length === 0 ? "100vh" : "unset" }}
          className={Styles.rightContainer}
        >
          <div className={Styles.rightFilterContainer}>
            <div className={Styles.searchChildContainer}>
              <input
                className={Styles.searchInput + " kstoreFont"}
                type="text"
                value={productSearch}
                placeholder="Search products..."
                onChange={(e) => setProductSearch(e.target.value)}
              />
              <div className="align-self-center">
                <img
                  className={Styles.searchImg}
                  onClick={() =>
                    handleGetProduct(filterIds.sub_id, filterIds.cat_id)
                  }
                  src={search}
                  alt="search"
                />
              </div>
            </div>
            <div className={Styles.mblFilterContainer}>
              <div className={Styles.filter} onClick={() => setShow(true)}>
                <span
                  className={Styles.filterText + " contentFont"}
                  onClick={() => setShow(true)}
                >
                  Filter
                </span>
              </div>
              <div className={Styles.clearFilterContainer}>
                {productSearch !== "" ||
                filterBrandData.length > 0 ||
                selectedProductsAttributes.length > 0 ||
                filter !== "" ? (
                  <p
                    onClick={() => handleClearFilter(1)}
                    className={Styles.clearfilterText}
                  >
                    Clear Filter
                  </p>
                ) : null}
                <div className={Styles.sortText}>
                  <span
                    className={Styles.sort + " align-self-center kstoreFont2"}
                  >
                    Sort By
                  </span>
                  <div className={Styles.selectContainer}>
                    <select
                      value={filter}
                      onChange={(e) => setFilter(e.target.value)}
                      className={Styles.empty + " kstoreFont2"}
                    >
                      <option value=""> please select</option>
                      <option value="asc-1">Name (A to Z)</option>
                      <option value="desc-1">Name (Z to A)</option>
                      <option value="desc-2">Price (High to Low)</option>
                      <option value="asc-2">Price (Low to High)</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {productDetails && productDetails?.items?.length === 0 ? (
            <div className={Styles.notFoundImgContainer}>
              <img
                className={Styles.notFoundImg}
                src={notFoundImage}
                alt="notfound"
              />
            </div>
          ) : (
            <div className={Styles.mblProducts + " row"}>
              {productDetails?.items?.map((e) => (
                <ProductItem
                  categoryname={categoryName}
                  data={e}
                  key={e.product_id}
                  customer="1"
                  handleSelectedData={handleSelectedData}
                  customerSelectedItem={customerSelectedItem.map(
                    (e) => e.product_id
                  )}
                  productType={state !== null ? parseInt(state) : 2}
                />
              ))}
            </div>
          )}

          <div
            className={Styles.mblcart}
            onClick={() => {
              navigate("/kansas_product/customercartlist", {
                state: customerSelectedItem,
              });
              sessionStorage.setItem(
                "customer_cart_list",
                JSON.stringify(customerSelectedItem)
              );
            }}
          >
            <img className={Styles.cartImg} src={cart} alt="cart" />
            <div className={Styles.notification}>
              <span className={Styles.badge}>
                {customerSelectedItem?.length}
              </span>
            </div>
          </div>
          <div
            style={{ display: productDetails?.total <= 10 ? "none" : "flex" }}
            className={Styles.paginationContainer}
          >
            <ReactPaginate
              previousLabel={<img src={LeftArrow} alt="left" />}
              nextLabel={<img src={RightArrow} alt="right" />}
              breakLabel="..."
              pageCount={Math.ceil(productDetails?.total) / 10}
              marginPagesDisplayed={1}
              pageRangeDisplayed={1}
              onPageChange={({ selected }) =>
                handleGetProduct(
                  filterIds?.sub_id,
                  filterIds?.cat_id,
                  "",
                  selected + 1
                )
              }
              containerClassName={
                "pagination m-0 d-flex justify-content-end me-4 bg-secondory"
              }
              pageClassName={"page-item"}
              pageLinkClassName={
                "page-link text-secondary rounded-circle bg-light pt-1 pb-1 ps-2 pe-2 ms-1 me-1 border-0 shadow-none"
              }
              forcePage={""}
              previousClassName={"page-item"}
              previousLinkClassName={
                "page-link border-0 text-dark p-1 rounded-circle bg_orange shadow-none me-1"
              }
              nextClassName={"page-item "}
              nextLinkClassName={
                "page-link border-0 text-dark p-1 bg_orange rounded-circle shadow-none ms-1"
              }
              activeClassName={"active"}
            />
          </div>
        </div>
      </div>
    </>
  );
}
