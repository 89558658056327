const initialState = {
  count: 0,
  cartCount: 0,
  profile: null,
  wishlistCount: 0,
};

export default function Reducer(state = initialState, action) {
  switch (action.type) {
    case "SET_CUSTOMER_CART_COUNT":
      return { ...state, count: action.data };
    case "VIEW_PROFILE":
      return { ...state, profile: action.data };
    case "CART_COUNT":
      return { ...state, cartCount: action.data };
    case "WISHLIST_COUNT":
      return { ...state, wishlistCount: action.data };
  }
  return state;
}
