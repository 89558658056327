import Styles from "./CheckBox.module.css";

export default function Checkbox(props) {
  return (
    <div>
      <div
        className={Styles.checkboxContainer}
        style={{ maxHeight: "250px", overflow: "hidden" }}
      >
        {props?.checkboxData?.map((e, index) => (
          <div
            key={index}
            onClick={(ele) => {
              props.hanldeBrandData(e?.brand_id);
            }}
            className={Styles.checkbox + " form-check mt-3"}
          >
            <input
              className={Styles.input + " form-check-input"}
              type="checkbox"
              value={e?.brand_id}
              id="flexCheckDefault"
              checked={props?.filterData?.includes(e.brand_id)}
              onChange={(element) => {
                props.hanldeBrandData(e?.brand_id);
              }}
            />
            <label className="form-check-label dropdownHeading">
              {e?.brand_name}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
}
